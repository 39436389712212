import UpgradedDateInput from "../../../components/UpgradedDateInput"

interface props {
    setAnswer: (date: string | null) => void;
    errorCheck?: boolean;
    values: string | null;
    id: number;
    message: string;
    /**
     * Should the legend be bold faced
     */
    legendBold?: boolean;
    errorMessage?: string;
    /** Should the component return an invalid date on change? Default false */
    returnInvalidDate?: boolean;
}
const BirthDateQuestion = ({...props}:props) =>{

    const questionData = {
        info: 'For example: 8/23/1994',
    };

    return (
        <div className="benefit-discovery-margin-bottom">
            {/* <p className={props.boldQuestion ? 'benefit-discovery-bold' : ""}>{props.message}</p>
            <p className='benefit-discovery-subtitle'>{questionData.info}</p> */}
            <UpgradedDateInput
                legendBold={props.legendBold}
                legend={props.message}
                hint={questionData.info}
                setAnswer={props.setAnswer}
                errorMessage={props.errorMessage}
                values={props.values}
                returnInvalidDate={props.returnInvalidDate}
            />
        </div>
    );
}

export default BirthDateQuestion;
