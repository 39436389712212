import React from "react";
import classnames from "classnames";

/**
 * The `label` for a field. Also renders
 * supporting UI elements like hint text and error message.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/form-controls)
 */
function FormLabel({ ...props }: FormLabelProps) {
    const errorMsgId = props.inputId + "_error";
    const hasError = !!props.errorMsg;
    const isBold = props.bold === undefined ? true : props.bold; // Default to bold

    const labelClasses = classnames("usa-label", {
        "usa-label--error": hasError,
        "font-heading-xl text-bold line-height-heading-2 margin-bottom-2": props.large,
        "text-bold": isBold,
    });

    return (
        <React.Fragment>
            <label className={labelClasses} htmlFor={props.inputId}>
                {props.children}
                {props.labelSuffix && (
                    <span className="usa-hint text-base-dark text-normal">
                        {props.labelSuffix}
                    </span>
                )}
                {props.optionalText && (
                    <span className="usa-hint text-base-dark text-normal display-block">
                        {props.optionalText}
                    </span>
                )}
            </label>
            {props.hint && (
                <span className="usa-hint font-body-md line-height-body-4">
                    {props.hint}
                </span>
            )}
            {hasError && (
                <span
                    className="usa-error-message"
                    id={errorMsgId}
                    role="alert"
                >
                    {props.errorMsg}
                </span>
            )}
        </React.Fragment>
    );
}

interface FormLabelProps {
    /**
     * If you want to show it as an error without the message
     */
    showErrorWithoutMessage?: boolean | undefined;
    children: React.ReactNode;
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Localized hint text
     */
    hint?: React.ReactNode;
    /**
     * The ID of the field this label is for. This is used for the label's `for`
     * attribute and any related ARIA attributes, such as for the error message.
     */
    inputId?: React.InputHTMLAttributes<HTMLInputElement>["id"];
    /**
     * Enable the large variant of label
     */
    large?: boolean;
    /**
     * Localized text indicating this field is optional
     */
    optionalText?: React.ReactNode;
    /**
     * Enable the bold variant of label
     */
    bold?: boolean;
    /**
     * Localized text to append after label inline
     */
    labelSuffix?: string
}

export default FormLabel;
