import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LinkMyMedicaidAgreementModal from "./link-my-medicaid-agreement-modal";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'; 
import FieldsetAddress from "../../components/FieldsetAddress";
import InputChoiceGroup from "../../components/InputChoiceGroup";
import InputText from "../../components/InputText";
import QuestionPage from "../../templates/QuestionPage";
import { pageScrollToTop } from "../../utils/pageScrollToTop";
import {
    updateAddressStateOnChange
} from "../BenefitsApplication/shared/services/addressService";
import { inputErrorMsg} from "../../utils/errorMessageUtil";
import { useLocation } from "react-router-dom";
import LinkMyMedicaidService  from  "./service/LinkMyMedicaidService";
import {LinkMyMedicaid} from "./model/LinkMyMedicaid";
import LookupMemberMedicaidIDService from './service/LookupMemberMedicaidIDService';
import BirthDateQuestion from "../BenefitsApplication/BirthDateQuestion";
import { useHistory } from "react-router";
import Button from "../../components/Button";
import NavigationConstants from "../BenefitsApplication/shared/enums/navigation";
import DigitalRegistrationService  from  "../user-digital-registration/service/DigitalRegistrationService";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const LinkMyMedicaidPage = () => {

    let query = useQuery();
    const history = useHistory();
    let idMeRegistrationCode = query.get("code");
    console.log("idMeRegistrationCode", idMeRegistrationCode);

    const [registrationStep, setRegistrationStep] = useState(1);
    const [isNavigateToStatusPage, setNavigateToStatusPage] = useState(false);
    const [isDigitalRegistered, setIsDigitalRegistered] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [openAgreementModal, setOpenAgreementModal] = useState(false);
    const [ssnErrorMsg, setSsnErrorMsg] = useState("");
    const [dobErrorMsg, setDobErrorMsg] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    //step2 fields
    const [medicaidErrorMsg, setMedicaidErrorMsg] = useState("");
   
    const [acknowledgeCheckboxErrorMsg, setAcknowledgeCheckboxErrorMsg] = useState("");
    const [agreementsCheckboxErrorMsg, setAgreementsCheckboxErrorMsg] = useState("");
    const [isAgreeRules, setIsAgreeRules] = useState(false);
    const [agreementErrorMsg, setAgreementErrorMsg] = useState("");
    const [enableSubmitButton, setEnableSubmitButton] = useState(false);
    const addressComponentName = "registrationRepAddress";


    const initializeDefaultAddressState = () => {
        return {
            streetAddress: "",
            streetAddress2: "",
            city: "",
            countyCode: "",
            stateCode: "",
            postalCode: "",
            postalCodeExtension: "",
        } ;
    };

    const initializeDefaultDigitalRegistration = ():LinkMyMedicaid => {
        return {
            firstName: "",
            lastName: "",
            address: initializeDefaultAddressState(),
            phoneNumber: "",
            email: "",
            dob: "",
            medicaid: "",
            ssn: "",
            acknowledgeCheckbox: false,
            agreementsCheckbox: false
        } as LinkMyMedicaid;
    };

    const resetStep1ErrorMsgs = () => {
        setSsnErrorMsg("");
        setDobErrorMsg("");
        setErrorMessage("");
     
    };

    const resetStep2ErrorMsgs = () => {
        setMedicaidErrorMsg("");
        setAcknowledgeCheckboxErrorMsg("");
        setAgreementsCheckboxErrorMsg("");
        setAgreementErrorMsg(""); 
        setErrorMessage("");
    };

    const [localState, setRegistrationRepState] = useState<LinkMyMedicaid>(initializeDefaultDigitalRegistration());

    useEffect(() => {
       getLinkMyMedicaidDetails();
    }, []);

    const validateLookupMemberApi = () => {
        setErrorMessage("");
        LookupMemberMedicaidIDService.getLookUpMember(localState.medicaid, localState.dob, localState.ssn)
        .then(response => {
            console.log(response.data);
           // setIsValidMedicaId(true);'
           if (response.data && response.data.errorModel){
            setErrorMessage(response.data.errorModel.code);
           } else {
            let memberLookData: any = response.data;
                if (Array.isArray(memberLookData)) {
                    for(let i=0; i < memberLookData.length; i++) {
                        if (memberLookData[i] && memberLookData[i].activeIndicator.toLowerCase() =='y' && memberLookData[i].medicaidID) {
                        const b2cMemberId = sessionStorage.getItem('member_id');
                        updateB2cFHIRMemberId(memberLookData[i].medicaidID, b2cMemberId);
                        }
                    }
                } else {
                    if (memberLookData.activeIndicator.toLowerCase() =='y' && memberLookData.medicaidID) {
                        const b2cMemberId = sessionStorage.getItem('member_id');
                        updateB2cFHIRMemberId(memberLookData.medicaidID, b2cMemberId);
                    }
                }

                setStatusMessage("You have successfully completed your digital registration. You can now use approved third party applications to access your Medicaid data.");
                setRegistrationStep(3);
                
            }
        })
          .catch(e => {
            console.log(e);
            let errormsg = e.errorMessage;
            if(e.responseData && e.responseData.message) {
                errormsg = e.responseData.message;
            }
            setStatusMessage("Error: " + errormsg);
          //  setIsValidMedicaId(false);
        });
    }

    const updateB2cFHIRMemberId = (medicaidId: any, memberId: any) => {
        DigitalRegistrationService.updateB2cFHIRMemberId(medicaidId, memberId)
          .then(response => {
            console.log(response.data);
            setStatusMessage("You have successfully completed your digital registration. You can now use approved third party applications to access your Medicaid data.");
            setNavigateToStatusPage(true);
          })
          .catch(e => {
            console.log(e);
            let errormsg = e.errorMessage;
            if(e.responseData && e.responseData.message) {
                errormsg = e.responseData.message;
            }
            setStatusMessage("Error: " + errormsg);
          });
    }

    const getLinkMyMedicaidDetails = () => {
        if (idMeRegistrationCode){
            LinkMyMedicaidService.get(idMeRegistrationCode)
            .then(response => {
                console.log(response.data);
                let preparedData = prepareLinkMyMedicaidUIData(response.data);
                setRegistrationRepState(preparedData);
                setIsDigitalRegistered(true);
                resetStep1ErrorMsgs();
        //     step1ValidationFormWithErrorMsg();
            })
            .catch(e => {
                console.log(e);
                setIsDigitalRegistered(false);
                let errormsg = e.errorMessage;
                if(e.responseData && e.responseData.message) {
                    errormsg = e.responseData.message;
                }
                setStatusMessage("Error: " + errormsg);
            //  setIsValidMedicaId(false);
            // setStatusMessage("Error occured while fetching user registration information")
            // setRegistrationStep(3);
            });
        } else {
            setErrorMessage("B2C code is not available")
        }
    };

    const changeDateFormat = (inputDate:any) => {  // expects Y-m-d
        var splitDate = inputDate.split('-');
        if(splitDate.count == 0){
            return null;
        }
    
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2]; 
    
        return month + '-' + day + '-' + year;
    }

    const prepareLinkMyMedicaidUIData = (data: any): LinkMyMedicaid => {
        let dateOfBirth:any = "";
        if (data.idMeDob) {
            dateOfBirth = JSON.stringify(data.idMeDob.toString()).replaceAll(",","-").replaceAll(/["']/g, "");
            dateOfBirth = changeDateFormat(dateOfBirth);
        }
        let registration = {
            firstName: data.idMeFirstName ,
            lastName: data.idMeLastName,
            address: initializeAddressState(data),
            phoneNumber: data.idMePhone,
            email: data.idMeEmail,
            dob: dateOfBirth,
            medicaid: "",
            ssn: data.idMeSsn,
            acknowledgeCheckbox: false,
            agreementsCheckbox: false
        } as LinkMyMedicaid;

        return registration;
    }

    const initializeAddressState = (data:any) => {
        let zipArray = data.idMeUserZip.split("-");
        return {
            streetAddress:data.idMeUserAddr1,
            streetAddress2: data.idMeUserAddr2,
            city: data.idMeUserCity,
            countyCode: "",
            stateCode: data.idMeUserSt,
            postalCode: zipArray[0],
            postalCodeExtension: zipArray[1],
        } ;
    };
  
    const address1Props = {
        value: localState?.address.streetAddress,
     //   errorMsg: streetAddressErrorMsg,
        label: "Current Residential Address"
    };
    const address2Props = {
        value: localState.address.streetAddress2,
    };
    const cityProps = {
        value: localState.address.city,
      //  errorMsg: cityErrorMsg,
    };
    const countyProps = {
        value: localState.address.countyCode,
    };
    const stateProps = {
        value: localState.address.stateCode,
     //   errorMsg: stateCodeErrorMsg,
    };
    const postalCodeProps = {
        postalCode5Value: localState.address.postalCode,
        postalCode4Value: localState.address.postalCodeExtension,
     //   errorMsg: postalCodeErrorMsg,
    };

    const step1ValidationFormWithErrorMsg = () => {
        let isFormValid = true;

        if (!localState.ssn) {
            setSsnErrorMsg(inputErrorMsg("Ssn"));
            isFormValid = false;
        }
        if (!localState.dob) {
            isFormValid = false;
            setDobErrorMsg(inputErrorMsg("Date Of Birth"));
        }

        return isFormValid;
    };


    const step2ValidationFormWithErrorMsg = () => {
        let isFormValid = true;

        if (!localState.medicaid) {
            setMedicaidErrorMsg(inputErrorMsg("Medicaid ID"));
            isFormValid = false;
        } else if (localState.medicaid.length != 11) {
            setMedicaidErrorMsg("Medicaid ID length should be 11");
            isFormValid = false;
        }
    
        if (!localState.acknowledgeCheckbox) {
            setAcknowledgeCheckboxErrorMsg("Please select Acknowledge checkbox");
            isFormValid = false;
        }
        if (!localState.agreementsCheckbox) {
            setAgreementsCheckboxErrorMsg("Please select Agreement checkbox");
            isFormValid = false;
        }
        if(!isAgreeRules) {
            setAgreementErrorMsg("Please read Agreement document and click on Agreement Button");
            isFormValid = false;
        }
        return isFormValid;
    };

    

    const handleSave = (event: React.FormEvent<HTMLInputElement>) => {
        console.log("event", event, localState);
        event.preventDefault();
        if (registrationStep === 1) {
            resetStep1ErrorMsgs();
            if(step1ValidationFormWithErrorMsg()) {
                setRegistrationStep(2);
            }
        } else if (registrationStep === 2) {
            resetStep2ErrorMsgs();
            if(step2ValidationFormWithErrorMsg()) {
                validateLookupMemberApi();
            }
        }
        pageScrollToTop();
    };


    const handleAdditionalChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRegistrationRepState({
            ...localState,
            [event.target.name]: event.target.value,
        });
    };

    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRegistrationRepState({
            ...localState,
            [event.target.name]: event.target.checked,
        });   
        if (event.target.name == "agreementsCheckbox" || event.target.name == "acknowledgeCheckbox") {
            if(event.target.checked && isAgreeRules) {
                if (event.target.name == "acknowledgeCheckbox" && localState.agreementsCheckbox) {
                    setEnableSubmitButton(true);
                } else if (event.target.name == "agreementsCheckbox" && localState.acknowledgeCheckbox) {
                    setEnableSubmitButton(true);
                }  else {
                    setEnableSubmitButton(false);
                }
            } else {
                setEnableSubmitButton(false);
            }
        }    
    };

    const handleAddressFormChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        //Update Address fields
        const addressUpdateObj = updateAddressStateOnChange(
            event,
            localState.address,
            addressComponentName
        );
        addressUpdateObj &&
        setRegistrationRepState({
            ...localState,
            address: addressUpdateObj,
        });
    };

    const handleCloseBtnAgreementModal = () => {
        setOpenAgreementModal(false);
    };

    const handleAgreeBtnAgreementModal = () => {
        setOpenAgreementModal(false);
        setIsAgreeRules(true);
        if(localState.agreementsCheckbox && localState.acknowledgeCheckbox) {
            setEnableSubmitButton(true);
        } else {
            setEnableSubmitButton(false);
        }
    };

    const handleOpenAgreementModal = () => {
        setOpenAgreementModal(true);
    };
    

    const errorMessagesStep1 = [
        ssnErrorMsg,
        dobErrorMsg,
        errorMessage,
    ];

    const errorMessagesStep2 = [
        medicaidErrorMsg,
        acknowledgeCheckboxErrorMsg,
        agreementsCheckboxErrorMsg,
        agreementErrorMsg,
        errorMessage,
    ];

    const setDateOfBirth = (date: string | null) =>{
        console.log("dob", date);
        let updatedDob = "";
        if(date) {
            updatedDob = date;
        }
        setRegistrationRepState({
            ...localState,
            dob: updatedDob
        });
    };

    const moveToBackStep = () => {
        if (registrationStep === 3 ) {
            setRegistrationStep(2);
        } else if (registrationStep === 2 ) {
            setRegistrationStep(1);
        } else if (registrationStep === 1 ) {
            history.goBack();
        }
    };

    const routeChange = () => {
       const path = NavigationConstants.UserRegistrationLandingPage;
        history.push({pathname: path, state: {hideLinkMyMedicaid : true, hideSignInSignUp : true }})
    }

    return (
        <React.Fragment>
            <QuestionPage
                title={`Digital Registration Step ${registrationStep}`}
                onSave={handleSave}
                errorMessages={registrationStep === 1 ? errorMessagesStep1 : errorMessagesStep2 }
                hideFeatureName = {true}
                disabled = {registrationStep === 1 ? false : (registrationStep === 3 ? true : !enableSubmitButton) }
                buttonText = {registrationStep === 1 ? "Continue" : (registrationStep === 2 ? "Next": "")}
                hideSubmitBtn = {registrationStep === 3? true: false}
                backBtnOnClick = {moveToBackStep}
            >
                { registrationStep === 1 &&
                <>
                    <InputText
                        context="registrationRep"
                        name="firstName"
                        label="First Name"
                        value={localState.firstName}
                        onChange={handleAdditionalChange}
                    //    errorMsg={firstNameErrorMsg}
                        readOnly= {true}
                    />
                    <InputText
                        context="registrationRep"
                        name="lastName"
                        label="Last Name"
                        value={localState.lastName}
                        onChange={handleAdditionalChange}
                    //    errorMsg={lastNameErrorMsg}
                        readOnly= {true}
                    />
                    <InputText
                        context="registrationRep"
                        name="email"
                        label="Email"
                        value={localState.email}
                        onChange={handleAdditionalChange}
                    //    errorMsg={emailErrorMsg}
                        readOnly= {true}
                    />
                    <InputText
                        context="registrationRep"
                        name="ssn"
                        label="Social Security Number"
                        value={localState.ssn}
                        onChange={handleAdditionalChange}
                        errorMsg={ssnErrorMsg}
                        readOnly= {isDigitalRegistered && !localState.ssn ? false : true}
                    />
                    {  isDigitalRegistered && localState.dob &&
                        <InputText
                            context="registrationRep"
                            name="dob"
                            label="Date Of Birth"
                            value={localState.dob}
                            onChange={handleAdditionalChange}
                            errorMsg={dobErrorMsg}
                            readOnly= {true}
                        />
                    }
                    { isDigitalRegistered && !localState.dob &&
                        < BirthDateQuestion 
                            setAnswer={setDateOfBirth} 
                            id={1}
                            values={localState.dob} 
                            message={"Date of Birth"}
                            legendBold={true}
                            errorMessage={dobErrorMsg}
                        />  
                    }
                    <FieldsetAddress
                        address1={address1Props}
                        address2={address2Props}
                        city={cityProps}
                        county={countyProps}
                        state={stateProps}
                        postalCode={postalCodeProps}
                        name={addressComponentName}
                        legend=""
                        onChange={handleAddressFormChange}
                        hideStreetAddress2 ={true}
                        readOnly= {true}
                        disabled= {true}
                    />
                    <InputText
                        context="registrationRep"
                        name="phoneNumber"
                        label="Telephone Number"
                        value={localState.phoneNumber}
                        onChange={handleAdditionalChange}
                    //    errorMsg={phoneErrorMsg}
                        readOnly= {true}
                    />
                </>
                }
                { registrationStep === 2 &&
                <>
                    
                    <InputText
                        context="registrationRep"
                        name="medicaid"
                        label="Medicaid ID"
                        value={localState.medicaid}
                        onChange={handleAdditionalChange}
                        errorMsg={medicaidErrorMsg}
                        maxLength = {11}
                    />
           
                    <InputChoiceGroup
                        choices={[
                            {
                                label: "By checking this box, you certify that the information you listed above is true and complete to the best of your knowledge",
                                value: "true",
                            
                            },
                        ]}
                        name="acknowledgeCheckbox"
                        type="checkbox"
                        onChange={handleToggleChange}
                        errorMsg={acknowledgeCheckboxErrorMsg}
                    /> 
                    <InputChoiceGroup
                        choices={[
                            {
                                label: <span>By checking this box, you agree to the rules and regulations regarding use of this site. Please view the <Link to="/registration" onClick={handleOpenAgreementModal} className={"linkk"}>Online Service and Web Confidentiality Agreements</Link> here. You must accept the agreements to continue with  registration.</span>,
                                value: "true",
                            
                            },
                        ]}
                        name="agreementsCheckbox"
                        type="checkbox"
                        onChange={handleToggleChange}
                        errorMsg={agreementsCheckboxErrorMsg}
                    />  
                    <LinkMyMedicaidAgreementModal
                        showPopup={openAgreementModal}
                        agreeBtnOnClick={handleAgreeBtnAgreementModal}
                        closeBtnOnClick = {handleCloseBtnAgreementModal}
                    />
                </>
                }
                { registrationStep === 3 &&
                <>
                    <Box sx={{ width: '100%', maxWidth: 600 }}>
                    <Typography variant="h6" gutterBottom component="div">
                            
                            <p>{statusMessage}</p>
                               
                            <Button
                                className="margin-top-4 margin-bottom-6"
                                type="button"
                                name="idMe"
                                onClick={routeChange}
                            >Finish
                            </Button>                    
                        </Typography>
                   </Box>
                </>
                }
             
            </QuestionPage>
        </React.Fragment>
    );
};
export default LinkMyMedicaidPage;


