export const selectYesNoErrorMsg = (reasonForSelectingYes: string): string => {
    //ie: Select yes if you have a Massachusetts driver's license or ID card.
    return reasonForSelectingYes
        ? `Please select yes if ${reasonForSelectingYes}.`
        : "Please select yes or no.";
};

export const selectErrorMsg = (restateQuestion: string): string => {
    //ie: Please select your work schedule.
    return `Please select ${restateQuestion || "one of the options"}.`;
};

export const inputErrorMsg = (missingField: string): string => {
    //ie: Please enter your current residential address.
    return `Please enter ${missingField || "this field"}.`;
};

export const guidelineErroMsg = (field: string, guideline: string): string => {
    //ie: Your date of birth must include a valid month, day, and year.
    return `${field || "This field"} must ${guideline || "be valid"}.`;
};

export const documentErroMsg = (documentName: string): string => {
    //ie: Please read the entire Notice of Information Privacy Practices before continuing
    return `Please read the entire ${documentName || "document"} before continuing.`;
};

export const genFirstNameErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} first name`);
};

export const getApptypeErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Application Type`);
};

export const getAppSupportErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} App Support URI`);
};

export const getClientTosErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Client Terms of Service URI`);
};

export const getSecCertUrlErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Security Certification URL`);
};

export const getAppDescErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Application Description`);
};


export const getSftNameErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Application name`);
};

export const getSftVersionErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Application Version`);
};

export const getDescriptionErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Description`);
};

export const getPhoneNumberErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Phone number`);
};

export const getSoftwareIdErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Software ID`);
};

export const getSupportEmailErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Support Email`);
};


export const genOrgNameErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Organization name`);
};

export const genFedIdErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} Federal Tax ID`);
};

export const genLastNameErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} last name`);
};

export const genUserNameErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} user name`);
};

export const genPhoneNumErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} phone number`);
};

export const genEmailErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} email`);
};

export const getPasswordErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} password`);
};

export const getConfPasswordErrorMsg = (possessive: string): string => {
    return inputErrorMsg(`${possessive} confirm password`);
};

export const getPasswordMisMatchErrorMsg = ()=> {
    return passwordMatchErrorMsg();
};

export const genBirthdateErrorMsg = (): string => {
    return guidelineErroMsg("Birthdate", "be a valid date and in the past");
};

export const genDateErrorMsg = (dateName: string, isFuture?: boolean): string => {
    const pastFuture = isFuture ? "future" : "past";
    return guidelineErroMsg(dateName, `be a valid date and in the ${pastFuture}`);
};

export const passwordMatchErrorMsg = (): string => {
    return `Please enter same password.`;
};