import React, { useState, useEffect } from "react";
import QuestionPage from "../../templates/QuestionPage";
import CustomTable, { MinTableItem, MinTableHeader } from "../../components/CustomTable";
import AppRegistrationService from "../application-registration/AppRegistrationService";
import { Applications } from "../model/Applications";
import NavigationConstants from "../BenefitsApplication/shared/enums/navigation";
import AppStatusClientPage from '../application-status/application-status-client-modal';
import InputText from "../../components/InputText";
import AppDetailsPage from "./application-details-page";

import { useHistory } from "react-router-dom";

const ApplicationStatus = () => {
    const history = useHistory();

    const orgId = sessionStorage.getItem('orgId');
    if (!orgId) {
        const path = NavigationConstants.AppSignInPage;
        history.push(path);
    }

    const [applicationsTableData, setApplicationsTableData] = useState<AppliationStatusTable[]>([]);
    const [applicationsStatusListData, setApplicationsStatusListData] = useState<AppliationStatusTable[]>([]);

    const [openStatusClientPopup, setOpenAppStatusClientPopup] = useState(false);
    const [appClientData, setAppClientData] = useState<any>();
    const [searchField, setSearchField] = useState<any>('');
    const [openAdminAppStatusReviewPopup, setOpenAdminAppStatusReviewPopup] = useState(false);
    const [openAdminAppStatusPopup, setOpenAdminAppStatusPopup] = useState(false);
    const [adminAppStatusData, setAdminAppStatusData] = useState<any>();


    const statusDropdownChoices = [
        {
            label: "Approved",
            value: "AP",
        },
        {
            label: "Rejected",
            value: "RJ",
        },
        {
            label: "In Review",
            value: "IR",
        },
        {
            label: "Requires Additional Information",
            value: "RA",
        }
    ]

    useEffect(() => {
        getApplicationsByOrgId();
    }, []);

    const getApplicationsByOrgId = () => {
        AppRegistrationService.getApplicationsByOrgId(orgId)
            .then(response => {
                console.log(response.data);
                let preparedData = prepareApplicationsTableData(response.data);
                preparedData = preparedData.sort((a, b) => {
                    const isAsc = true;
                    return compareData(a.appName, b.appName, isAsc)
                });
                setApplicationsStatusListData(preparedData);
                setApplicationsTableData(preparedData);
            })
            .catch(e => {
                setApplicationsStatusListData([]);
                setApplicationsTableData([]);
                console.log(e);
            });
    };

    const getCredentialsById = (orgId: any, appId: any): void => {
        console.log("App parsing data", appId)
        AppRegistrationService.getCredentials(orgId, appId)
            .then(response => {
                console.log(response.data);
                setAppClientData(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleButtonFunctionality = (res: any) => {
        if (res && res.data) {
            if (res.header) {
                if (res.header.type == "button") {
                    setOpenAppStatusClientPopup(true);
                    getCredentialsById(orgId, res.data.appId)
                } else if (res.header.type == "link") {
                    setOpenAdminAppStatusPopup(true);
                }
            }
            getApplicationById(res.data.appId)
        }
    };

    const getApplicationById = (appId: any): void => {
        console.log("App parsing data", appId)
        AppRegistrationService.getApplication(appId)
            .then(response => {
                console.log(response.data);
                setAdminAppStatusData(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const prepareApplicationsTableData = (data: Applications[]): AppliationStatusTable[] => {
        if (data) {
            return data.map(getApplicationsTableData);
        } else {
            return [];
        }
    }

    function getApplicationsTableData(data: Applications) {
        let applicationsTableData: AppliationStatusTable = {
            appId: data.appId,
            appName: data.appName,
            appStatusCd: data.appStatusCd,
            action: 'App Credentials',
        }

        if (applicationsTableData.appStatusCd) {
            if (applicationsTableData.appStatusCd != 'AP') {
                applicationsTableData.buttonDisable = true;
            }
            statusDropdownChoices.forEach(statusDropDown => {
                if (statusDropDown.value == applicationsTableData.appStatusCd) {
                    applicationsTableData.appStatusCd = statusDropDown.label;
                }
            });
        } else {
            applicationsTableData.buttonDisable = true;
        }

        return applicationsTableData;
    }

    const appStatusHeaders: MinTableHeader[] = [
        {
            id: "appId",
            name: "Application ID",
            type: "link"
        },
        {
            id: "appName",
            name: "Application Name",
            type: "text",
            sort: true,
        },
        {
            id: "appStatusCd",
            name: "Status",
            type: "text"
        },
        {
            id: "action",
            name: "Actions",
            type: "button"
        }
    ]

    const handleClientCloseBtnConfirmModal = () => {
        setAppClientData(null);
        setOpenAppStatusClientPopup(false);
    };

    const handleClientConfirmBtnModal = (data: any) => {
        console.log("data", data)
        setOpenAppStatusClientPopup(false);
        setAppClientData(null);
    };

    const searchAppStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("event", event.target.value)
        setSearchField(event.target.value);
        let filteredAppStatusList = applicationsStatusListData.filter((item) => item.appName.toLowerCase().includes(event.target.value.toLowerCase()));
        filteredAppStatusList = filteredAppStatusList.sort((a, b) => {
            const isAsc = true;
            return compareData(a.appName, b.appName, isAsc)
        });
        setApplicationsTableData(Object.create(filteredAppStatusList));
    };

    const compareData = (a: number | string, b: number | string, isAsc: boolean) => {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    const handleSortingFunctionality = (res: any) => {
        console.log("sorting res", res);
        let filteredAppStatusList = applicationsStatusListData.filter((item) => item.appName.toLowerCase().includes(searchField.toLowerCase()));
        filteredAppStatusList = filteredAppStatusList.sort((a, b) => {
            const isAsc = res.sortOrdering === 'ASC';
            return compareData(a.appName, b.appName, isAsc);
        });
        setApplicationsTableData(Object.create(filteredAppStatusList));
    }

    const handleAppStatusCloseBtnConfirmModal = () => {
        setAdminAppStatusData(null);
        setOpenAdminAppStatusPopup(false);
    };

    const handleAppStatusConfirmBtnModal = () => {
        setAdminAppStatusData(null);
        setOpenAdminAppStatusPopup(false);
    }

    return (

        <React.Fragment>
            <QuestionPage
                title="Application Status"
                hideFeatureName={true}
                hideSubmitBtn={true}
                formClass=""
                showLogoutBtn={true}
            >
                <InputText
                    name="search_medicaid"
                    onChange={searchAppStatusChange}
                    type="text"
                    formGroupClassName=" float_right margin-top-0 margin-bottom-20 "
                    inputPrefix="Search"
                />
                <CustomTable
                    headers={appStatusHeaders}
                    items={applicationsTableData}
                    tableButton={handleButtonFunctionality}
                    sortingButton={handleSortingFunctionality}
                    defaultSortColId="appName"
                />
                <AppStatusClientPage
                    data={appClientData}
                    adminAppStatusData={adminAppStatusData}
                    showPopup={openStatusClientPopup}
                    agreeBtnOnClick={handleClientConfirmBtnModal}
                    closeBtnOnClick={handleClientCloseBtnConfirmModal}
                />
                <AppDetailsPage
                    data={adminAppStatusData}
                    showPopup={openAdminAppStatusPopup}
                    agreeBtnOnClick={handleAppStatusConfirmBtnModal}
                    closeBtnOnClick={handleAppStatusCloseBtnConfirmModal}
                />

            </QuestionPage>
        </React.Fragment>
    );
};
interface AppliationStatusTable extends MinTableItem {
    appId: string,
    appName: string,
    appStatusCd: string,
    action: string,
    buttonDisable?: boolean

}


export default ApplicationStatus;