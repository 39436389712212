import "../Modal/CustomModal";
import React, { useState, useEffect, CSSProperties } from "react";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import InputText from "../../components/InputText";
import OrganizationService from "./service/adminOrganizationService";
import BeatLoader from "react-spinners/BeatLoader";
import { useMsal } from "@azure/msal-react";


interface AdminOrganizationReviewPageProps {
    showPopup: boolean,
    okButtonText?: string
    closeButtonText?: string
    orgId?: String
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;
}

function AdminOrganizationReviewPage(props: AdminOrganizationReviewPageProps) {
    const { instance,accounts } = useMsal();
    const [reviewStatus, setReviewStatus] = useState("");
    const [reviewComments, setReviewComments] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props?.orgId) {
            getOrganizationsById(props?.orgId);
        }
    }, []);

    const getOrganizationsById = (orgId: any): void => {
        setLoading(true);
        console.log("org parsing data", orgId)
        OrganizationService.get(orgId)
            .then(response => {
                console.log(response.data);
                setReviewStatus(response.data.orgStatus);
                setReviewComments(response.data.comment);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);

            });
    };

    const override: CSSProperties = {
        marginTop: "20%",
        marginLeft: "50%",
        marginBottom: "20%",
    };

    const statusCodeOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log("event", event.target.value);
        setReviewStatus(event.target.value);
    };


    const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewComments(event.target.value);
        console.log("event", event.target.value);
    };

    const okayButtonOnClick = () => {
        updateOrganizationStatus();
    };

    const updateOrganizationStatus = () => {
        setLoading(true);
        let updateStatus = {
            orgId: props?.orgId,
            orgApprovalSts: reviewStatus,
            comment: reviewComments,
            approverEmail: accounts?.[0].username
        }
        OrganizationService.updateOrganization(updateStatus)
            .then(response => {
                console.log(response.data)
                setReviewStatus("");
                setReviewComments("");
                setLoading(false);
                props.agreeBtnOnClick && props.agreeBtnOnClick(response.data);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    };

    const statusDropdownChoices = [
        {
            label: "Approved",
            value: "AP",
        },
        {
            label: "Rejected",
            value: "RJ",
        },
        {
            label: "In Review",
            value: "IR",
        },
        {
            label: "Requires Additional Information",
            value: "RA",
        }];




    return (
        <>
            {(props && props.showPopup && (
                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>Review Page</h2>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {props.closeButtonText || "Close"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div className={"ModalText"}>
                                    {!loading && <Dropdown
                                        choices={statusDropdownChoices}
                                        emptyChoiceLabel="Select Status"
                                        label="Status"
                                        name="reviewStatusDropDown"
                                        onChange={statusCodeOnChange}
                                        data-testid="reviewStatusDropDown"
                                        value={reviewStatus}></Dropdown>}
                                    {!loading && <InputText
                                        context="review"
                                        name="Comments"
                                        label="Comments"
                                        value={reviewComments}
                                        onChange={handleCommentsChange}
                                        type="textarea"
                                    />}
                                    {!loading && <Button className="ModalButton" onClick={props.closeBtnOnClick}> {props.closeButtonText || "Cancel"} </Button>}
                                    {!loading && <Button className="ModalButton" onClick={okayButtonOnClick}> {props.okButtonText || "OK"} </Button>}
                                    <BeatLoader loading={loading} cssOverride={override} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )) ||
                null}
        </>
    );

}

export default AdminOrganizationReviewPage;