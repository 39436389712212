import React from "react";
import InputText from "../../../../components/InputText";

interface PhoneNumberProps {
    /**
     * Name of the HTML text box
     */
    name: string;
    /**
     * Label for the HTML text box
     */
    label: string;
    /**
     * Initial value of the HTML text box
     */
    value?: string;
    /**
     * More compressed view
     */
    small?: boolean;
    /**
     * HTML input `onChange` attribute
     */
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * The context to differentiate from other text boxes
     */
    context?: string;
    /**
     * Enable the bold variant of label
     */
    bold?: boolean;
    /**
     * Sets the input's pre-fix
     */
    inputPrefix?: React.ReactNode;
    /** Class name for the input field */
    className?: string;
    /** Hide the prefix? */
    hideInputPrefix?: boolean;
    /** Show optional text */
    optionalText?: string;
    /** event.target.dataset.itemId to determine which item in a series you are updating */
    itemId?: string;
    mandatoryLabel?: boolean;
}

const PhoneNumber = ({ ...props }: PhoneNumberProps) : JSX.Element => {
    return (
        <React.Fragment>
            <InputText
                small={props.small}
                inputClassName={props.className}
                inputPrefix={!props.hideInputPrefix ? props.inputPrefix || "+1" : ""}
                context={props.context}
                name={props.name}
                label={props.label}
                mask="(999) 999-9999"
                maskPlaceholder=" "
                value={props.value}
                onChange={props.onChange}
                errorMsg={props.errorMsg}
                bold={props.bold}
                optionalText={props.optionalText}
                itemId={props.itemId}
                mandatoryLabel = {props.mandatoryLabel}
            ></InputText>
        </React.Fragment>
    );
};

export default PhoneNumber;
