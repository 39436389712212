import { any } from "prop-types";
import axios from 'axios';
import {getBaseApiUrl} from "../../../utils/url-constant";


const appSignIn = (data: any) => {

    let url = getBaseApiUrl() + "/consumer/token" ;
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
   return axios.post(`${url}`, data);  
}


const AppSignInService = {
    appSignIn,
};

export default AppSignInService;

