import { guidelineErroMsg, inputErrorMsg } from "../../../../utils/errorMessageUtil";
import { formatAddress } from "../utilities/formatHelper";
import { CountyEnums, StateEnums } from "../enums/addressEnum";
import { ItemGroupChoice } from "../utilities/itemGroupUtilities";
import { useAppDispatch } from "../../../../app/hooks";
//import {useAppSelector} from "../../../../app/hooks";

interface AddressState {
    streetAddress: string;
    streetAddress2: string;
    city: string;
    countyCode: string;
    stateCode: string;
    postalCode: string;
    postalCodeExtension: string;
    addressId: string;
}

interface AddressValidationState {
    isValid: boolean;
    streetAddressMsg: string;
    streetAddress2Msg: string;
    cityMsg: string;
    stateMsg: string;
    countyMsg: string;
    postalCodeMsg: string;
}

const initializeAddressState = (): AddressState => {
    return {
        streetAddress: "",
        streetAddress2: "",
        city: "",
        countyCode: "",
        stateCode: StateEnums.Nebraska,
        postalCode: "",
        postalCodeExtension: "",
    } as AddressState;
};

const validateAddressByAddress = (
    address: AddressState,
    validateStreetAddress = true,
    isApplicant = true,
    validateCity = true,
    validatePostalCode = true
): AddressValidationState => {
    return validateAddress(
        address.streetAddress,
        address.city,
        address.stateCode,
        address.postalCode,
        address.postalCodeExtension,
        validateStreetAddress,
        isApplicant,
        validateCity,
        validatePostalCode
    );
};

const validateAddress = (
    streetAddress: string,
    city: string,
    stateCode: string,
    postalCode: string,
    postalCodeExt: string,
    validateStreetAddress = true,
    isApplicant = true,
    validateCity = true,
    validatePostalCode =true
): AddressValidationState => {
    const validationResult = {} as AddressValidationState;
    validationResult.isValid = false;
    const possessive = isApplicant ? "your" : "their";

    if (validateStreetAddress && !streetAddress)
        validationResult.streetAddressMsg = inputErrorMsg(`${possessive} street address`);
    if (!city && validateCity) validationResult.cityMsg = inputErrorMsg(`${possessive} city`);
    if (!stateCode) validationResult.stateMsg = inputErrorMsg(`${possessive} state`);
    validationResult.postalCodeMsg = validateZipCode(postalCode, postalCodeExt, validatePostalCode, isApplicant);
    validationResult.isValid =
        !validationResult.streetAddressMsg &&
        !validationResult.cityMsg &&
        !validationResult.stateMsg &&
        !validationResult.postalCodeMsg;
    return validationResult;
};

const validateZipCode = (
    postalCode: string,
    postalCodeExt: string,
    isRequired: boolean,
    isApplicant = true
): string => {
    const possessive = isApplicant ? "your" : "their";
    const CapPossessive = isApplicant ? "Your" : "Their";
    let errorMessage = "";
    const zipCode5Num = Number(postalCode);
    const zipCode4Num = Number(postalCodeExt);

    if (!isRequired && !postalCode && !postalCodeExt) return errorMessage;

    if (isRequired && !postalCode) {
        errorMessage = inputErrorMsg(`${possessive} zip code`);
    } else {
        if (
            Number.isNaN(zipCode5Num) ||
            !Number.isInteger(zipCode5Num) ||
            postalCode.length !== 5
        ) {
            errorMessage = guidelineErroMsg(
                `${CapPossessive} zip code`,
                "be 5 digits or 5 digits plus 4 digits extension"
            );
        }
        if (!!postalCodeExt && postalCodeExt.length > 0) {
            if (
                Number.isNaN(zipCode4Num) ||
                !Number.isInteger(zipCode4Num) ||
                postalCodeExt.length !== 4
            ) {
                errorMessage =
                    errorMessage || errorMessage.length > 0
                        ? errorMessage
                        : guidelineErroMsg(`${CapPossessive} zip code extension`, "be 4 digits");
            }
        }
    }
    return errorMessage;
};

const updateAddressStateOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    addressState: AddressState,
    inputName: string
): AddressState => {
    //Update Address fields
    let key = "";
    if (event.target.name === `${inputName}.line_1`) key = "streetAddress";
    else if (event.target.name === `${inputName}.line_2`) key = "streetAddress2";
    else if (event.target.name === `${inputName}.city`) key = "city";
    else if (event.target.name === `${inputName}.county`) key = "countyCode";
    else if (event.target.name === `${inputName}.state`) key = "stateCode";
    else if (event.target.name === `${inputName}.zip5`) key = "postalCode";
    else if (event.target.name === `${inputName}.zip4`) key = "postalCodeExtension";

    if (!key) return {} as AddressState;

    if (key === "stateCode") {
        //handle county change as well
        const countyValue =
            event.target.value === StateEnums.Nebraska
                ? addressState.countyCode === CountyEnums.OutOfState
                    ? ""
                    : addressState.countyCode
                : event.target.value
                    ? CountyEnums.OutOfState
                    : "";
        return {
            ...addressState,
            [key]: event.target.value,
            countyCode: countyValue,
        };
    } else {
        return { ...addressState, [key]: event.target.value };
    }
};

// Very simple address service that uses the address state to check to see if an address is in state.
// checkInState(householdAddressStore.address);
// returns: boolean
const checkInState = (addressState: AddressState) => {
    return addressState.stateCode === StateEnums.Nebraska;
};

const convertAddressToItemGroupChoice = (
    address: AddressState,
    addressType: string,
    selected: string
): ItemGroupChoice => {
    return {
        label: formatAddress(address),
        value: addressType,
        checked: addressType === selected,
    } as ItemGroupChoice;
};

enum AddressTypeChoices {
    "otherHomeAddress" = "Other home address",
    "noHomeAddress" = "No home address",
    "householdAddress" = "household-address",
    "mailingAddress" = "mailing-address"
}

/**
 * Use to get ItemGroupChoice[] options for Addresses
 * @param householdAddress The applicant's household address
 * @param applicantMailing The applican'ts mailing address
 * @param value The selected value to match
 * @returns ItemGroupChoice[] of options
 */
const combineAddressChoices = (
    householdAddress: AddressState,
    applicantMailing: AddressState,
    selected: string
): ItemGroupChoice[] => {
    const ret: ItemGroupChoice[] = [];

    if (householdAddress.streetAddress.length > 0) {
        ret.push(convertAddressToItemGroupChoice(householdAddress, AddressTypeChoices.householdAddress, selected));
    }
    if (applicantMailing.streetAddress.length > 0) {
        ret.push(convertAddressToItemGroupChoice(applicantMailing, AddressTypeChoices.mailingAddress, selected));
    }
    ret.push({
        label: AddressTypeChoices.otherHomeAddress,
        value: AddressTypeChoices.otherHomeAddress,
        checked: selected === AddressTypeChoices.otherHomeAddress,
    });
    ret.push({
        label: AddressTypeChoices.noHomeAddress,
        value: AddressTypeChoices.noHomeAddress,
        checked: selected === AddressTypeChoices.noHomeAddress,
    });

    return ret;
};



export type { AddressState, AddressValidationState };
export {
    checkInState,
    initializeAddressState,
    validateAddress,
    validateAddressByAddress,
    updateAddressStateOnChange,
    validateZipCode,
    combineAddressChoices,
    AddressTypeChoices,
};
