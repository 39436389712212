import { useState } from "react";
import InputDate from "./InputDate";

// This component Lets you input a date and it will validate it and dispatch
// the result to store in yyyy-mm-dd

interface props {
    setAnswer?: (date: string | null) => void;
    errorMessage?: string;
    values?: string | null;
    /**
     * Localized hint text for the entire fieldset
     */
    hint?: React.ReactNode;
    /**
     * Legend text
     */
    legend?: React.ReactNode;
    legendStyle?: "default" | "large";
    /**
     * Should the legend be bold faced
     */
    legendBold?: boolean;
    negativeMargin?: boolean;
    /** Should the component return an invalid date on change? Default false */
    returnInvalidDate?: boolean;
    /**
     * Should the labels be bold faced
     */
    labelBold?: boolean;
}

const UpgradedDateInput = ({ ...props }: props) => {
    const [day, setDay] = useState<string>(props.values?.slice(8, 10) || "");
    const [month, setMonth] = useState<string>(props.values?.slice(5, 7) || "");
    const [year, setYear] = useState<string>(props.values?.slice(0, 4) || "");
    const returnInvalid = props.returnInvalidDate ?? false;

    const setAndCheckFinalValue = (d: string, m: string, y: string) => {
        if (day && month && year && y.length > 3 || returnInvalid) {
            const finalMonth = m.length === 1 ? `0${m}` : m;
            const finalDay = d.length === 1 ? `0${d}` : d;
            const NewFullAnswer = `${y}-${finalMonth}-${finalDay}`;
            props.setAnswer && props.setAnswer(NewFullAnswer);
        } else {
            props.setAnswer && props.setAnswer(null);
        }
    };

    const parseValue = (value: string) => {
        const valueNormalized = value.split("");
        const formattedValue = valueNormalized.filter((val: string) => /^[0-9]*$/.test(val)).join("");
        return formattedValue;
    };

    const onChangeDay = (
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newValue = parseValue(e.target.value);
        const newInt = parseInt(e.target.value);
        if (newInt > 31) {
            setDay((prev) => prev);
        } else {
            setDay(newValue);
            setAndCheckFinalValue(newValue, month, year);
        }
    };

    const onChangeMonth = (
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newValue = parseValue(e.target.value);
        const newInt = parseInt(e.target.value);
        if (newInt > 12) {
            setMonth((prev) => prev);
        } else {
            setMonth(newValue);
            setAndCheckFinalValue(day, newValue, year);
        }
    };

    const onChangeYear = (
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newValue = parseValue(e.target.value);
        if (newValue.length > 4) {
            setYear((prev) => prev);
        } else {
            setYear(newValue);
            setAndCheckFinalValue(day, month, newValue);
        }
    };

    return (
        <div className={props.negativeMargin ? "benefit-discovery-negative-margin-top" : ""}>
            <InputDate
                legendBold={props.legendBold}
                hint={props.hint}
                legend={props.legend}
                legendStyle={props.legendStyle}
                name="date"
                yearLabel="Year"
                monthLabel="Month"
                dayLabel="Day"
                dayValue={day}
                onChangeDay={onChangeDay}
                monthValue={month}
                onChangeMonth={onChangeMonth}
                yearValue={year}
                onChangeYear={onChangeYear}
                errorMsg={props.errorMessage}
                labelBold={props.labelBold}
            />
        </div>
    );
};

export default UpgradedDateInput;
