import React from "react";
import Alert from "./Alert";

const ErrorSummary = (props: ErrorSummaryProps) => {
    const errorCount = props.totalErrorCount ? props.totalErrorCount : props.errorMessages.length;
    const heading = `${errorCount} error${errorCount > 1 ? "s" : ""} ${errorCount > 1 ? "were" : "was"} encountered`;

    return (
        <React.Fragment>
            {errorCount > 0 && props.errorMessages.length> 0 && (
                <div className="margin-top-1">
                    <Alert heading={heading}>
                        {props.errorMessages.map((error, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <li>{error}</li>
                                </React.Fragment>
                            );
                        })}
                    </Alert>
                </div>
            )}
        </React.Fragment>
    );
};

interface ErrorSummaryProps {
    errorMessages: string[];
    totalErrorCount?:number;
}

export default ErrorSummary;
