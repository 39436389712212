import { store } from "../../../app/store";
import {OrganizationRegistrationRepresentativeState} from "../store/step1OrganizationRegistrationRepresentativeSlice";
import {UserRegistrationRepresentativeState} from "../store/step2UserRegistrationRepresentativeSlice"
import {PrivacySecurityRepresentativeState} from "../store/step3PrivacySecurityRepresentativeSlice"

const OrgnizationRegistrationTransformService = () => {
    const data = initializeOrgnizationRegistrationModel();
    const storeCopy = store.getState();

    const orgRegistrationData:OrganizationRegistrationRepresentativeState[] = storeCopy.orgRegistrationStep1RepDetails ;
    const userRegistrationData:UserRegistrationRepresentativeState[] = storeCopy.userRegistrationRepDetails ;
    const privacyRegistrationData:PrivacySecurityRepresentativeState[] = storeCopy.privacyPolicyRepDetails ;

    var phoneNumber = userRegistrationData[0].phoneNumber.replace(/[^\w]/gi, '')
    
     data.orgName = orgRegistrationData[0].orgName;
     data.orgTaxId = orgRegistrationData[0].fedTaxId;
     if (orgRegistrationData[0].physicalAdress) {
        data.orgPhyAdd1 = orgRegistrationData[0].physicalAdress.streetAddress;
        data.orgPhyAdd2 = orgRegistrationData[0].physicalAdress.streetAddress2;
        data.orgPhyAddCity = orgRegistrationData[0].physicalAdress.city;
        data.orgPhyAddSt = orgRegistrationData[0].physicalAdress.stateCode;
        data.orgPhyAddZip = orgRegistrationData[0].physicalAdress.postalCode;
        data.orgPhyAddZipExt = orgRegistrationData[0].physicalAdress.postalCodeExtension;
        data.orgPhyCountry = orgRegistrationData[0].physicalAdress.countyCode;
     }
     if (orgRegistrationData[0].mailingAdress) {
        data.orgMailAdd1 = orgRegistrationData[0].mailingAdress.streetAddress;
        data.orgMailAdd2 = orgRegistrationData[0].mailingAdress.streetAddress2;
        data.orgMailAddCity = orgRegistrationData[0].mailingAdress.city;
        data.orgMailAddSt = orgRegistrationData[0].mailingAdress.stateCode;
        data.orgMailAddZip = orgRegistrationData[0].mailingAdress.postalCode;
        data.orgMailAddZipExt = orgRegistrationData[0].mailingAdress.postalCodeExtension;
        data.orgMailCountry = orgRegistrationData[0].mailingAdress.countyCode;
     }

     data.userFirstName = userRegistrationData[0].firstName;
     data.userLastName = userRegistrationData[0].lastName;
     data.userEmail = userRegistrationData[0].email;
     data.userName = userRegistrationData[0].userName;
     data.userMobilePhone = phoneNumber;
     data.userPassword = userRegistrationData[0].password;

     data.legacyEntCd = privacyRegistrationData[0].legalEntity;
     data.stateFileCd = privacyRegistrationData[0].stateFileCd;
     data.ownedByOthrCd = privacyRegistrationData[0].ownedByOthrCd;
     data.publicRelCd = privacyRegistrationData[0].publicRelCd;
     data.privPolicyCd = privacyRegistrationData[0].privPolicyCd;
     data.privPolicyUrl = privacyRegistrationData[0].privPolicyUrl;
     data.privPolUpdateInd = privacyRegistrationData[0].privPolUpdateInd;
     data.infoSecPolicyInd = privacyRegistrationData[0].infoSecPolicyInd;
     data.ind3PrtInd = privacyRegistrationData[0].ind3PrtInd;
     data.outSideUsDevInd = privacyRegistrationData[0].outSideUsDevInd;
     data.outSideUsDataInd = privacyRegistrationData[0].outSideUsDataInd;
     data.namedOffcrFelonInd = privacyRegistrationData[0].namedOffcrFelonInd;
     data.dataBreachInd = privacyRegistrationData[0].dataBreachInd;

    
    let obj = JSON.stringify(data);
    // For API testing ...
    console.log("printing transformed JSON for backend...", obj);
    console.log("orgRegistrationData", orgRegistrationData, "userRegistrationData", userRegistrationData);
    return data;
};

export { OrgnizationRegistrationTransformService };

export interface OrgnizationRegistrationModel {
    orgName: string;
    orgTaxId: string;
    orgTaxClassCd: string;
    orgPhyAdd1: string;
    orgPhyAdd2: string;
    orgPhyAddCity: string;
    orgPhyAddSt: string;
    orgPhyAddZip: string;
    orgPhyAddZipExt: string;
    orgPhyCountry: string;
    orgMailAdd1: string;
    orgMailAdd2: string;
    orgMailAddCity: string;
    orgMailAddSt: string;
    orgMailAddZip: string;
    orgMailAddZipExt: string;
    orgMailCountry: string;

    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userMobilePhone: string;
    userName: string;
    userPassword: string;

    legacyEntCd: string;
    stateFileCd: string;
    ownedByOthrCd: string;
    publicRelCd: string;
    privPolicyCd: string;
    privPolicyUrl: string;
    privPolUpdateInd: string;
    infoSecPolicyInd: string;
    ind3PrtInd: string;
    outSideUsDevInd: string;
    outSideUsDataInd: string;
    namedOffcrFelonInd: string;
    dataBreachInd: string;
    userTypeCd: string;

   
}


export const initializeOrgnizationRegistrationModel = () => {
    return {
    orgName: "",
    orgTaxId: "",
    orgTaxClassCd: "",
    orgPhyAdd1: "",
    orgPhyAdd2: "",
    orgPhyAddCity: "",
    orgPhyAddSt: "",
    orgPhyAddZip: "",
    orgPhyAddZipExt: "",
    orgPhyCountry: "",
    orgMailAdd1: "",
    orgMailAdd2: "",
    orgMailAddCity: "",
    orgMailAddSt: "",
    orgMailAddZip: "",
    orgMailAddZipExt: "",
    orgMailCountry: "",

    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userMobilePhone: "",
    userName: "",
    userPassword: "",

    legacyEntCd: "",
    stateFileCd: "",
    ownedByOthrCd: "",
    publicRelCd: "",
    privPolicyCd: "",
    privPolicyUrl: "",
    privPolUpdateInd: "",
    infoSecPolicyInd: "",
    ind3PrtInd: "",
    outSideUsDevInd: "",
    outSideUsDataInd: "",
    namedOffcrFelonInd: "",
    dataBreachInd: "",
    userTypeCd: "",
    } as OrgnizationRegistrationModel;
};





