import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface PrivacySecurityRepresentativeState {
    legalEntity: string;
    stateFileCd: string;
    ownedByOthrCd: string;
    publicRelCd: string
    privPolicyCd: string
    privPolicyUrl: string;
    privPolUpdateInd: string;
    infoSecPolicyInd: string;
    ind3PrtInd: string;
    outSideUsDevInd: string;
    outSideUsDataInd: string;
    namedOffcrFelonInd: string;
    dataBreachInd: string;
    
}

export const initializePrivacySecurityRegistrationRepState = () => {
    return {
        legalEntity: "",
        stateFileCd: "",
        ownedByOthrCd: "",
        publicRelCd: "",
        privPolicyCd: "",
        privPolicyUrl: "",
        privPolUpdateInd: "",
        infoSecPolicyInd: "",
        ind3PrtInd: "",
        outSideUsDevInd: "",
        outSideUsDataInd: "",
        namedOffcrFelonInd: "",
        dataBreachInd: "",
    } as PrivacySecurityRepresentativeState;
};


export const getLastItemOrNew = (registrationRepArray: PrivacySecurityRepresentativeState[]) => {
    const registrationRepDetailsIndex = registrationRepArray.length - 1;
    const lastItem =
    registrationRepDetailsIndex >= 0
        ? registrationRepArray[registrationRepDetailsIndex]
        : initializePrivacySecurityRegistrationRepState();
    return { index: registrationRepDetailsIndex, item: lastItem };
};

const initialState = [] as PrivacySecurityRepresentativeState[];

const privacysecurityRepDetailsSlice = createSlice({
    name: "privacyPolicyRepDetails",
    initialState,
    reducers: {
        addPrivacyPolicyRepDetails(state, action: PayloadAction<PrivacySecurityRepresentativeState>) {
            state.push(action.payload);
        },
        updatePrivacyPolicypDetailsByIndex(
            state,
            action: PayloadAction<{ index: number; arState: PrivacySecurityRepresentativeState }>
        ) {
            
            //add new if conditions are met
            if (state.length === 0 || (state.length < 4 && action.payload.index < 0)) {
                state.push(action.payload.arState);
            } else {
                //grab the item by index or most recent;
                const validIndex =
                    action.payload.index >= state.length ? state.length - 1 : action.payload.index;
                const stateObj = state[validIndex];
                for (const [key, value] of Object.entries(action.payload.arState)) {
                    stateObj[key as keyof PrivacySecurityRepresentativeState] = value;
                }
            }
        },
        removeRegistrationRepDetails(state, action: PayloadAction<number>) {
            const index = action.payload;
            //do not remove if index is out of index range
            if (index < 0 || index >= state.length) return;
            state.splice(index, 1);
        },
        removeAll(state) {
            state.splice(0, state.length);
        },
    },
});

export const {
    addPrivacyPolicyRepDetails,
    updatePrivacyPolicypDetailsByIndex,
    removeRegistrationRepDetails,
    removeAll,
} = privacysecurityRepDetailsSlice.actions;

export default privacysecurityRepDetailsSlice.reducer;




