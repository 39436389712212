import { any } from "prop-types";
import axios from 'axios';
import {getBaseApiUrl} from "../../../utils/url-constant";


const b2cCodeApi = (b2cCode: any) => {

    let url = getBaseApiUrl() + "/b2c/" +  b2cCode;
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
   return axios.get(`${url}`);
        
}

const b2cCodeLogoutApi = () => {
  let url = "https://stateofneb2cdev.b2clogin.com/stateofneb2cdev.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_DHHS_INTEROP_PIPELINE_SIGNUP_SIGNIN&post_logout_redirect_uri=https://red-ocean-02af07310.1.azurestaticapps.net";
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
   return axios.get(`${url}`);
}
const B2CService = {
    b2cCodeApi,
    b2cCodeLogoutApi,
    
  };
  export default B2CService;