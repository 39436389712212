import "../Modal/CustomModal";
import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import { Applications } from "../model/Applications";

interface AppStatusPageProps {
    
    showPopup: boolean,
    okButtonText?: string
    closeButtonText?: string
    data: Applications
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;
}

function AppDetailsPage(props: AppStatusPageProps) {

    const statusDropdownChoices = [
        {
            label: "Approved",
            value: "AP",
        },
        {
            label: "Rejected",
            value: "RJ",
        },
        {
            label: "In Review",
            value: "IR",
        },
        {
            label: "Requires Additional Information",
            value: "RA",
        }
    ]

    const getApplicationStatusName = (statusCode: any) => {
        let statusName = statusCode;
        if (statusCode) {
            statusDropdownChoices.forEach(statusDropDown => {
                if (statusDropDown.value == statusCode) {
                    statusName = statusDropDown.label;
                }
            });
        }
        return statusName;
    }

    return (
        <>
            {(props && props.showPopup && (
                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>Application Details</h2>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {props.closeButtonText || "Close"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div className={"ModalText"}>
                                   <InputText
                                        context="appStatusModal"
                                        name="applicationId"
                                        label="Application Id"
                                        value={props.data?.appId}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                    <InputText
                                        context="appStatusModal"
                                        name="applicationName"
                                        label="Application Name"
                                        value={props.data?.appName}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                    <InputText
                                        context="appStatusModal"
                                        name="applicatioDesc"
                                        label="Application Description"
                                        value={props.data?.appDesc}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                    <InputText
                                        context="appStatusModal"
                                        name="applicationVersion"
                                        label="Application Version"
                                        value={props.data?.appNum}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                    <InputText
                                        context="appStatusModal"
                                        name="organization"
                                        label="Organization"
                                        value={props.data?.orgId}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                     <InputText
                                        context="appStatusModal"
                                        name="applicationSupportEmail"
                                        label="Application Support Email"
                                        value={props.data?.appSptEmail}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                     <InputText
                                        context="appStatusModal"
                                        name="applicationSupportPhone"
                                        label="Application Support Phone Number"
                                        value={props.data?.appSptPhone}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                     <InputText
                                        context="appStatusModal"
                                        name="applicationSupportUrl"
                                        label="Application Support URL"
                                        value={props.data?.appSptUrl}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                    <InputText
                                        context="appStatusModal"
                                        name="updatedDate"
                                        label="Last Updated Date"
                                        value={props.data?.updateDt ? props.data.updateDt: ""}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                    <InputText
                                        context="appStatusModal"
                                        name="status"
                                        label="Status"
                                        value={getApplicationStatusName(props.data?.appStatusCd)}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                     <InputText
                                        context="appStatusModal"
                                        name="comments"
                                        label="Comments"
                                        value={props.data?.comment}
                                        readOnly= {true}
                                        formGroupInputClasses = "usa-input-group-readonly"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )) ||
              null}
        </>
    );
}

export default AppDetailsPage;