import classnames from "classnames";
import useUniqueId from "./hooks/useUniqueId";
import FormLabel from "./FormLabel";
import { DropDownItemChoice } from "../features/BenefitsApplication/shared/utilities/itemGroupUtilities";

/**
 * A dropdown (`select`) allows users to select one option from a temporary modal menu.
 * Also renders supporting UI elements like a `label`, hint text, and error message.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/dropdown/)
 */
function Dropdown(props: DropdownProps) {
    const hasError = !!props.errorMsg || props.showErrorWithoutMessage;
    const inputId = useUniqueId("Dropdown");

    const fieldClasses = classnames("usa-select", {
        "usa-input--error": hasError,
    });

    const formGroupClasses = classnames("usa-form-group", {
        "usa-form-group--error": hasError,
        "margin-top-2": props.small,
    });

    return (
        <div className={formGroupClasses}>
            <FormLabel
                errorMsg={props.errorMsg || (props.showErrorWithoutMessage && " ")}
                hint={props.hint}
                inputId={inputId}
                bold={props.bold}
                optionalText={props.optionalText}
                labelSuffix={props.labelSuffix}
            >
                {props.label}
                {props.mandatoryLabel && (
                    <span className="usa-mandatory-label">
                        *
                    </span>
                )}
            </FormLabel>

            <select
                className={fieldClasses}
                id={inputId}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                data-item-id={props.itemId}
                disabled={props.disabled}
            >
                {/* Include a blank initial option, which will be chosen if no option has been selected yet */}
                {!props.hideEmptyChoice && <option value="">{props.emptyChoiceLabel}</option>}

                {props.choices.map((choice) => (
                    <option key={choice.value} value={choice.value}>
                        {choice.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export interface DropdownProps {
    /**
     * If you want to show it as an error without the message
     */
    showErrorWithoutMessage?: boolean | undefined;
    /**
     * List of choices to be rendered in the dropdown
     */
    choices: DropDownItemChoice[];
    /**
     * Localized label for the initially selected option when no value is set
     */
    emptyChoiceLabel: string;
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Localized hint text
     */
    hint?: React.ReactNode;
    /**
     * Localized label
     */
    label: React.ReactNode;
    /**
     * HTML input `name` attribute
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * HTML input `onChange` attribute
     */
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    /**
     * Localized text indicating this field is optional
     */
    optionalText?: React.ReactNode;
    /** The `value` of the selected choice */
    value: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * Enable the bold variant of label
     */
    bold?: boolean;
    small?: boolean;
    /** event.target.dataset.itemId to determine which item in a series you are updating */
    itemId?: string;
    /** flag to hide empty choice */
    hideEmptyChoice?: boolean;
    /** disable the dropdown */
    disabled?: boolean;
    /**
     * Localized text to append after label inline
     */
    labelSuffix?: string;
    mandatoryLabel?: boolean;
}

export default Dropdown;
