import Fieldset from "./Fieldset";
import InputText from "./InputText";
import ZipCodeInput from "./ZipCodeInput";
import CountyDropdown from "./CountyDropdown";
import StateDropdown from "./StateDropdown";
import { useState } from "react";
import FormLabel from "./FormLabel";
import { StateEnums } from "../features/BenefitsApplication/shared/enums/addressEnum";

/**
 * Address text fields. Also renders supporting UI elements like label and
 * hint text.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/templates/form-templates/address-form/)
 */
function FieldsetAddress(props: FieldsetAddressProps) {
    const [showCountyDropdown, setShowCountyDropdown] = useState(
        props.state.value === StateEnums.Nebraska
    );

    const stateCodeOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setShowCountyDropdown(event.target.value === StateEnums.Nebraska);
        props.onChange && props.onChange(event);
    };

    return (
        <Fieldset
            hint={props.hint}
            legend={props.legend}
            legendStyle={props.legendStyle}
            errorMsg={props.errorMsg}
        >
            {!props.hideStreetAddress && (
                <InputText
                    context=""
                    autoComplete="address-line1"
                    label={props.address1.label || "Street address"}
                    name={`${props.name}.line_1`}
                    onChange={props.onChange}
                    readOnly={props.readOnly}
                    {...props.address1}
                    formGroupInputClasses={props.formGroupInputClasses}
                />
            )}

            {!props.hideStreetAddress2 && (
                <InputText
                    context=""
                    autoComplete="address-line2"
                    label={props.address2.label || "Street address 2"}
                    name={`${props.name}.line_2`}
                    optionalText="Optional"
                    onChange={props.onChange}
                    data-testid={`${props.name}.line_2`}
                    readOnly={props.readOnly}
                    {...props.address2}
                />
            )}

            <InputText
                context=""
                autoComplete="address-level2"
                label={props.city.label || "City"}
                name={`${props.name}.city`}
                onChange={props.onChange}
                data-testid={`${props.name}.city`}
                readOnly={props.readOnly}
                {...props.city}
                formGroupInputClasses={props.formGroupInputClasses}
            />
            
            {!props.hideState && (
                <StateDropdown
                    {...props.state}
                    emptyChoiceLabel="Select -"
                    label={props.state.label || "State"}
                    name={`${props.name}.state`}
                    onChange={stateCodeOnChange}
                    data-testid={`${props.name}.state`}
                    value={props.state.value}
                    disabled= {props.disabled}
                    formGroupInputClasses={props.formGroupInputClasses}
                />
            )}

            {!props.hideCounty && 
                (showCountyDropdown ?
                    <CountyDropdown
                        emptyChoiceLabel="Select -"
                        label={props.county.label || "County"}
                        name={`${props.name}.county`}
                        onChange={props.onChange}
                        data-testid={`${props.name}.county`}
                        {...props.county}
                        disabled= {props.disabled}
                        formGroupInputClasses={props.formGroupInputClasses}
                    />
                    :
                    <InputText
                        context=""
                        label={props.county.label || "County"}
                        name={`${props.name}.county`}
                        data-testid={`${props.name}.county`}
                        readOnly={true}
                        {...props.county}
                        formGroupInputClasses={props.formGroupInputClasses}
                    />
                )
            }
            {/* Zip code with one textbox. Deprecated. */}
            {/* <InputText
            autoComplete="postal-code"
            inputMode="numeric"
            label={props.postalCode.label || "Postal Code"}
            placeholder="#####-####"
            name={`${props.name}.zip`}
            pattern="[\d]{5}(-[\d]{4})?"
            title="enter 5 digits (#####) or 9 digts (#####-####) postal code"
            width="medium"
            onChange={props.onChange}
            {...props.postalCode}
        /> */}
            <ZipCodeInput
                label={props.postalCode.label || "ZIP"}
                name={`${props.name}.zip`}
                width="medium"
                onChange={props.onChange}
                data-testid={`${props.name}.zip`}
                {...props.postalCode}   
                readOnly={props.readOnly}  
                mandatoryLabel= {props.postalCode.mandatoryLabel}           
            />
        </Fieldset>
    );
}

type FieldsetAddressProps = {
    /**
     * Localized hint text for the entire fieldset
     */
    hint?: React.ReactNode;
    /**
     * Localized label for the entire fieldset
     */
    legend: React.ReactNode;
    legendStyle?: "default" | "large";
    /**
     * The root HTML name value. Each field will use a name with
     * this as the prefix.
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Flag for hiding county input.
     */
    hideCounty?: boolean;
    /**
     * Flag for hiding address input.
     */
    hideStreetAddress?: boolean;
    hideStreetAddress2?: boolean;
    /**  
     * Flag for hiding state input 
    */
    hideState?:boolean;

    readOnly?: boolean;

    disabled?:boolean;

    formGroupInputClasses?: string;
    /**
     * HTML input `onChange` attribute applied to each input
     */
    onChange?: React.ChangeEventHandler;
    address1: {
        value: React.InputHTMLAttributes<HTMLInputElement>["value"];
        errorMsg?: React.ReactNode;
        label?: React.ReactNode;
        mandatoryLabel?: boolean;
    };
    address2: {
        value: React.InputHTMLAttributes<HTMLInputElement>["value"];
        errorMsg?: React.ReactNode;
        label?: React.ReactNode;
        mandatoryLabel?: boolean;
    };
    city: {
        value: React.InputHTMLAttributes<HTMLInputElement>["value"];
        errorMsg?: React.ReactNode;
        label?: React.ReactNode;
        mandatoryLabel?: boolean;
    };
    county: {
        value: React.InputHTMLAttributes<HTMLInputElement>["value"];
        errorMsg?: React.ReactNode;
        label?: React.ReactNode;
        mandatoryLabel?: boolean;
    };
    state: {
        value: React.InputHTMLAttributes<HTMLInputElement>["value"];
        errorMsg?: React.ReactNode;
        label?: React.ReactNode;
        mandatoryLabel?: boolean;
    };
    postalCode: {
        errorMsg?: React.ReactNode;
        label?: React.ReactNode;
        postalCode5Value: React.InputHTMLAttributes<HTMLInputElement>["value"];
        postalCode4Value: React.InputHTMLAttributes<HTMLInputElement>["value"];
        mandatoryLabel?: boolean;
    };
};

export default FieldsetAddress;
