import classnames from "classnames";
import uniqueId from "lodash/uniqueId";

/**
 * A checkbox or radio field. In most cases, you shouldn't use this
 * component directly. Instead, use `InputChoiceGroup` which renders
 * markup for the entire fieldset.
 *
 * [USWDS Reference for Radio button↗](https://designsystem.digital.gov/components/radio-buttons/)
 * [USWDS Reference for Checkbox↗](https://designsystem.digital.gov/components/checkbox/)
 */
function InputChoice({ type = "checkbox", ...props }: InputChoiceProps) {
    // Generate a unique ID for associating the field with the label.
    // This is important for a11y.
    const inputId = uniqueId("InputRadio");
    const id = props.id || inputId;
    const hasError = !!props.errorMsg;
    const isSlim = props.slim === undefined ? true : props.slim; //Make slim the default
    const isTile = props.tile === undefined ? true : props.tile; //Make tile default

    const formGroupClasses = classnames(`usa-${type}`, props.formGroupClassName, {
        "usa-form-group": !isSlim,
        "usa-form-group--error": hasError,
        "margin-top-5": props.large,
    });

    const inputClasses = classnames([`usa-${type}__input`], {
        "usa-input--error": hasError,
        "usa-radio__input--tile": isTile && type === "radio",
        "usa-checkbox__input--tile": isTile && type === "checkbox",
    });

    const inputLabelClasses = classnames({
        "text-bold": props.bold,
    });

    return (
        <div className={formGroupClasses}>
            <input
                className={inputClasses}
                id={id}
                type={type}
                name={props.name}
                value={props.value}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
                onChange={props.onChange}
                data-item-id={props.itemId}
                data-testid={props.dataTestId}
            />
            <label className={`usa-${type}__label`} htmlFor={id}>
                <span className={inputLabelClasses}>{props.label}</span>
                {props.hint && (
                    <span className="usa-checkbox__label-description">{props.hint}</span>
                )}
                {props.errorMsg && (
                    <span className="usa-error-message" role="alert">
                        {props.errorMsg}
                    </span>
                )}
            </label>
        </div>
    );
}

interface InputChoiceProps {
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Sets the input's `checked` state. Use this for an uncontrolled component.
     */
    defaultChecked?: React.InputHTMLAttributes<HTMLInputElement>["defaultChecked"];
    /**
     * Sets the input's `checked` state. Use this in combination with `onChange`
     * for a controlled component.
     */
    checked?: React.InputHTMLAttributes<HTMLInputElement>["checked"];
    /**
     * Additional classes to include on the containing form group element
     */
    formGroupClassName?: string;
    /**
     * Unique identifier for input
     */
    id?: React.InputHTMLAttributes<HTMLInputElement>["id"];
    /**
     * Localized hint text
     */
    hint?: React.ReactNode;
    /**
     * Localized field label
     */
    label: React.ReactNode;
    /**
     * Large variant of label
     */
    large?: boolean;
    /**
     * HTML input `name` attribute
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * HTML input `onChange` attribute
     */
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    /**
     * Enable the tile style variant
     */
    tile?: boolean;
    /**
     * HTML input `type` attribute
     */
    type?: "checkbox" | "radio";
    /**
     * Sets the input's `value`
     */
    value: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * Reduce spacing
     */
    slim?: boolean;
    /**
     * (NOT HTML ID) Sets the value of a corresponding reference ID (like personId if the checkboxes are related to a person)
     * to access from HTMLInputElement, use target.dataset.itemId
     */
    itemId?: string;
    dataTestId?: string;
    bold?: boolean;
}

export default InputChoice;
