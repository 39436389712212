import "../Modal/CustomModal";
import React, { useState } from "react";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import InputText from "../../components/InputText";
import AppRegistrationService from "../application-registration/AppRegistrationService";
import { Applications } from "../model/Applications";


interface AdminAppStatusReviewPageProps {
    showPopup: boolean,
    okButtonText?: string
    closeButtonText?: string
    data: Applications
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;
}

function AdminAppStatusReviewPage(props: AdminAppStatusReviewPageProps) {

    const [reviewStatus, setReviewStatus] = useState<string>(props?.data?.appStatusCd);
    const [reviewComments, setReviewComments] = useState(props?.data?.comment);

    const statusCodeOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log("event", event.target.value);
        setReviewStatus(event.target.value);
    };

    
    const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewComments(event.target.value);
        console.log("event", event.target.value);
    };

    const okayButtonOnClick = () => {
        updateAdminAppStatus();
    };

    const updateAdminAppStatus = () => {
        let updateStatus = {
            appId: props.data.appId,
            appApprovalSts: reviewStatus,
            comment: reviewComments
        }
       
        AppRegistrationService.updateApplication(updateStatus)
          .then(response => {
            console.log(response.data)
            setReviewStatus("");
            setReviewComments("");
            props.agreeBtnOnClick && props.agreeBtnOnClick(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    };

    const statusDropdownChoices = [
        {
            label: "Approved",
            value: "AP",
        },
        {
            label: "Rejected",
            value: "RJ",
        },
        {
            label: "In Review",
            value: "IR",
        },
        {
            label: "Requires Additional Information",
            value: "RA",
        }];

       
    

    return (
        <>
            {(props && props.showPopup && (
                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>Review Page</h2>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {props.closeButtonText || "Close"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div className={"ModalText"}>
                                    <Dropdown
                                      choices={statusDropdownChoices}
                                      emptyChoiceLabel="Select Status"
                                      label="Status"
                                      name="reviewStatusDropDown"
                                      onChange={statusCodeOnChange}
                                      data-testid="reviewStatusDropDown"
                                      value={reviewStatus}></Dropdown>
                                    <InputText
                                        context="review"
                                        name="Comments"
                                        label="Comments"
                                        value= {reviewComments}
                                        onChange={handleCommentsChange}
                                        type ="textarea"
                                    />
                                    <Button className="ModalButton" onClick={props.closeBtnOnClick}> {props.closeButtonText || "Cancel"} </Button>
                                    <Button className="ModalButton" onClick={okayButtonOnClick}> {props.okButtonText || "OK"} </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )) ||
              null}
        </>
    );
        
}

export default AdminAppStatusReviewPage;