import React, { useState, useEffect } from 'react'
import '../styles/_table.scss'
import Button from '../components/Button'
import isFunction from 'lodash/isFunction'
import TableCheckBox from '../../src/components/TableCheckBox'
import Avatar from '@mui/material/Avatar'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'

/** Helpers */

// helper to get an array containing the object values with
// the correct type infered.
function objectValues<T extends object>(obj: T) {
  return Object.keys(obj).map((objKey) => obj[objKey as keyof T])
}

function objectKeys<T extends object>(obj: T) {
  return Object.keys(obj).map((objKey) => objKey as keyof T)
}

type PrimitiveType = string | number | boolean

/** Component */

export interface MinTableItem {
  id?: PrimitiveType
}

export interface MinTableHeader {
  id: string
  name: string
  type: string
  sort?: boolean
  isHidden?: boolean
}

//type TableHeaders<T extends MinTableItem> = Record<keyof T, string>;

interface TableProps<T extends MinTableItem, H extends MinTableHeader> {
  items: T[]
  headers: H[]
  tableButton?: any
  sortingButton?: any
  buttonDisable?: boolean
  defaultSortColId?: string
}

export default function CustomTable<
  T extends MinTableItem,
  H extends MinTableHeader,
>(props: TableProps<T, H>) {
  const [sortOrdering, setSortOrdering] = useState('ASC')
  const [sortColumnId, setSortColumnId] = useState(
    props?.defaultSortColId ? props?.defaultSortColId : '',
  )

  const actionFromChildToParent = (item: T, header: H) => {
    const { tableButton } = props
    let data = {
      data: item,
      header: header,
    }
    isFunction(tableButton) && tableButton(data)
  }

  const sortByColumnName = (columnId: any) => {
    let sortingOrder = 'ASC'
    const { sortingButton } = props
    if (columnId == sortColumnId) {
      if (sortOrdering == 'ASC') {
        setSortOrdering('DESC')
        sortingOrder = 'DESC'
      } else {
        setSortOrdering('ASC')
      }
    } else {
      setSortColumnId(columnId)
      setSortOrdering('ASC')
    }

    let data = {
      sortColumnId: columnId,
      sortOrdering: sortingOrder,
    }
    isFunction(sortingButton) && sortingButton(data)
  }

  function renderHeader(header: H) {
    if (header.isHidden) {
      return <th></th>
    }
    if (header.type == 'checkbox') {
      return (
        <th key={header.id}>
          <TableCheckBox
            name={header.name}
            type="checkbox"
            value={1}
            formGroupClassName="margin-top-neg-20"
          />{' '}
        </th>
      )
    } else if (header.sort) {
      if (sortColumnId == header.id && sortOrdering == 'DESC') {
        return (
          <th key={header.id}>
            <span>{header.name}</span>
            <IconButton>
              <ExpandLessIcon
                onClick={() => sortByColumnName(header.id)}
                sx={{ color: 'white' }}
              />
            </IconButton>
          </th>
        )
      } else {
        return (
          <th key={header.id}>
            <span>{header.name}</span>
            <IconButton>
              <ExpandMoreIcon
                onClick={() => sortByColumnName(header.id)}
                sx={{ color: 'white' }}
              />
            </IconButton>
          </th>
        )
      }
    } else {
      return <th key={header.id}>{header.name}</th>
    }
  }

  function renderRowItemWithHeader(item: any, rowIndex: any, headers: H[]) {
    return (
      <tr>
        {headers.map((header, colIndex) => {
          let itemKey =
            `${header.type}` +
            '-' +
            `${header.id}` +
            '-' +
            `${rowIndex}` +
            '-' +
            `${colIndex}`
          if (header.isHidden) {
            return <td></td>
          }
          if (header.type == 'button') {
            return (
              <td key={itemKey}>
                <Button
                  disabled={props.buttonDisable || item?.buttonDisable}
                  className="table-button"
                  type="button"
                  onClick={() => actionFromChildToParent(item, header)}
                >
                  {item[header.id]}
                </Button>
              </td>
            )
          } else if (header.type == 'link') {
            return (
              <td key={itemKey}>
                <Button
                  className="usa-link"
                  onClick={() => actionFromChildToParent(item, header)}
                  variation="unstyled"
                >
                  {item[header.id]}
                </Button>
              </td>
            )
          } else {
            return <td key={itemKey}>{item[header.id]}</td>
          }
        })}
      </tr>
    )
  }

  return (
    <div className="flist">
      <table className="fl-table">
        <thead key="tableHeader">
          {props.headers && props.headers.map(renderHeader)}
        </thead>
        <tbody>
          {props.headers &&
            props.items &&
            props.items.map((item, index) =>
              renderRowItemWithHeader(item, index, props.headers),
            )}
        </tbody>
      </table>
      {(!props.items || props.items.length == 0) && (
        <p className="no-data">Records not found</p>
      )}
    </div>
  )
}
