import React from "react";
import Button from "../../components/Button";
import ManageConsentService from "./service/ManageConsentService"
import { ManageConsent } from "./model/ManageConsent";


interface AdminManageConsentRevokePageProps {
    showPopup: boolean,
    okButtonText?: string
    closeButtonText?: string
    data: ManageConsent
    messageText: string
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;
}

function AdminManageConsentRevokePage(props: AdminManageConsentRevokePageProps) {
    let isAdmin = true;
    const okayButtonOnClick = () => {
        updateAdminAppStatus();
    };

    const updateAdminAppStatus = () => {
        ManageConsentService.updateConsentByConsentId(props.data.id,props.data.memberId,props.data.appId,isAdmin)
          .then(response => {
            console.log("Update Conset By Id response: " + response.data)
            props.agreeBtnOnClick && props.agreeBtnOnClick(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    };

    return (
        <>
            {(props && props.showPopup && (
                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>Review Page</h2>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {props.closeButtonText || "Close"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div className={"ModalText"}>
                                    { props.messageText && (<>
                                        <p>{props.messageText}</p>
                                    </>)
                                    }
                                    <Button className="ModalButton" onClick={props.closeBtnOnClick}> {props.closeButtonText || "Cancel"} </Button>
                                    <Button className="ModalButton" onClick={okayButtonOnClick}> {props.okButtonText || "OK"} </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )) ||
              null}
        </>
    );
        
}

export default AdminManageConsentRevokePage;