import {
  AppInsightsErrorBoundary,
  withAITracking,
} from '@microsoft/applicationinsights-react-js'
import { MsalProvider } from '@azure/msal-react'

import React from 'react'
import { Route } from 'react-router-dom'
import { reactPlugin } from './AppInsights'
import NavigationConstants from './features/BenefitsApplication/shared/enums/navigation'
import './styles/app.scss'
import CmsInteropHomePage from './features/cms-interop-landing-home-page/cms-interop-home-page'
import IdmePage from './features/id.me-page/idme'
import IdmePageDummy from './features/id.me-page-dummy/id.me-dummy'
import AppFeedBackPage from './features/static-pages/app-feedback'
import ApiLandingPage from './features/api-landing-page/api-landing-page'
import ExploreApis from './features/explore-apis/exploreapi'
import ApplicationStatus from './features/application-status/application-status'
import AppRegistrationStep1 from './features/application-registration/application-registration'

import AdminLandingPage from './features/admin-landing-page/admin-landing-page'
import AdminSignInPage from './features/admin-signin/admin-signin'
import AdminSignInLandingPage from './features/admin-signin-landing-page/admin-signin-landing-page'
import UserRegistrationLandingPage from './features/user-registration-landing-page/user-registraton-landing'
import AppownerLandingPage from './features/app-owner-landing-page/app-owner-landing-page'
import UserAuthorizedLandingPage from './features/user-authorized-landing-page/user-authorized-landing'

import AdminOrganizationList from './features/admin-organization-list/admin-organization-list'
import AdminManageConsentListPage from './features/admin-manage-consent/admin-manage-consent-list'
import AdminAppStatusListPage from './features/admin-app-status/admin-app-status-list'

import LinkMyMedicaidPage from '../src/features/link-my-medicaid/link-my-medicaid-page'

import UserManageConsentList from './features/user-manage-consent/user-manage-consent-list'

import OrganizationUserSecurityHomePage from '../src/features/organization-user-security-registration/organization-user-security-home-page'
import OrgFeedBackPage from './features/static-pages/org-feedback'

import AppSignInPage from './features/app-signin/app-signin'
import B2CSignInPage from './features/b2c-signin/b2c-signnin'
import APIDetails from './features/api-details/api-details'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function App({ msalInstance }: any) {
  const userRegProps = {
    step: 0,
    showDrpDown: true,
  }

  return (
    <AppInsightsErrorBoundary
      onError={() => <h1>Something went wrong please try again</h1>}
      appInsights={reactPlugin}
    >
      <React.Fragment>
        <MsalProvider instance={msalInstance}>
          {/* <a className="usa-skipnav" href="#main">
                        Skip to main content
                    </a>
                    <header className="usa-header usa-header--basic">
                        <div className="usa-nav-container">
                            <div className="usa-navbar">
                                <div className="usa-logo" id="basic-logo">
                                    <em className="usa-logo__text">
                                        <Link to="/">Medicaid User Registration</Link>
                                    </em>
                                </div>
                                <button className="usa-menu-btn">Menu</button>
                            </div>
                        </div>
                    </header> */}
          <main id="main">
            <div className="container-fluid">
              <div className="grid-row">
                <div className="grid-col">
                  <Route exact path="/">
                    <CmsInteropHomePage />
                  </Route>
                  <Route
                    path={NavigationConstants.UserDigitalRegistration}
                    component={LinkMyMedicaidPage}
                  />

                  <Route
                    path={NavigationConstants.UserManageConsentList}
                    component={UserManageConsentList}
                  />

                  <Route
                    path={NavigationConstants.OrganizationUserSecurityHomePage}
                    component={OrganizationUserSecurityHomePage}
                  />
                  <Route
                    path={NavigationConstants.AppownerLandingPage}
                    component={AppownerLandingPage}
                  />
                  <Route
                    path={NavigationConstants.UserRegistrationLandingPage}
                    component={UserRegistrationLandingPage}
                  />
                  <Route
                    path={NavigationConstants.UserAuthorizedLandingPage}
                    component={UserAuthorizedLandingPage}
                  />

                  <Route
                    path={NavigationConstants.AppFeedbackPage}
                    component={AppFeedBackPage}
                  />
                  <Route
                    path={NavigationConstants.OrgFeedbackPage}
                    component={OrgFeedBackPage}
                  />
                  <Route
                    path={NavigationConstants.ApiLandingPage}
                    component={ApiLandingPage}
                  />
                  <Route
                    path={NavigationConstants.AppSignInPage}
                    component={AppSignInPage}
                  />
                  <Route
                    path={NavigationConstants.B2CSignInPage}
                    component={B2CSignInPage}
                  />
                  <Route
                    path={NavigationConstants.ExploreApis}
                    component={ExploreApis}
                  />
                  <Route
                    path={NavigationConstants.ApplicationStatus}
                    component={ApplicationStatus}
                  />
                  <Route
                    path={NavigationConstants.APIDetails}
                    component={APIDetails}
                  />
                  <Route
                    path={NavigationConstants.AppRegstep1}
                    component={AppRegistrationStep1}
                  />

                  <Route
                    path={NavigationConstants.AdminLandingPage}
                    component={AdminLandingPage}
                  />
                  <Route
                    path={NavigationConstants.AdminSignInLandingPage}
                    component={AdminSignInLandingPage}
                  />
                  {/* <Route path={NavigationConstants.AdminSignInPage} component={AdminSignInPage
                                    } /> */}
                  <Route
                    path={NavigationConstants.AdminManageConsentList}
                    component={AdminManageConsentListPage}
                  />
                  <Route
                    path={NavigationConstants.AdminAppStatusList}
                    component={AdminAppStatusListPage}
                  />
                  <Route
                    path={NavigationConstants.AdminOrganizationList}
                    component={AdminOrganizationList}
                  />
                </div>
              </div>
            </div>
          </main>
        </MsalProvider>
      </React.Fragment>
    </AppInsightsErrorBoundary>
  )
}

export default withAITracking(reactPlugin, App)
