
import {getBaseApiUrl} from "../../../utils/url-constant";
import axios from 'axios';

const getAllConsentList = () => {
  let url = getBaseApiUrl() + "/admin/consentlist" ; 
  axios.defaults.baseURL = '';
  const customHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Api-Key': process.env.REACT_APP_X_API_KEY,
  };
  return axios.get(`${url}`, { headers: customHeaders });
};

const getAllByMemberId = (memberId: any) => {
  let url =  getBaseApiUrl() + "/admin/consentlist"
  if (memberId) {
    url = url + "/" + memberId ;
  }
  axios.defaults.baseURL = '';
  const customHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Api-Key': process.env.REACT_APP_X_API_KEY,
  };
  return axios.get(`${url}`, { headers: customHeaders });
};

const updateConsentByConsentId = (id:any,memberId:any,appId:any,isAdmin: boolean) => {
  let url =  getBaseApiUrl() + "/consent"
  if (id) {
    url = url + "/" + id + "/" + memberId + "/" + appId + "/" + isAdmin;
  }
  axios.defaults.baseURL = '';
  axios.defaults.headers.common['Content-Type'] = "application/json";
  axios.defaults.headers.common['Accept'] = "*/*";
  return axios.put(`${url}`);
}


const ManageConsentService = {
  getAllConsentList,
  getAllByMemberId,
  updateConsentByConsentId
  
};
export default ManageConsentService;