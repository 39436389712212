import classnames from "classnames";
import React from "react";
import { ItemGroupChoice } from "../features/BenefitsApplication/shared/utilities/itemGroupUtilities";
import Fieldset from "./Fieldset";
import InputChoice from "./InputChoice";

/**
 * A grouping of checkbox or radio fields. Also renders supporting UI
 * elements like a `legend` and error message.
 *
 * [USWDS Reference for Radio button↗](https://designsystem.digital.gov/components/radio-buttons/)
 * [USWDS Reference for Checkbox↗](https://designsystem.digital.gov/components/checkbox/)
 */
function InputChoiceGroup({ type = "checkbox", ...props }: InputChoiceGroupProps) {
    const hasError = !!props.errorMsg;
    const useTile = props.tile === undefined ? true : props.tile;
    const formGroupClasses = classnames(
        "usa-form-group",
        {
            "usa-form-group--error": hasError,
        },
        props.className
    );

    return (
        <Fieldset
            className={formGroupClasses}
            legend={props.legend}
            legendStyle={props.legendStyle}
            legendBold={props.legendBold}
            errorMsg={props.errorMsg}
            hint={props.hint}
        >
            {props.choices.map((choice) => (
                <React.Fragment key={choice.label + "." + choice.value}>
                    <InputChoice
                        name={props.name}
                        onChange={props.onChange}
                        type={type}
                        tile={useTile}
                        slim={props.slim}
                        bold={props.inputBold}
                        dataTestId={
                            (props.context ? props.context + "." : "") +
                            props.name +
                            "." +
                            choice.value?.toString().replace(/[^\w.]/gi, "")
                        }
                        {...choice}
                        itemId={choice.itemId ?? props.itemId}
                    />
                </React.Fragment>
            ))}
        </Fieldset>
    );
}

interface InputChoiceGroupProps {
    /**
     * List of choices to be rendered as individual checkbox/radio fields.
     */
    choices: ItemGroupChoice[];
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Legend text
     */
    legend?: React.ReactNode;
    legendStyle?: "default" | "large";
    legendBold?: boolean;
    hint?: string;
    /**
     * HTML input `name` attribute applied to each choice in the list
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * Localized text indicating this fieldset is optional
     */
    optionalText?: React.ReactNode;
    /**
     * HTML input `onChange` attribute applied to each choice in the list
     */
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    /**
     * Enable the tile style variant
     */
    tile?: boolean;
    /**
     * HTML input `type` attribute applied to each choice in the list
     */
    type?: "checkbox" | "radio";
    /**
     * Reduce spacing
     */
    slim?: boolean;
    className?: string;
    context?: string;
    inputBold?: boolean;
    itemId?: string;
}

export default InputChoiceGroup;
