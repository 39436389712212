import { any } from "prop-types";
import axios from 'axios';
import { OrgnizationRegistrationTransformService } from "./orgnizationRegistrationTransformService";
import {getBaseApiUrl} from "../../../utils/url-constant";


const createOrgnization = () => {
    const compiledAppData = OrgnizationRegistrationTransformService();

    let url = getBaseApiUrl() + "/orgnization/registration";
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
   return axios.post(`${url}`, compiledAppData);
        
}

const createOrgnizationUserSecurityRegistration = (data: any) => {
    let url = getBaseApiUrl() + "/orgnization/registration";    
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
   return axios.post(`${url}`, data);
        
}


const OrgnizationRegistrationService = {
    createOrgnization,
    createOrgnizationUserSecurityRegistration
};
export default OrgnizationRegistrationService;

