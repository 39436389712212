import classnames from "classnames";
import uniqueId from "lodash/uniqueId";

/**
 * A checkbox or radio field. In most cases, you shouldn't use this
 * component directly. Instead, use `InputChoiceGroup` which renders
 * markup for the entire fieldset.
 *
 * [USWDS Reference for Radio button↗](https://designsystem.digital.gov/components/radio-buttons/)
 * [USWDS Reference for Checkbox↗](https://designsystem.digital.gov/components/checkbox/)
 */
function TableCheckBox({ type = "checkbox", ...props }: TableCheckBoxProps) {
    // Generate a unique ID for associating the field with the label.
    // This is important for a11y.
    const inputId = uniqueId("InputRadio");
    const id = props.id || inputId;
    const isTile = props.tile === undefined ? false : props.tile; //Make tile default

    const inputClasses = classnames([`usa-${type}__input`], {
        "usa-radio__input--tile": isTile && type === "radio",
        "usa-checkbox__input--tile": isTile && type === "checkbox"
    });

    const inputLabelClasses = classnames({
        "text-bold": props.bold,
    });

    return (
        <div className={props.formGroupClassName}>
            <input
                className={inputClasses}
                id={id}
                type={type}
                name={props.name}
                value={props.value}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
                onChange={props.onChange}
                data-item-id={props.itemId}
                data-testid={props.dataTestId}
            />
            <label className={`usa-${type}__label`} htmlFor={id}>
                <span className={inputLabelClasses}>{props.label}</span>
                {props.hint && (
                    <span className="usa-checkbox__label-description">{props.hint}</span>
                )}
            </label>
        </div>
    );
}

interface TableCheckBoxProps {

    /**
     * Sets the input's `checked` state. Use this for an uncontrolled component.
     */
    defaultChecked?: React.InputHTMLAttributes<HTMLInputElement>["defaultChecked"];
    /**
     * Sets the input's `checked` state. Use this in combination with `onChange`
     * for a controlled component.
     */
    checked?: React.InputHTMLAttributes<HTMLInputElement>["checked"];
  
    /**
     * Unique identifier for input
     */
    id?: React.InputHTMLAttributes<HTMLInputElement>["id"];
    /**
     * Localized hint text
     */
    hint?: React.ReactNode;
    /**
     * Localized field label
     */
    label?: React.ReactNode;
  
    /**
     * HTML input `name` attribute
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * HTML input `onChange` attribute
     */
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    /**
     * Enable the tile style variant
     */
    tile?: boolean;
    /**
     * HTML input `type` attribute
     */
    type?: "checkbox" | "radio";
    /**
     * Sets the input's `value`
     */
    value: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * (NOT HTML ID) Sets the value of a corresponding reference ID (like personId if the checkboxes are related to a person)
     * to access from HTMLInputElement, use target.dataset.itemId
     */
    itemId?: string;
    dataTestId?: string;
    bold?: boolean;
    formGroupClassName?: string;
}

export default TableCheckBox;
