import { guidelineErroMsg, inputErrorMsg } from "../../../../utils/errorMessageUtil";
import { FormFieldValidationResult } from "../interfaces/formValidationInterfaces";

/**
 * Use to validate if an email address is valid
 * @param email The email to validate
 * @param isOptional used to handle a field that is not required to save and continue
 * @param isApplicant to determine posessive form of error messages
 * @returns FormFieldValidationResult
 */
export const validateEmail = (
    email: string,
    isOptional = false,
    isApplicant = true
): FormFieldValidationResult => {
    const possessive = isApplicant ? "your" : "their";
    const CapPossessive = isApplicant ? "Your" : "Their";
    const ret = { errorMessage: "", isValid: true } as FormFieldValidationResult;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;
    if (!email || email.length < 1) {
        if (!isOptional) {
            ret.isValid = false;
            ret.errorMessage = inputErrorMsg(`${possessive} email address`);
        }
    } else if (!regex.test(email)) {
        ret.isValid = false;
        ret.errorMessage = guidelineErroMsg(
            `${CapPossessive} email address`,
            "be in a valid format (ie: user@example.com)"
        );
    }
    return ret;
};

/**
 * To ensure that a phone number is valid
 * @param phone The phone number to be validated
 * @param isOptional used to handle a field that is not required to save and continue
 * @param isApplicant to determine posessive form of error messages
 * @returns FormFieldValidationResult
 */
export const validatePhone = (
    phone: string,
    isOptional = false,
    isApplicant = true,
    alternatePossessive?: string
): FormFieldValidationResult => {
    const possessive = isApplicant ? "your" : "their";
    let CapPossessive = isApplicant ? "Your" : "Their";
    CapPossessive = alternatePossessive ?? CapPossessive;
    const ret = { errorMessage: "", isValid: true } as FormFieldValidationResult;
    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!phone || phone.length < 1) {
        if (!isOptional) {
            ret.isValid = false;
            ret.errorMessage = inputErrorMsg(`${possessive} phone number`);
        }
    } else if (!regex.test(phone)) {
        ret.isValid = false;
        ret.errorMessage = guidelineErroMsg(
            `${CapPossessive} phone number`,
            "be a valid 10-digit number"
        );
    }
    return ret;
};
