import { Box, Typography } from "@mui/material";
import QuestionPage from "../../templates/QuestionPage";
import React, { useState, useEffect } from "react";
import "./exploreapi.css";
import { useHistory } from "react-router-dom";
import NavigationConstants from "../BenefitsApplication/shared/enums/navigation";
import AppRegistrationService from "../application-registration/AppRegistrationService";


const ExploreApis = (props: any) => {
    const [apicProductsList, setApicProductsList] = useState([]);
    const [apiErrorMsg, setApiErrorMsg] = useState("");


    const history = useHistory();

    const orgId = sessionStorage.getItem('orgId');
    if (!orgId) {
        const path = NavigationConstants.AppSignInPage;
        history.push(path);
    }

    useEffect(() => {
        getApicProducts();
    }, []);

    const getApicProducts = () => {
        AppRegistrationService.getApicProducts()
        .then(response => {
            console.log(response.data.results);
            setApicProductsList(response.data.results);
          })
        .catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                setApiErrorMsg(error.response.data.message);
            }
        });
    }

    const signupRoute = (apicProduct: any) => {
    }

    const apiLandigRoute = () => {
        if(props.navigateTo && props.navigateTo ===  NavigationConstants.AppFeedbackPage) {
            const path = NavigationConstants.AppFeedbackPage;
            history.push(path);
        }else {
            const path = NavigationConstants.ApiLandingPage;
            history.push(path);
        }        
    }
    return (
        <React.Fragment>
            <QuestionPage
                title="Explore API's"
                hideFeatureName={true}
                hideSubmitBtn={true}
                formClass=""
                showLogoutBtn= {true}
            >
                <br/>
                {
                    apicProductsList.map((apicProduct:any) => ( apicProduct && <>
                        <br />
                        <Box sx={{ width: '100%', maxWidth: 800 }}>
                            <Typography sx={{ color: 'primary.main' }} variant="h5" gutterBottom component="div">
                                {apicProduct.name}
                            </Typography>
                            <div id= {apicProduct.id}>
                                <div id="column1">
                                    <Typography variant="h6" gutterBottom component="div">
                                        {apicProduct.summary}
                                    </Typography>
                                </div>
                                <div id="column2">
                                    {/* <Button
                                        type="submit"
                                        name="signup"
                                        onClick={() => signupRoute(apicProduct)}
                                    >Subscribe
                                    </Button> */}
                                </div>
                            </div>
                        </Box>
                        </>
                    ))
                }
            </QuestionPage>
        </React.Fragment>
    );
};

export default ExploreApis;