import "../Modal/CustomModal";
import React, { useState, useEffect, CSSProperties } from "react";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import { Organizations } from "../model/Organizations";
import BeatLoader from "react-spinners/BeatLoader";
import OrganizationService from "./service/adminOrganizationService";


interface OrganizationModalProps {

    showPopup: boolean,
    okButtonText?: string
    closeButtonText?: string
    orgId?: String
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;
}

function OrganizationModal(props: OrganizationModalProps) {
    const [adminOrganizationData, setAdminOrganizationData] = useState<any>();

    const [loading, setLoading] = useState(false);
    const [next, setNext] = useState(false);

    useEffect(() => {
        if (props?.orgId) {
            getOrganizationsById(props?.orgId);
        }
    }, []);

    const getOrganizationsById = (orgId: any): void => {
        setLoading(true);
        console.log("org parsing data", orgId)
        OrganizationService.get(orgId)
            .then(response => {
                console.log(response.data);
                setAdminOrganizationData(response.data);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);

            });
    };
    const getPublicRelease =(value: string)  => {
        switch(value) {
            case 'P':
            return "Public Use"
            case 'T':
            return "Test Use"
            case 'E':
            return  "Educational Use"
        }
    }
    const override: CSSProperties = {
        marginTop: "20%",
        marginLeft: "50%",
        marginBottom: "20%",
    };

    return (
        <>
            {(props && props.showPopup && (

                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>Organization Details</h2>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {props.closeButtonText || "Close"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div>
                                    {next ?
                                        <Button onClick={() => setNext(false)} className="usa-button CloseButton" variation="outline" >
                                            Previous
                                        </Button> :
                                        <Button onClick={() => setNext(true)} className="usa-button CloseButton" variation="outline" >
                                            Next
                                        </Button>
                                    }
                                </div>
                                {!loading &&
                                    (
                                        !next ?
                                            <div className={"ModalText"}>
                                                <InputText
                                                    context="organizationModal"
                                                    name="organizationId"
                                                    label="Organization Id"
                                                    value={adminOrganizationData?.orgId}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="organizationName"
                                                    label="Organization Name"
                                                    value={adminOrganizationData?.orgName}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="organizationTaxId"
                                                    label="Organization Tax Id"
                                                    value={adminOrganizationData?.orgTaxId}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="physicalAddress"
                                                    label="Physical Address"
                                                    value={adminOrganizationData?.orgPhyAdd1}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="physicalAddressCity"
                                                    label="Physical Address City"
                                                    value={adminOrganizationData?.orgPhyAddCity}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="physicalAddressState"
                                                    label="Physical Address State"
                                                    value={adminOrganizationData?.orgPhyAddSt}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="physicalAddressCountry"
                                                    label="Physical Address Country"
                                                    value={adminOrganizationData?.orgPhyCountry}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="physicalAddressZip"
                                                    label="Physical Address Zip"
                                                    value={adminOrganizationData?.orgPhyAddZip}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="mailAddress"
                                                    label="Mail Address"
                                                    value={adminOrganizationData?.orgMailAdd1}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="mailAddressCity"
                                                    label="Mail Address City"
                                                    value={adminOrganizationData?.orgMailAddCity}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="mailAddressState"
                                                    label="Mail Address State"
                                                    value={adminOrganizationData?.orgMailAddSt}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="mailAddressCountry"
                                                    label="Mail Address Country"
                                                    value={adminOrganizationData?.orgMailCountry}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="mailAddressZip"
                                                    label="Mail Address Zip"
                                                    value={adminOrganizationData?.orgMailAddZip}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="status"
                                                    label="Status"
                                                    value={adminOrganizationData?.orgStatus}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="appStatusModal"
                                                    name="comments"
                                                    label="Comments"
                                                    value={adminOrganizationData?.comment}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />

                                            </div>
                                            :
                                            <div className={"ModalText"}>
                                                <InputText
                                                    context="organizationModal"
                                                    name="legacyEntCd"
                                                    label="Registered Legal Entity"
                                                    value={adminOrganizationData?.legacyEntCd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="stateFileCd"
                                                    label="Business Registration State"
                                                    value={adminOrganizationData?.stateFileCd}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="ownedByOthrCd"
                                                    label="Entity Owned by other corporation"
                                                    value={adminOrganizationData?.ownedByOthrCd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="PublicRelCd"
                                                    label="Public Release or Purpose"
                                                    value={getPublicRelease(adminOrganizationData?.publicRelCd)}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="privPolicyCd"
                                                    label="Privacy Policy shared with application users"
                                                    value={adminOrganizationData?.privPolicyCd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="privPolicyUrl"
                                                    label="Privacy Policy Url"
                                                    value={adminOrganizationData?.privPolicyUrl}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="privPolUpdateInd"
                                                    label="update to consumers of material change to your privacy policy"
                                                    value={adminOrganizationData?.privPolUpdateInd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="infoSecPolicyInd"
                                                    label="Information Security"
                                                    value={adminOrganizationData?.infoSecPolicyInd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="infoSecPolicyInd"
                                                    label="Information Security"
                                                    value={adminOrganizationData?.infoSecPolicyInd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="ind3PrtInd"
                                                    label="3rd Party audit for compliance"
                                                    value={adminOrganizationData?.ind3PrtInd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                                <InputText
                                                    context="organizationModal"
                                                    name="outSideUsDevInd"
                                                    label="Data be stored in another country if not US"
                                                    value={adminOrganizationData?.outSideUsDevInd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />

                                                <InputText
                                                    context="organizationModal"
                                                    name="namedOffcrFelonInd"
                                                    label="In past 12 months any data breaches required disclosure to a government agency"
                                                    value={adminOrganizationData?.namedOffcrFelonInd === 'Y' ? 'Yes' : 'No'}
                                                    readOnly={true}
                                                    formGroupInputClasses="usa-input-group-readonly"
                                                />
                                            </div>

                                    )
                                }
                                <BeatLoader loading={loading} cssOverride={override} />
                            </div>
                        </div>
                    </div>
                </>
            )) ||
                null}
        </>
    );
}

export default OrganizationModal;