import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface UserRegistrationRepresentativeState {
    firstName: string;
    lastName: string;
    userName: string;
    prsnType: string;
    email: string;
    phoneNumber: string;
    password:string;
    confirmPassword:string;
}

export const initializeUserRegistrationRepState = () => {
    return {
        firstName: "",
        lastName: "",
        userName: "",
        prsnType: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
    } as UserRegistrationRepresentativeState;
};


export const getLastItemOrNew = (registrationRepArray: UserRegistrationRepresentativeState[]) => {
    const registrationRepDetailsIndex = registrationRepArray.length - 1;
    const lastItem =
    registrationRepDetailsIndex >= 0
        ? registrationRepArray[registrationRepDetailsIndex]
        : initializeUserRegistrationRepState();
    return { index: registrationRepDetailsIndex, item: lastItem };
};

const initialState = [] as UserRegistrationRepresentativeState[];

const userRegistrationRepDetailsSlice = createSlice({
    name: "userRegistrationRepDetails",
    initialState,
    reducers: {
        addRegistrationRepDetails(state, action: PayloadAction<UserRegistrationRepresentativeState>) {
            state.push(action.payload);
        },
        updateRegistrationRepDetailsByIndex(
            state,
            action: PayloadAction<{ index: number; arState: UserRegistrationRepresentativeState }>
        ) {
            
            //add new if conditions are met
            if (state.length === 0 || (state.length < 4 && action.payload.index < 0)) {
                state.push(action.payload.arState);
            } else {
                //grab the item by index or most recent;
                const validIndex =
                    action.payload.index >= state.length ? state.length - 1 : action.payload.index;
                const stateObj = state[validIndex];
                for (const [key, value] of Object.entries(action.payload.arState)) {
                    stateObj[key as keyof UserRegistrationRepresentativeState] = value;
                }
            }
        },
        removeRegistrationRepDetails(state, action: PayloadAction<number>) {
            const index = action.payload;
            //do not remove if index is out of index range
            if (index < 0 || index >= state.length) return;
            state.splice(index, 1);
        },
        removeAll(state) {
            state.splice(0, state.length);
        },
    },
});

export const {
    addRegistrationRepDetails,
    updateRegistrationRepDetailsByIndex,
    removeRegistrationRepDetails,
    removeAll,
} = userRegistrationRepDetailsSlice.actions;

export default userRegistrationRepDetailsSlice.reducer;




