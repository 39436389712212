import React, { useState, useEffect, CSSProperties } from "react";
import QuestionPage from "../../templates/QuestionPage";
import CustomTable, {MinTableItem, MinTableHeader} from "../../components/CustomTable";
import AdminAppStatusReviewPage from "./admin-app-status-review-page";
import AdminAppStatusPage from "./admin-app-status-page";
import AppRegistrationService from "../application-registration/AppRegistrationService";
import { Applications } from "../model/Applications";
import InputText from "../../components/InputText";
import BeatLoader from "react-spinners/BeatLoader";
import NavigationConstants from "../BenefitsApplication/shared/enums/navigation";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import BackButton from "../../components/BackButton";
import { AuthorizationComponent } from "../admin-landing-page/authorization-component";


const AdminAppStatusListPage = () => {
    const [adminAppStatusListData, setAdminAppStatusListData] = useState<AdminAppStatusTable[]>([]);
    const [adminAppStatusTableData, setAdminAppStatusTableData] = useState<AdminAppStatusTable[]>([]);
    const [openAdminAppStatusReviewPopup, setOpenAdminAppStatusReviewPopup] = useState(false);
    const [openAdminAppStatusPopup, setOpenAdminAppStatusPopup] = useState(false);
    const [adminAppStatusData, setAdminAppStatusData] = useState<any>();
    const [searchField, setSearchField] = useState<any>('');
    const [loading, setLoading] = useState(true);
   
    const history = useHistory();

    const goToAdminLoginPage = () => {
        const path = NavigationConstants.AdminSignInLandingPage;
         history.push(path);
     }

    const override: CSSProperties = {
       marginTop: "20%",
       marginLeft: "50%"
    };

    const statusDropdownChoices = [
        {
            label: "Approved",
            value: "AP",
        },
        {
            label: "Rejected",
            value: "RJ",
        },
        {
            label: "In Review",
            value: "IR",
        },
        {
            label: "Requires Additional Information",
            value: "RA",
        }
    ]

    useEffect(() => {
        getAdminAppStatusList();
    }, []);

    const getAdminAppStatusList = () => {
        AppRegistrationService.getAllApplications()
          .then(response => {
            console.log(response.data);
            let preparedData = prepareAdminAppStatusTableData(response.data);
            preparedData = preparedData.sort((a, b) => {
                const isAsc = true;
                 return compareData(a.appName, b.appName, isAsc)
             });
            setAdminAppStatusListData(preparedData);
            setAdminAppStatusTableData(preparedData);
            setLoading(false);
          })
          .catch(e => {
                    console.log(e);
                    setLoading(false);
            });
    };

    const prepareAdminAppStatusTableData = (data: Applications[]): AdminAppStatusTable[] => {
        if (data) {
            return data.map(getAdminAppStatusTableData);
        } else {
             return [];
        }
    }

    function getAdminAppStatusTableData(data: Applications) {
        let adminAppStatusTableData :AdminAppStatusTable =  { 
            appId: data.appId, 
            appName: data.appName, 
            organizationName: data.orgId,
            updateDt: '',
            appStatusCd: data.appStatusCd,
            action: 'Review'
           
        }
        if (data.updateDt) {
            adminAppStatusTableData['updateDt'] = data.updateDt;
        } else if (data.createDt) {
            adminAppStatusTableData['updateDt'] = data.createDt;
        }
        if (adminAppStatusTableData.appStatusCd) {
            statusDropdownChoices.forEach(statusDropDown => {
                if (statusDropDown.value == adminAppStatusTableData.appStatusCd) {
                    adminAppStatusTableData.appStatusCd = statusDropDown.label;
                }
                
            });
        }
        return adminAppStatusTableData;
    }

    const adminAppStatusHeaders:MinTableHeader[] = [
            {
                id: "appId",
                name: "Application ID",
                type: "link"
            },
            {
                id: "appName",
                name: "Application Name",
                type: "text",
                sort: true,
            },
            {
                id: "organizationName",
                name: "Organization",
                type: "text",
            },
            {
                id: "updateDt",
                name: "Last Updated Date",
                type: "text",
                sort: true,
            },
            {
                id: "appStatusCd",
                name: "Status",
                type: "text"
            },
            {
                id: "action",
                name: "Actions",
                type: "button"
            }
        ]

    const handleButtonFunctionality = (res: any) => {
        if (res && res.data) {
            if (res.header) {
                if (res.header.type == "button") {
                    setOpenAdminAppStatusReviewPopup(true);
                } else if (res.header.type == "link") {
                    setOpenAdminAppStatusPopup(true);
                }
            }
            getApplicationById(res.data.appId)
        }   
    };

    const getApplicationById = (appId: any): void => {
        console.log("App parsing data", appId)
        AppRegistrationService.getApplication(appId)
          .then(response => {
            console.log(response.data);
            setAdminAppStatusData(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    };

    const handleAppStatusCloseBtnConfirmModal = () => {
        setAdminAppStatusData(null);
        setOpenAdminAppStatusPopup(false);
    };

    const handleAppStatusConfirmBtnModal = () => {
        setAdminAppStatusData(null);
        setOpenAdminAppStatusPopup(false);
    }

    const handleReviewCloseBtnConfirmModal = () => {
        setAdminAppStatusData(null);
        setOpenAdminAppStatusReviewPopup(false);
    };

    const handleReviewConfirmBtnModal = (data: any) => {
        console.log ("data", data)
        setOpenAdminAppStatusReviewPopup(false);
        getAdminAppStatusList();
        setAdminAppStatusData(null);
    };


    const searchAppStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("event", event.target.value)
        setSearchField(event.target.value);
        let filteredAppStatusList = adminAppStatusListData.filter((item) => item.appName.toLowerCase().includes(event.target.value.toLowerCase()));
        filteredAppStatusList = filteredAppStatusList.sort((a, b) => {
            const isAsc = true;
             return compareData(a.appName, b.appName, isAsc)
         });
        setAdminAppStatusTableData(Object.create(filteredAppStatusList));
    };

    const compareData = (a: number | string, b: number | string, isAsc: boolean) => { 
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    

    const handleSortingFunctionality = (res: any) => {
       console.log("sorting res", res);
       let filteredAppStatusList = adminAppStatusListData.filter((item) => item.appName.toLowerCase().includes(searchField.toLowerCase()));
        filteredAppStatusList = filteredAppStatusList.sort((a, b) => {
           const isAsc = res.sortOrdering === 'ASC';
          if (res.sortColumnId == 'updateDt') {
            return compareData(a.updateDt, b.updateDt, isAsc);
          } else {
            return compareData(a.appName, b.appName, isAsc)
           }
           
        });
        setAdminAppStatusTableData(Object.create(filteredAppStatusList));
    }


    const AdminAppStatusListPageComponent = () =>
        <React.Fragment>
        <AuthenticatedTemplate>
            <QuestionPage
                title= "Admin App Status"
                hideFeatureName = {true}
                hideSubmitBtn = {true}
                callAdminSigout = {true}
                showLogoutBtn = {true}
                formClass =""
            >
              { !loading &&  <InputText
                    name="search_medicaid"
                    onChange={searchAppStatusChange}
                    type="text"
                    formGroupClassName=" float_right margin-top-0 margin-bottom-20 "
                    inputPrefix="Search"
                /> }
               { !loading && <CustomTable
                    headers={adminAppStatusHeaders}
                    items={adminAppStatusTableData}
                    tableButton={handleButtonFunctionality}
                    sortingButton={handleSortingFunctionality}
                    defaultSortColId = "appName"
                /> }
                <AdminAppStatusReviewPage
                    data = {adminAppStatusData}
                    showPopup={openAdminAppStatusReviewPopup}
                    agreeBtnOnClick={handleReviewConfirmBtnModal}
                    closeBtnOnClick = {handleReviewCloseBtnConfirmModal}
                />
                <AdminAppStatusPage
                    data = {adminAppStatusData}
                    showPopup={openAdminAppStatusPopup}
                    agreeBtnOnClick={handleAppStatusConfirmBtnModal}
                    closeBtnOnClick = {handleAppStatusCloseBtnConfirmModal}
                />
               <BeatLoader loading = {loading} cssOverride={override}/>

            </QuestionPage>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to access the Admin Content</h5> 
                <BackButton buttonClassName="subheading-backbutton" onClick={goToAdminLoginPage}/>
           </UnauthenticatedTemplate>
       </React.Fragment>
       
    const CmsiAuthorizedComponent = AuthorizationComponent(AdminAppStatusListPageComponent);

    return (
        <CmsiAuthorizedComponent>
        </CmsiAuthorizedComponent>
    );
}

interface AdminAppStatusTable extends MinTableItem
{
    appId : string;
    appName: string;
    organizationName: string;
    appStatusCd: string;
    updateDt: string;
    action: string;
}

export default AdminAppStatusListPage;