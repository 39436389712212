import classnames from "classnames";

/**
 * An alert keeps users informed of important and sometimes time-sensitive changes.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/alert/)
 */
function Alert({ role = "region", type = "error", ...props }: AlertProps) {
    const classes = classnames("usa-alert", [`usa-alert--${type}`], {
        "usa-alert--slim": props.slim,
        "usa-alert--no-icon": props.noIcon,
    });

    return (
        <div className={classes}>
            <div className="usa-alert__body" role={role}>
                {props.heading && <h4 className="usa-alert__heading">{props.heading}</h4>}
                <div className="usa-alert__text">{props.children}</div>
            </div>
        </div>
    );
}

interface AlertProps {
    children: React.ReactNode;
    heading?: React.ReactNode;
    /** Hide the icon */
    noIcon?: boolean;
    /** ARIA `role` */
    role?: "alert" | "alertdialog" | "region";
    /** Reduce the spacing */
    slim?: boolean;
    /** Alert style */
    type?: "error" | "info" | "success" | "warning";
}

export default Alert;
