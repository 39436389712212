import './admin-landing-page.css'
import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../admin-signin-landing-page/authConfig'
import { callMsGraph } from '../admin-signin-landing-page/graph'
import BackButton from '../../components/BackButton'
import NavigationConstants from '../BenefitsApplication/shared/enums/navigation'
import { useHistory } from 'react-router-dom'
interface GraphData {
  value: Array<{ [x: string]: string }>
  // Add other properties as needed
}

export const AuthorizationComponent = (Component: any) => {
  const { instance, accounts } = useMsal()
  const [graphData, setGraphData] = useState<GraphData | null>(null)

  const [isLoaded, setIsLoaded] = useState(false)
  const history = useHistory()
  const authorizedGroups = ['DHHS_Azure_CMSI_Admins']

  const goToAdminLoginPage = () => {
    const path = NavigationConstants.AdminSignInLandingPage
    history.push(path)
  }

  function requestGroupsForLoggedInUser() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((data) => setGraphData(data))
      })
  }

  return (props: any) => {
    useEffect(() => {
      if (!isLoaded) {
        requestGroupsForLoggedInUser()
        setIsLoaded(true)
      }
    }, [isLoaded])

    // graphData.value.find(obj => obj['mailNickname'] == 'DHHS_Azure_CMSI_Prd_Admins')
    if (graphData && graphData != null) {
      const isEligibleGroup =
        graphData.value.filter((element: { [x: string]: string }) =>
          authorizedGroups.includes(element['mailNickname']),
        ).length > 0

      if (isEligibleGroup) {
        return <Component {...props} />
      } else {
        return (
          <div>
            <h5 className="card-title">
              You are Unauthorized to view this page
            </h5>
            <BackButton
              buttonClassName="subheading-backbutton"
              onClick={goToAdminLoginPage}
            />
          </div>
        )
      }
    } else {
      return (
        <div>
          <h5 className="card-title">You are Unauthorized to view this page</h5>
          <BackButton
            buttonClassName="subheading-backbutton"
            onClick={goToAdminLoginPage}
          />
        </div>
      )
    }
  }
}
