import classnames from "classnames";

/**
 * Renders a `button` element
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/button/)
 */
function Button({ type = "button", ...props }: ButtonProps) {
    const classes = classnames(
        props.className,
        "usa-button",
        props.variation ? `usa-button--${props.variation}` : "",
        {
            "usa-button--inverse": props.inversed,
            // This is weird, but we need this so that the inversed styling
            // kicks in when the variation is unstyled
            "usa-button--outline": props.inversed && props.variation === "unstyled",
        }
    );

    return (
        <button className={classes} name={props.name} onClick={props.onClick} type={type} disabled={props.disabled}>
            {props.children}
        </button>
    );
}

interface ButtonProps {
    children: React.ReactNode;
    /**
     * Additional classes to apply to the HTML element. Useful for adding
     * utility classes to control spacing.
     */
    className?: string;
    /** Apply the "inverse" style modifier */
    inversed?: boolean;
    name?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
    variation?: "" | "accent-cool" | "outline" | "secondary" | "unstyled";
    disabled?: boolean;
}

export default Button;
