import React, { useState, CSSProperties } from "react";
import StateDropdown from "../../components/StateDropdown";
import QuestionPage from "../../templates/QuestionPage";
import FieldsetAddress from "../../components/FieldsetAddress";
import { AddressState, initializeAddressState, updateAddressStateOnChange, validateAddressByAddress } from "../BenefitsApplication/shared/services/addressService";
import FormLabel from "../../components/FormLabel";
import InputText from "../../components/InputText";
import { genFedIdErrorMsg, genOrgNameErrorMsg } from "../../utils/errorMessageUtil";
import InputChoiceGroup from "../../components/InputChoiceGroup";
import { genFirstNameErrorMsg,
    genLastNameErrorMsg,
    genUserNameErrorMsg,
    genPhoneNumErrorMsg,
    genEmailErrorMsg,
    getPasswordErrorMsg,
    getConfPasswordErrorMsg,
    getPasswordMisMatchErrorMsg,
} from "../../utils/errorMessageUtil";
import Dropdown from "../../components/Dropdown";
import { useHistory } from "react-router";
import { inputErrorMsg } from "../../../src/utils/errorMessageUtil";
import PhoneNumber from "../BenefitsApplication/shared/components/phoneNumber";
import {
    validatePhone,
} from "../BenefitsApplication/shared/services/formatValidationService";
import { OrganizationRegistrationRepresentativeState, initializeOrgRegistrationStep1RepState } from "./store/step1OrganizationRegistrationRepresentativeSlice"
import { UserRegistrationRepresentativeState , initializeUserRegistrationRepState } from "./store/step2UserRegistrationRepresentativeSlice";
import { PrivacySecurityRepresentativeState, initializePrivacySecurityRegistrationRepState } from "./store/step3PrivacySecurityRepresentativeSlice";
import OrgnizationRegistrationService from "./service/orgnizationRegistrationService";
import { OrgnizationRegistrationModel, initializeOrgnizationRegistrationModel } from "./service/orgnizationRegistrationTransformService";
import { pageScrollToTop } from "../../../src/utils/pageScrollToTop"
import BeatLoader from "react-spinners/BeatLoader";


const OrganizationUserSecurityHomePage = () => {

    const history = useHistory();

    const [registrationStep, setRegistrationStep] = useState(1);
    const [localStateStep1, setOrgRegistrationStep1RepState] = useState<OrganizationRegistrationRepresentativeState>(initializeOrgRegistrationStep1RepState());
    const [localStateStep2, setUserRegistrationStep2RepState] = useState<UserRegistrationRepresentativeState>(initializeUserRegistrationRepState());
    const [localStateStep3, setPrivacySecurityStep3RepState] = useState<PrivacySecurityRepresentativeState>(initializePrivacySecurityRegistrationRepState());
    const [loading, setLoading] = useState(false);

    const override: CSSProperties = {
        marginTop: "20%",
        marginLeft: "50%"
    };

    //step-1
    const [streetAddressErrorMsg, setStreetAddressError] = useState("");
    const [cityErrorMsg, setCityError] = useState("");
    const [stateCodeErrorMsg, setStateCodeError] = useState("");
    const [postalCodeErrorMsg, setPostalCodeError] = useState("");
    const [mailStreetAddressErrorMsg, setMailStreetAddressError] = useState("");
    const [mailCityErrorMsg, setMailCityError] = useState("");
    const [mailStateCodeErrorMsg, setMailStateCodeError] = useState("");
    const [mailPostalCodeErrorMsg, setMailPostalCodeError] = useState("");
    const [orgNameErrorMsg, setOrgNameErrorMsg] = useState("");
    const [fedTaxIdErrorMsg, setFedTaxIdErrorMsg] = useState("");
    const [isHideMailingAddress, setHideMailingAddress] = useState(false);
    const [mailingAddressInit, setMailingAddressInit] = useState<AddressState>(
        initializeAddressState()
    );

    const step1ValidationFormWithErrorMsg = () => {
        let isFormValid = true;

        if (!localStateStep1.orgName) {
            setOrgNameErrorMsg(genOrgNameErrorMsg("your"));
            isFormValid = false;
        }


        if (!localStateStep1.fedTaxId) {
            setFedTaxIdErrorMsg(genFedIdErrorMsg("your"));
            isFormValid = false;
        }

        const phyAddressValidationResult = validateAddressByAddress(
            localStateStep1.physicalAdress,
            true,
            true
        );

        const mailingAddressValidationResult = validateAddressByAddress(
            localStateStep1.mailingAdress,
            true,
            true
        );

        if (!phyAddressValidationResult.isValid) {
            setStreetAddressError(phyAddressValidationResult.streetAddressMsg);
            setCityError(phyAddressValidationResult.cityMsg);
            setStateCodeError(phyAddressValidationResult.stateMsg);
            setPostalCodeError(phyAddressValidationResult.postalCodeMsg);
            isFormValid = false;
        }

        if (!isHideMailingAddress && !mailingAddressValidationResult.isValid) {
            setMailStreetAddressError(mailingAddressValidationResult.streetAddressMsg);
            setMailCityError(mailingAddressValidationResult.cityMsg);
            setMailStateCodeError(mailingAddressValidationResult.stateMsg);
            setMailPostalCodeError(mailingAddressValidationResult.postalCodeMsg);
            isFormValid = false;
        }

        return isFormValid;
    };

    const resetStep1ErrorMsgs = () => {
        setOrgNameErrorMsg("");
        setFedTaxIdErrorMsg("");
        setStreetAddressError("");
        setCityError("");
        setStateCodeError("");
        setPostalCodeError("");
        setMailStreetAddressError("");
        setMailCityError("");
        setMailStateCodeError("");
        setMailPostalCodeError("");
    };

    const errorMessagesStep1 = [
        orgNameErrorMsg,
        fedTaxIdErrorMsg,
        streetAddressErrorMsg,
        cityErrorMsg,
        stateCodeErrorMsg,
        postalCodeErrorMsg,
        mailStreetAddressErrorMsg,
        mailCityErrorMsg,
        mailStateCodeErrorMsg,
        mailPostalCodeErrorMsg,
    ];

    const handleAdditionalChangeStep1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrgRegistrationStep1RepState({
            ...localStateStep1,
            [event.target.name]: event.target.value,
        });
    };

    const phyAdressComponentName = "orgRegistrationPhyRepAddress";
    const mailAddressComponentName = "orgRegistrationMailRepAddress";

    const address1Props = {
        value: localStateStep1.physicalAdress.streetAddress,
        errorMsg: streetAddressErrorMsg,
        label: "Address Line 1",
        mandatoryLabel: true
    };
    const address2Props = {
        value: localStateStep1.physicalAdress.streetAddress2,
    };
    const cityProps = {
        value: localStateStep1.physicalAdress.city,
        errorMsg: cityErrorMsg,
        mandatoryLabel: true
    };
    const countyProps = {
        value: localStateStep1.physicalAdress.countyCode,
    };
    const stateProps = {
        value: localStateStep1.physicalAdress.stateCode,
        errorMsg: stateCodeErrorMsg,
    };
    const postalCodeProps = {
        postalCode5Value: localStateStep1.physicalAdress.postalCode,
        postalCode4Value: localStateStep1.physicalAdress.postalCodeExtension,
        errorMsg: postalCodeErrorMsg,
        mandatoryLabel: true
    };

    const mailAddress1Props = {
        value: localStateStep1.mailingAdress.streetAddress,
        errorMsg: streetAddressErrorMsg,
        label: "Address Line 1",
        mandatoryLabel: true
    };
    const  mailAddress2Props = {
        value: localStateStep1.mailingAdress.streetAddress2,
    };
    const  mailcityProps = {
        value: localStateStep1.mailingAdress.city,
        errorMsg: cityErrorMsg,
        mandatoryLabel: true
    };
    const mailcountyProps = {
        value: localStateStep1.mailingAdress.countyCode,
    };
    const  mailstateProps = {
        value: localStateStep1.mailingAdress.stateCode,
        errorMsg: stateCodeErrorMsg,
    };
    const mailpostalCodeProps = {
        postalCode5Value: localStateStep1.mailingAdress.postalCode,
        postalCode4Value: localStateStep1.mailingAdress.postalCodeExtension,
        errorMsg: postalCodeErrorMsg,
        mandatoryLabel: true
    };
    const handleAddressFormChangeStep1 = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        //Update Address fields
        const addressUpdateObj = updateAddressStateOnChange(
            event,
            localStateStep1.physicalAdress,
            phyAdressComponentName
        ); 
        addressUpdateObj &&
            setOrgRegistrationStep1RepState({
                ...localStateStep1,
                physicalAdress: addressUpdateObj 
            });
    };

    const handleMailAddressFormChangeStep1 = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => { 
        const mailAddressUpdateObj = updateAddressStateOnChange(
            event,
            localStateStep1.mailingAdress,
            mailAddressComponentName
        );
        mailAddressUpdateObj &&
            setOrgRegistrationStep1RepState({
                ...localStateStep1, 
                mailingAdress: mailAddressUpdateObj
            });
    };

    const handleToggleChangeStep1 = (event: React.ChangeEvent<HTMLInputElement>) => {

        setOrgRegistrationStep1RepState({
            ...localStateStep1,
            isAddrSameAsPhysicalAddr: event.target.checked,
        });   
        if (event.target.name == "sameAsPhysAddrCheckBx") {
            if(event.target.checked ) {
               setHideMailingAddress(true);
               localStateStep1.mailingAdress.streetAddress= localStateStep1.physicalAdress.streetAddress; 
               localStateStep1.mailingAdress.city = localStateStep1.physicalAdress.city;
               localStateStep1.mailingAdress.countyCode = localStateStep1.physicalAdress.countyCode;
               localStateStep1.mailingAdress.stateCode= localStateStep1.physicalAdress.stateCode;
               localStateStep1.mailingAdress.postalCode =  localStateStep1.physicalAdress.postalCode;
               localStateStep1.mailingAdress.postalCodeExtension =  localStateStep1.physicalAdress.postalCodeExtension;
               localStateStep1.isAddrSameAsPhysicalAddr = true;
            }else {    
                setHideMailingAddress(false);
                localStateStep1.mailingAdress = mailingAddressInit;
                localStateStep1.isAddrSameAsPhysicalAddr = false;
            }
           
        }    
    };

    //step-2

    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");
    const [userNameErrorMsg, setUserNameErrorMsg] = useState("");
    const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [passwordErrMsg, setPasswordErrorMsg] = useState("");
    const [confPasswordErrMsg, setConfPasswordErrorMsg] = useState("");

    const step2ValidationFormWithErrorMsg = () => {
        let isFormValid = true;

        if (!localStateStep2.firstName) {
            setFirstNameErrorMsg(genFirstNameErrorMsg("your"));
            isFormValid = false;
        }
        if (!localStateStep2.lastName) {
            setLastNameErrorMsg(genLastNameErrorMsg("your"));
            isFormValid = false;
        }

        if (!localStateStep2.userName) {
            setUserNameErrorMsg(genUserNameErrorMsg("your"));
            isFormValid = false;
        }

        if (!localStateStep2.phoneNumber) {
            setPhoneErrorMsg(genPhoneNumErrorMsg("your"));
            isFormValid = false;
        }

        if (!localStateStep2.email) {
            setEmailErrorMsg(genEmailErrorMsg("your"));
            isFormValid = false;
        }

        if (!localStateStep2.password) {
            setPasswordErrorMsg(getPasswordErrorMsg("your"));
            isFormValid = false;
        }

        if (localStateStep2.password && localStateStep2.password.length < 8) {
            setPasswordErrorMsg("Password should be at least 8 characters");
            isFormValid = false;
        }

        if (!localStateStep2.confirmPassword) {
            setConfPasswordErrorMsg(getConfPasswordErrorMsg("your"));
            isFormValid = false;
        }

        if (localStateStep2.password !== localStateStep2.confirmPassword) {
            setPasswordErrorMsg(getPasswordMisMatchErrorMsg);
            isFormValid = false;
        }

        const phoneCheck = validatePhone(localStateStep2.phoneNumber, true, false);
        if (!phoneCheck.isValid) {
            setPhoneErrorMsg(phoneCheck.errorMessage);
            isFormValid = false;
        }
        return isFormValid;
    };



    const resetStep2ErrorMsgs = () => {
        setFirstNameErrorMsg("");
        setLastNameErrorMsg("");
        setPhoneErrorMsg("");
        setConfPasswordErrorMsg("");
        setPasswordErrorMsg("");
        setUserNameErrorMsg("");
        setPhoneErrorMsg("");
        setEmailErrorMsg("");
    };

    const errorMessagesStep2 = [
        firstNameErrorMsg,
        lastNameErrorMsg,
        userNameErrorMsg,
        phoneErrorMsg,
        emailErrorMsg,
        passwordErrMsg,
        confPasswordErrMsg,
    ];

    const handleAdditionalChangeStep2 = (event: React.ChangeEvent<HTMLInputElement>) => {

        setUserRegistrationStep2RepState({
            ...localStateStep2,
            [event.target.name]: event.target.value,
        });
    };

    //step-3

    const [apiErrorMsg, setApiErrorMsg] = useState("");
    const [legalEntityErrorMsg, setLegalEntityErrorMsg] = useState("");
    const [stateFileCdErrorMsg, setStateFileCdErrorMsg] = useState("");
    const [ownedByOthrCdErrorMsg, setOwnedByOthrCdErrorMsgErrorMsg] = useState("");
    const [publicRelCdErrorMsg, setPublicRelCdErrorMsg] = useState("");
    const [privPolicyCdErrorMsg, setPrivPolicyCdErrorMsg] = useState("");
    const [privPolicyUrlErrorMsg, sePrivPolicyUrlErrorMsg] = useState("");
    const [privPolUpdateIndErrorMsg, setPrivPolUpdateIndErrorMsg] = useState("");
    const [infoSecPolicyIndErrorMsg, setInfoSecPolicyIndErrorMsg] = useState("");
    const [ind3PrtIndErrorMsg, setInd3PrtIndErrorMsg] = useState("");
    const [outSideUsDevIndErrorMsg, setOutSideUsDevIndErrorMsg] = useState("");
    const [outSideUsDataIndErrorMsg, setOutSideUsDataIndErrorMsg] = useState("");
    const [namedOffcrFelonIndErrorMsg, setNamedOffcrFelonIndErrorMsg] = useState("");
    const [dataBreachIndErrorMsg, setDataBreachIndErrorMsg] = useState("");

    const step3ValidationFormWithErrorMsg = () => {
        let isFormValid = true;

        if (!localStateStep3.legalEntity) {
            setLegalEntityErrorMsg(inputErrorMsg("Legal Entity"));
            isFormValid = false;
        }

        if (!localStateStep3.stateFileCd) {
            setStateFileCdErrorMsg(inputErrorMsg("State business registration"));
            isFormValid = false;
        }

        if (!localStateStep3.ownedByOthrCd) {
            setOwnedByOthrCdErrorMsgErrorMsg(inputErrorMsg("Owned by another corporation"));
            isFormValid = false;
        }

        if (!localStateStep3.publicRelCd) {
            setPublicRelCdErrorMsg(inputErrorMsg("Privacy Policy release"));
            isFormValid = false;
        }

        if (!localStateStep3.privPolicyCd) {
            setPrivPolicyCdErrorMsg(inputErrorMsg("Privacy Policy application user"));
            isFormValid = false;
        }

        if (!localStateStep3.privPolicyUrl) {
            sePrivPolicyUrlErrorMsg(inputErrorMsg("Privacy Policy URL"));
            isFormValid = false;
        }

        if (!localStateStep3.privPolUpdateInd) {
            setPrivPolUpdateIndErrorMsg(inputErrorMsg("Updates Privacy Policy"));
            isFormValid = false;
        }

        if(!localStateStep3.infoSecPolicyInd) {
            setInfoSecPolicyIndErrorMsg(inputErrorMsg("Information Security policy"));
            isFormValid = false;
        }
        if (!localStateStep3.ind3PrtInd) {
            setInd3PrtIndErrorMsg(inputErrorMsg("Independent 3rd party"));
            isFormValid = false;
        }

        if(!localStateStep3.outSideUsDevInd) {
            setOutSideUsDevIndErrorMsg(inputErrorMsg("Any contracted 3rd party"));
            isFormValid = false;
        }

        if(!localStateStep3.outSideUsDataInd) {
            setOutSideUsDataIndErrorMsg(inputErrorMsg("Passed through another country"));
            isFormValid = false;
        }

        if(!localStateStep3.namedOffcrFelonInd) {
            setNamedOffcrFelonIndErrorMsg(inputErrorMsg("Convicted of felonies in the U.S"));
            isFormValid = false;
        }

        if (!localStateStep3.dataBreachInd) {
            setDataBreachIndErrorMsg(inputErrorMsg("Organization had any data breaches"));
            isFormValid = false;
        }
        return isFormValid;
    };

    const resetStep3ErrorMsgs = () => {
        setLegalEntityErrorMsg("");
        setStateFileCdErrorMsg("");
        setOwnedByOthrCdErrorMsgErrorMsg("");
        setPublicRelCdErrorMsg("");
        setPrivPolicyCdErrorMsg("");
        sePrivPolicyUrlErrorMsg("");
        setPrivPolUpdateIndErrorMsg("");
        setInfoSecPolicyIndErrorMsg("");
        setInd3PrtIndErrorMsg("");
        setOutSideUsDevIndErrorMsg("");
        setOutSideUsDataIndErrorMsg("");
        setNamedOffcrFelonIndErrorMsg("");
        setDataBreachIndErrorMsg("");
        setApiErrorMsg("");
    };

    const errorMessagesStep3 = [
        apiErrorMsg,
        legalEntityErrorMsg,
        ownedByOthrCdErrorMsg,
        stateFileCdErrorMsg,
        publicRelCdErrorMsg,
        privPolicyCdErrorMsg,
        privPolicyUrlErrorMsg,
        privPolUpdateIndErrorMsg,
        infoSecPolicyIndErrorMsg,
        ind3PrtIndErrorMsg,
        outSideUsDevIndErrorMsg,
        outSideUsDataIndErrorMsg,
        namedOffcrFelonIndErrorMsg,
        dataBreachIndErrorMsg
    ];

    const publicRelCdDropdownValues = [
        {
            label: "Public Use",
            value: "P",
        },
        {
            label: "Test Use",
            value: "T",
        },
        {
            label: "Educational Use",
            value: "E",
        }
    ];

    const dropdownValues = [
        {
            label: "Yes",
            value: "Y",
        },
        {
            label: "No",
            value: "N",
        }
    ];

    const legalEntyProps = {
        emptyChoiceLabel: "Select",
        name: "legalEntity",
        label: 'Are you representing a registered legal entity?',
        value: ''
    }

    const anotherCorporationProps = {
        emptyChoiceLabel: "Select",
        name: "ownedByOthrCd",
        label: 'Is that entity owned by another corporation?',
        value: ''
    }

    const privacyPolicyProps = {
        emptyChoiceLabel: "Select",
        name: "privPolicyCd",
        label: 'Do you maintain a Privacy Policy that is shared with your application users?',
        value: ''
    }

    const publicRelCdProps = {
        emptyChoiceLabel: "Select",
        name: "publicRelCd",
        label: 'Do you intend to develop applications for public release or will you be developing for testing or educational purposes only?',
        value: ''
    }

    const privPolUpdateIndProps = {
        emptyChoiceLabel: "Select",
        name: "privPolUpdateInd",
        label: 'Do you provide updates to consumers of material changes to your privacy policy?',
        value: ''
    }

    const secPolicyProps = {
        emptyChoiceLabel: "Select",
        name: "infoSecPolicyInd",
        label: 'Has an information Security policy been formally adopted by the organization?',
        value: ''
    }

    const ind3PrtIndProps = {
        emptyChoiceLabel: "Select",
        name: "ind3PrtInd",
        label: 'Does an independent 3rd party audit the organization for compliance with the information Security policy?',
        value: ''
    }

    const outsideUsProps = {
        emptyChoiceLabel: "Select",
        name: "outSideUsDevInd",
        label: 'Is application development and/or support performed outside of the US by your organization or any contracted 3rd party?',
        value: ''
    }

    const dataOutsideUSProps = {
        emptyChoiceLabel: "Select",
        name: "outSideUsDataInd",
        label: 'Will data be stored in or passed through another country if not in the US?',
        value: ''
    }

    const felonyProps = {
        emptyChoiceLabel: "Select",
        name: "namedOffcrFelonInd",
        label: 'Have any of the named officers been convicted of felonies in the U.S. or been sanctioned by a government agency in the past three years?',
        value: ''
    }

    const breachProps = {
        emptyChoiceLabel: "Select",
        name: "dataBreachInd",
        label: 'Has the organization had any data breaches that requied  disclosure to a government agency in the past 12 months?',
        value: ''
    }

    const handleDropDownFormChangeStep3 = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setPrivacySecurityStep3RepState({
            ...localStateStep3,
            [event.target.name]: event.target.value,
        });
    }
    const handleAdditionalChangeStep3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrivacySecurityStep3RepState({
            ...localStateStep3,
            [event.target.name]: event.target.value,
        });
    };

    const stateCodeOnChangeStep3 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPrivacySecurityStep3RepState({
           ...localStateStep3,
           [event.target.name]: event.target.value,
        });  
    };

    const moveToBackStep = () => {
        if (registrationStep === 3 ) {
            setRegistrationStep(2);
        } else if (registrationStep === 2 ) {
            setRegistrationStep(1);
        } else if (registrationStep === 1 ) {
            history.goBack();
        }
    };

    const routeChange = () => {
        const path = "/orgFeedbackPg";
        history.push(path);
    }

    const createOrganizationAPI = () => {
        setLoading(true);
        let data:OrgnizationRegistrationModel = OrgnizationRegistrationTransformService();
        OrgnizationRegistrationService.createOrgnizationUserSecurityRegistration(data)
        .then(response => {
            console.log(response.data);
            routeChange();
            setLoading(false);
        })
          .catch(error => {
            setLoading(false);
            if (error && error.response && error.response.data && error.response.data.message) {
                setApiErrorMsg(error.response.data.message);
            }
            if(!error.statusText){
                alert('User name or email already exists.')
            }
            
        });
    }

    const handleSave = (event: React.FormEvent<HTMLInputElement>) => {
        console.log("event", event, localStateStep1);
        event.preventDefault();
        if (registrationStep === 1) {
            resetStep1ErrorMsgs();
            if (step1ValidationFormWithErrorMsg()) {
                setRegistrationStep(2);
            }
        } else if (registrationStep === 2) {
            resetStep2ErrorMsgs();
            if (step2ValidationFormWithErrorMsg()) {
                setRegistrationStep(3);
            }
        } else if (registrationStep === 3) {
            resetStep3ErrorMsgs();
            if (step3ValidationFormWithErrorMsg()) {
                createOrganizationAPI();
            }
        }  
        pageScrollToTop();
    };

    const OrgnizationRegistrationTransformService = (): OrgnizationRegistrationModel => {
        const data = initializeOrgnizationRegistrationModel();
       
        var phoneNumber = localStateStep2.phoneNumber.replace(/[^\w]/gi, '')
        
         data.orgName = localStateStep1.orgName;
         data.orgTaxId = localStateStep1.fedTaxId;
         if (localStateStep1.physicalAdress) {
            data.orgPhyAdd1 = localStateStep1.physicalAdress.streetAddress;
            data.orgPhyAdd2 = localStateStep1.physicalAdress.streetAddress2;
            data.orgPhyAddCity = localStateStep1.physicalAdress.city;
            data.orgPhyAddSt = localStateStep1.physicalAdress.stateCode;
            data.orgPhyAddZip = localStateStep1.physicalAdress.postalCode;
            data.orgPhyAddZipExt = localStateStep1.physicalAdress.postalCodeExtension;
            data.orgPhyCountry = localStateStep1.physicalAdress.countyCode;
         }
         if (localStateStep1.mailingAdress) {
            data.orgMailAdd1 = localStateStep1.mailingAdress.streetAddress;
            data.orgMailAdd2 = localStateStep1.mailingAdress.streetAddress2;
            data.orgMailAddCity = localStateStep1.mailingAdress.city;
            data.orgMailAddSt = localStateStep1.mailingAdress.stateCode;
            data.orgMailAddZip = localStateStep1.mailingAdress.postalCode;
            data.orgMailAddZipExt = localStateStep1.mailingAdress.postalCodeExtension;
            data.orgMailCountry = localStateStep1.mailingAdress.countyCode;
         }
    
         data.userFirstName = localStateStep2.firstName;
         data.userLastName = localStateStep2.lastName;
         data.userEmail = localStateStep2.email;
         data.userName = localStateStep2.userName;
         data.userMobilePhone = phoneNumber;
         data.userPassword = localStateStep2.password;
    
         data.legacyEntCd = localStateStep3.legalEntity;
         data.stateFileCd = localStateStep3.stateFileCd;
         data.ownedByOthrCd = localStateStep3.ownedByOthrCd;
         data.publicRelCd = localStateStep3.publicRelCd;
         data.privPolicyCd = localStateStep3.privPolicyCd;
         data.privPolicyUrl = localStateStep3.privPolicyUrl;
         data.privPolUpdateInd = localStateStep3.privPolUpdateInd;
         data.infoSecPolicyInd = localStateStep3.infoSecPolicyInd;
         data.ind3PrtInd = localStateStep3.ind3PrtInd;
         data.outSideUsDevInd = localStateStep3.outSideUsDevInd;
         data.outSideUsDataInd = localStateStep3.outSideUsDataInd;
         data.namedOffcrFelonInd = localStateStep3.namedOffcrFelonInd;
         data.dataBreachInd = localStateStep3.dataBreachInd;
    
        
        let obj = JSON.stringify(data);
        // For API testing ...
        console.log("printing transformed JSON for backend...", obj);
        return data as OrgnizationRegistrationModel;
    };

    return (
        <React.Fragment>
            {registrationStep === 1 &&
                <QuestionPage
                    title={"Step 1- Organization Registration"}
                    onSave={handleSave}
                    errorMessages={errorMessagesStep1}
                    backBtnOnClick = {moveToBackStep}
                    hideFeatureName={true}
                    disabled={registrationStep === 1 ? false : true}
                    buttonText={"Continue"}
                    hideSubmitBtn={registrationStep === 1 ? false : true}
                >

                    <>
                        <InputText
                            context="orgRegistrationStep1Rep"
                            name="orgName"
                            label="Name of the Organization"
                            value={localStateStep1.orgName}
                            onChange={handleAdditionalChangeStep1}
                            errorMsg={orgNameErrorMsg}
                            mandatoryLabel= {true}
                        />

                        <InputText
                            context="orgRegistrationStep1Rep"
                            name="fedTaxId"
                            label="What is the Federal Tax ID number or Business Identifier for the Organization"
                            value={localStateStep1.fedTaxId}
                            onChange={handleAdditionalChangeStep1}
                            errorMsg={orgNameErrorMsg}
                            mandatoryLabel= {true}
                            maxLength= {9}
                        />

                        <FormLabel>
                            What is the physical address of the organization offering this app ?
                        </FormLabel>

                        <FieldsetAddress
                            address1={address1Props}
                            address2={address2Props}
                            city={cityProps}
                            county={countyProps}
                            state={stateProps}
                            postalCode={postalCodeProps}
                            name={phyAdressComponentName}
                            legend=""
                            onChange={handleAddressFormChangeStep1}
                            hideStreetAddress2={true}
                            
                        />

                        <FormLabel>
                            What is the Primary Mailing address of the organization offering this app ?
                        </FormLabel>
                        <InputChoiceGroup
                            choices={[
                                {
                                    label: "Same as Physical Address",
                                    value: "true",
                                    defaultChecked: localStateStep1.isAddrSameAsPhysicalAddr,
                                },
                            ]}
                            name="sameAsPhysAddrCheckBx"
                            onChange={handleToggleChangeStep1}
                        />
                        {
                            !isHideMailingAddress
                            &&
                            <FieldsetAddress
                                address1={mailAddress1Props}
                                address2={mailAddress2Props}
                                city={mailcityProps}
                                county={mailcountyProps}
                                state={mailstateProps}
                                postalCode={mailpostalCodeProps}
                                name={mailAddressComponentName}
                                legend=""
                                onChange={handleMailAddressFormChangeStep1}
                                hideStreetAddress2={true}
                            />
                        }
                        {/* {
                            isHideMailingAddress
                            &&
                            <FieldsetAddress
                                address1={address1Props}
                                address2={address2Props}
                                city={cityProps}
                                county={countyProps}
                                state={stateProps}
                                postalCode={postalCodeProps}
                                name={mailAddressComponentName}
                                legend=""
                                readOnly={true}
                                onChange={handleMailAddressFormChangeStep1}
                                hideStreetAddress2={true}
                            />
                        } */}
                    </>
                </QuestionPage>
            }
            {registrationStep === 2  &&
                <QuestionPage
                    title="Step 2- Primary Administrator Identification"
                    onSave={handleSave}
                    errorMessages={errorMessagesStep2}
                    backBtnOnClick = {moveToBackStep}
                    hideFeatureName={true}
                    buttonText= "Continue"
                >

                    <>
                        <InputText
                            context="registrationRep"
                            name="firstName"
                            label="First Name"
                            value={localStateStep2.firstName}
                            onChange={handleAdditionalChangeStep2}
                            errorMsg={firstNameErrorMsg}
                            mandatoryLabel= {true}
                        />
                        <InputText
                            context="registrationRep"
                            name="lastName"
                            label="Last Name"
                            value={localStateStep2.lastName}
                            onChange={handleAdditionalChangeStep2}
                            errorMsg={lastNameErrorMsg}
                            mandatoryLabel= {true}
                        />
                        <InputText
                            context="registrationRep"
                            name="userName"
                            label="User Name"
                            value={localStateStep2.userName}
                            onChange={handleAdditionalChangeStep2}
                            errorMsg={userNameErrorMsg}
                            mandatoryLabel= {true}
                        />
                        <InputText
                            context="registrationRep"
                            name="email"
                            label="Email"
                            value={localStateStep2.email}
                            onChange={handleAdditionalChangeStep2}
                            errorMsg={emailErrorMsg}
                            mandatoryLabel= {true}
                        />

                        <PhoneNumber
                            context="registrationRep"
                            name="phoneNumber"
                            label="Telephone Number"
                            value={localStateStep2.phoneNumber}
                            onChange={handleAdditionalChangeStep2}
                            errorMsg={phoneErrorMsg}
                            mandatoryLabel= {true}
                            
                        />
                        <InputText
                            context="registrationRep"
                            name="password"
                            label="Password"
                            value={localStateStep2.password}
                            onChange={handleAdditionalChangeStep2}
                            errorMsg={passwordErrMsg}
                            type="password"
                            mandatoryLabel= {true}
                        />
                        <InputText
                            context="registrationRep"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={localStateStep2.confirmPassword}
                            onChange={handleAdditionalChangeStep2}
                            errorMsg={passwordErrMsg}
                            type="password"
                            mandatoryLabel= {true}
                        />
                        
                    </>

                </QuestionPage>
            }

            {registrationStep === 3 &&
                <QuestionPage
                    title="Step 3-  Privacy and security"
                    onSave={handleSave}
                    errorMessages={errorMessagesStep3}
                    backBtnOnClick = {moveToBackStep}
                    hideFeatureName={true}
                    hideSubmitBtn={loading}
                    buttonText= "Submit"
                >
                    { !loading &&
                    <>
                        <Dropdown 
                            choices={dropdownValues} {...legalEntyProps} 
                            value={localStateStep3.legalEntity} 
                            onChange={handleDropDownFormChangeStep3}
                            errorMsg={legalEntityErrorMsg}
                            mandatoryLabel = {true}
                         />

                        <StateDropdown
                            emptyChoiceLabel="Select"
                            label="In which state did you file your business registration?"
                            name="stateFileCd"
                            data-testid={`securitystate`}
                            onChange={stateCodeOnChangeStep3}
                            value={localStateStep3.stateFileCd}
                            errorMsg={stateFileCdErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={dropdownValues} {...anotherCorporationProps} 
                            value={localStateStep3.ownedByOthrCd} 
                            onChange={handleDropDownFormChangeStep3}
                            errorMsg={ownedByOthrCdErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={publicRelCdDropdownValues} {...publicRelCdProps} 
                            value={localStateStep3.publicRelCd} 
                            onChange={handleDropDownFormChangeStep3} 
                            errorMsg={publicRelCdErrorMsg}
                            mandatoryLabel = {true}
                            />
                        <Dropdown 
                            choices={dropdownValues} {...privacyPolicyProps} 
                            value={localStateStep3.privPolicyCd} 
                            onChange={handleDropDownFormChangeStep3} 
                            errorMsg={privPolicyCdErrorMsg}
                            mandatoryLabel = {true}
                            />
                        <InputText
                            context="privacysec"
                            name="privPolicyUrl"
                            label="Please provide link to your dated privacy policy (Provide material updates as they occur)?"
                            value={localStateStep3.privPolicyUrl}
                            onChange={handleAdditionalChangeStep3}
                            errorMsg={privPolicyUrlErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={dropdownValues} {...privPolUpdateIndProps} 
                            value={localStateStep3.privPolUpdateInd} 
                            onChange={handleDropDownFormChangeStep3}
                            errorMsg={privPolUpdateIndErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown
                             choices={dropdownValues} {...secPolicyProps} 
                             value={localStateStep3.infoSecPolicyInd} 
                             onChange={handleDropDownFormChangeStep3}
                             errorMsg={infoSecPolicyIndErrorMsg}
                             mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={dropdownValues} {...ind3PrtIndProps} 
                            value={localStateStep3.ind3PrtInd} 
                            onChange={handleDropDownFormChangeStep3}
                            errorMsg={ind3PrtIndErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={dropdownValues} {...outsideUsProps} 
                            value={localStateStep3.outSideUsDevInd} 
                            onChange={handleDropDownFormChangeStep3}
                            errorMsg={outSideUsDataIndErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={dropdownValues} {...dataOutsideUSProps} 
                            value={localStateStep3.outSideUsDataInd} 
                            onChange={handleDropDownFormChangeStep3} 
                            errorMsg={outSideUsDataIndErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={dropdownValues} {...felonyProps} 
                            value={localStateStep3.namedOffcrFelonInd} 
                            onChange={handleDropDownFormChangeStep3}
                            errorMsg={namedOffcrFelonIndErrorMsg}
                            mandatoryLabel = {true}
                        />
                        <Dropdown 
                            choices={dropdownValues} {...breachProps} 
                            value={localStateStep3.dataBreachInd} 
                            onChange={handleDropDownFormChangeStep3}
                            errorMsg={dataBreachIndErrorMsg}
                            mandatoryLabel = {true}
                        />
                    </>
                }
                <BeatLoader loading = {loading} cssOverride={override}/>
                </QuestionPage>
        }
        </React.Fragment>
    );
}
export default OrganizationUserSecurityHomePage;

