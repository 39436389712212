import "./link-my-medicaid-agreement-modal.css";
import Button from "../../components/Button";


interface LinkMyMedicaidAgreementProps {
    
    showPopup: boolean,
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;

}

function LinkMyMedicaidAgreementModal(props: LinkMyMedicaidAgreementProps) {
    
    return (
        <>
            {(props && props.showPopup && (
                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>{ "Agreement"}</h2>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {"Cancel"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div className={"ModalText"}>
                                    {(<>
                                        <p>
                                        Please contact the  State of Nebraska to understand the terms and conditions for the Interoperability application
                                        </p>
                                    </>
                                    )}
                                    <Button className="OkButton" onClick={props.agreeBtnOnClick}> {"I Agree"} </Button>
                                   
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )) ||
              null}
        </>
    );
}

export default LinkMyMedicaidAgreementModal;
