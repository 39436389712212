import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./app/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./app/scrollToTop";
import { PublicClientApplication } from "@azure/msal-browser";
// import { msalConfig } from "./authConfig";
import { msalConfig } from "./features/admin-signin-landing-page/authConfig"
export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ScrollToTop />
                <App msalInstance={msalInstance} />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
