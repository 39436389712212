import classnames from "classnames";
import Fieldset from "./Fieldset";
import InputText from "./InputText";
import React from "react";

/**
 * Date text fields (month, day, year). Also renders supporting UI elements like label,
 * hint text, and error message.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/date-input/)
 */
function InputDate(props: InputDateProps) {
    const hasError = !!props.errorMsg;
    const inputClassNames = {
        month: classnames("usa-input--inline", {
            "usa-input--error": props.monthInvalid,
        }),
        day: classnames("usa-input--inline", {
            "usa-input--error": props.dayInvalid,
        }),
        year: classnames("usa-input--inline", {
            "usa-input--error": props.yearInvalid,
        }),
    };

    const formGroupClasses = classnames("usa-fieldset", "usa-form-group", {
        "usa-form-group--error": hasError,
    });

    return (
        <Fieldset
            className={formGroupClasses}
            errorMsg={props.errorMsg}
            hint={props.hint}
            legend={props.legend || null}
            legendStyle={props.legendStyle}
            legendBold={props.legendBold}
        >
            <div className="usa-memorable-date">
                <InputText
                    formGroupClassName="usa-form-group--month"
                    inputClassName={inputClassNames.month}
                    inputMode="numeric"
                    label={props.monthLabel}
                    maxLength={2}
                    name={`${props.name}_month`}
                    pattern="[0-9]*"
                    onChange={props.onChangeMonth}
                    value={props.monthValue}
                    autoComplete="off"
                    bold={props.labelBold}
                />
                {!props.hideDay &&(
                    <React.Fragment>
                        <span className="margin-top-7 margin-right-2">/</span>
                        <InputText
                            formGroupClassName="usa-form-group--day"
                            inputClassName={inputClassNames.day}
                            inputMode="numeric"
                            label={props.dayLabel}
                            maxLength={2}
                            name={`${props.name}_day`}
                            pattern="[0-9]*"
                            onChange={props.onChangeDay}
                            value={props.dayValue}
                            autoComplete="off"
                            bold={props.labelBold}
                        />
                    </React.Fragment>
                )}                
                <span className="margin-top-7 margin-right-2">/</span>
                <InputText
                    formGroupClassName="usa-form-group--year"
                    inputClassName={inputClassNames.year}
                    inputMode="numeric"
                    label={props.yearLabel}
                    maxLength={4}
                    name={`${props.name}_year`}
                    pattern="[0-9]*"
                    onChange={props.onChangeYear}
                    value={props.yearValue}
                    autoComplete="off"
                    bold={props.labelBold}
                />
            </div>
        </Fieldset>
    );
}

interface InputDateProps {
    /**
     * Localized label for the day field
     */
    dayLabel?: React.ReactNode;
    /**
     * Apply error styling to the day `input`
     */
    dayInvalid?: boolean;
    /**
     * hide day `input`
     */
     hideDay?: boolean;
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Localized hint text for the entire fieldset
     */
    hint?: React.ReactNode;
    /**
     * Localized label for the entire fieldset
     */
    legend?: React.ReactNode;
    /**
     * Styling for the legend
     */
    legendStyle?: "default" | "large";
    /**
     * Should the legend be bold faced
     */
    legendBold?: boolean;
    /**
     * Localized label for the month field
     */
    monthLabel: React.ReactNode;
    /**
     * Apply error styling to the month `input`
     */
    monthInvalid?: boolean;
    /**
     * A name for the full date string. This is used for generating the individual field names
     * and is the name used as the onChange event's `target.name`
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * Apply error styling to the year `input`
     */
    yearInvalid?: boolean;
    /**
     * Localized label for the year `input` field
     */
    yearLabel: React.ReactNode;
    /**
     * HTML input `onChange` attribute
     */
    onChangeDay?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    /**
     * HTML input `onChange` attribute
     */
    onChangeMonth?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    /**
     * HTML input `onChange` attribute
     */
    onChangeYear?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    /**
     * Sets the input's `value`. Use this in combination with `onChange`
     * for a controlled component.
     */
    monthValue?: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * Sets the input's `value`. Use this in combination with `onChange`
     * for a controlled component.
     */
    dayValue?: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * Sets the input's `value`. Use this in combination with `onChange`
     * for a controlled component.
     */
    yearValue?: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * Should the label be bold faced
     */
    labelBold?: boolean;
}

export default InputDate;
