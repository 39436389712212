import axios from 'axios';
import {getBaseApiUrl} from "../../../utils/url-constant";

const getAll = () => {
  const url = getBaseApiUrl() + "/getAllOrganizations";

  const customHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Api-Key': process.env.REACT_APP_X_API_KEY,
  };

  return axios.get(url, { headers: customHeaders });
};

const get = (id:any) => {
  let url = getBaseApiUrl() + `/getOrganization/${id}`    
  axios.defaults.baseURL = '';
  const customHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Api-Key': process.env.REACT_APP_X_API_KEY,
  };
  return axios.get(url, { headers: customHeaders })};

const updateOrganization = (data: any) => {
  let url = getBaseApiUrl() + "/admin/orgnization/approval" ;
  axios.defaults.baseURL = '';
  axios.defaults.headers.common['Content-Type'] = "application/json";
  axios.defaults.headers.common['Accept'] = "*/*";
 return axios.post(`${url}`, data);
      
}

const getCredentials = () => {
  let url = getBaseApiUrl() + "/app/app-credentials" ;
  axios.defaults.baseURL = '';
  axios.defaults.headers.common['Content-Type'] = "application/json";
  axios.defaults.headers.common['Accept'] = "*/*";
 return axios.get(`${url}`);
      
}


const OrganizationService = {
  getAll,
  get,
  updateOrganization,
  getCredentials
};
export default OrganizationService;