import React, { useState, useEffect } from "react";
import QuestionPage from "../../templates/QuestionPage";
import Switch from "../../components/ToggleSwitch/index";
import CustomTable, {MinTableItem, MinTableHeader} from "../../components/CustomTable";
import { ManageConsent } from "../admin-manage-consent/model/ManageConsent";
import ManageConsentService  from  "../admin-manage-consent/service/ManageConsentService";
import UserManageConsentRevokePage from "./user-manage-consent-revoke-page";

const UserManageConsentList = () => {

    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState<any>(null);
    const [confirmModalData, setConfirmModalData] = useState<any>(null);
    const [userManageConsentData, setUserManageConsentData] = useState<UserManageConsentTable[]>([]);
    const [userManageConsentTableData, setUserManageConsentTableData] = useState<UserManageConsentTable[]>([]);

    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        getUserManageConsentList();
    }, []);

    const getUserManageConsentList = () => {
        const b2cMemberId = sessionStorage.getItem('member_id');
        ManageConsentService.getAllByMemberId(b2cMemberId)
          .then(response => {
            console.log(response.data);
            let preparedData = prepareUserManageConsentTableData(response.data)
            setUserManageConsentData(preparedData);
            const filteredManageConsent = preparedData.filter((item) =>  item.action == 'Revoke');
            setUserManageConsentTableData(Object.create(filteredManageConsent));
          })
          .catch(e => {
            console.log(e);
          });
    };

    const prepareUserManageConsentTableData = (data: ManageConsent[]): UserManageConsentTable[] => {
        if (data) {
            return data.map(getUserManageConsentTableData);
        } else {
             return [];
        }
    }

    function getUserManageConsentTableData(data: ManageConsent) {
        let adminManageConsentTableData :UserManageConsentTable =  { 
            appId: +data.appName.split("-appId:")[1],
            appName: data.appName.split("-appId:")[0],
            status: data.status,
            orgName: data.orgName,
            memberId: data.memberId,
            medicaidId: data.medicaidId,
            updateDt: '',
            action: 'Revoke',
            id: data.id,
            updateBy: data.updateBy
        }  
        if (data.updateDt) {
            adminManageConsentTableData['updateDt'] = data.updateDt;
        } else if (data.createDt) {
            adminManageConsentTableData['updateDt'] = data.createDt;
        }
        if (data.status == 'A') {
            adminManageConsentTableData['action'] = 'Revoke';
        } else {
            adminManageConsentTableData['action'] = 'Authorize';
        }
        return adminManageConsentTableData;
    }


    const userManageConsentHeaders:MinTableHeader[] = [
       
        {
            id: "id",
            name: "Consent ID",
            type: "text",
            isHidden: true
        },
        {
            id: "memberId",
            name: "Member Id",
            type: "text",
            isHidden: true
        },
        {
            id: "appId",
            name: "Application ID",
            type: "text"
        },
        {
            id: "appName",
            name: "Application Name",
            type: "text"
        },
        {
            id: "orgName",
            name: "Organization",
            type: "text"
        },
        {
            id: "updateDt",
            name: "Last Updated Date",
            type: "text"
        },
        {
            id: "updateBy",
            name: "Last Updated By",
            type: "text"
        },
        {
            id: "action",
            name: "Actions",
            type: "button"
        }
    ]

    
    const filterManageConsentTableData = (showActiveData: any) => {
        const filteredManageConsent = userManageConsentData.filter((item) => showActiveData ? item.action == 'Revoke' : item.action == 'Authorize');
        setUserManageConsentTableData(Object.create(filteredManageConsent));
    }

    const toggleNotifications = () => {
        setToggle(!toggle);
        filterManageConsentTableData(!toggle);
    };

    const handleButtonFunctionality = (item: any) => {
        console.log("item", item)
        const showActiveData = toggle;
        let message = "The Application ";
        message = message + item.data.appName.split("-appId:")[0] + ",with Application Id: " + item.data.appId+ ", "
        if (showActiveData) {
        message = message + "will no longer be allowd access to your medicaid Data";
        } else {
        message = message + "will be allowd access to your medicaid Data";
        }
        setConfirmModalMessage(message);
        setConfirmModalData(item.data);
        setOpenConfirmModal(true);
    };

    const handleCloseBtnConfirmModal = () => {
        setConfirmModalMessage(null);
        setConfirmModalData(null);
        setOpenConfirmModal(false);
    };

    const handleAgreeBtnConfirmModal = () => {
        const showActiveData = toggle;
        setConfirmModalMessage(null);
        setOpenConfirmModal(false);
        if (confirmModalData && confirmModalData.id) {
            getUserManageConsentList();
            setConfirmModalData(null);
        }
    };

    
    return (
        
        <React.Fragment>
            <QuestionPage
                title= "Manage Consent"
                hideFeatureName = {true}
                hideSubmitBtn = {true}
                formClass =""
            >
                <Switch theme="default"
                    enabled = {toggle}
                    className = "text-align-right"
                    onStateChanged = {toggleNotifications}
                />
                {
                    toggle &&
                    <h5>
                        The following provides a list of applications that you have authorized to access your Medicaid data. 
                        You can revoke access for applications you no longer wish to share your information here.
                    </h5>
                }

                {
                    !toggle &&
                    <h5>
                        The following applications no longer have to access your Medicaid data. 
                    </h5>
                }
                
                <CustomTable
                    headers={userManageConsentHeaders}
                    items={userManageConsentTableData}
                    tableButton={handleButtonFunctionality}
                    buttonDisable = {false}
                    
                />
                 <UserManageConsentRevokePage
                    showPopup={openConfirmModal}
                    agreeBtnOnClick={handleAgreeBtnConfirmModal}
                    closeBtnOnClick = {handleCloseBtnConfirmModal}
                    messageText = {confirmModalMessage}
                    data = {confirmModalData}
                />

            </QuestionPage>
        </React.Fragment>
    );
};

interface UserManageConsentTable extends MinTableItem
{
    checkbox?: string,
    status: string,
    memberId: string,
    medicaidId: string,
    id: number, //consent Id
    appName: string,
    orgName: string,
    updateDt: string,
    action: string,
    appId: number,
    updateBy: string
}


export default UserManageConsentList;