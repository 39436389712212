import "../Modal/CustomModal";
import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import {getPatientEndpoint, getProviderEndpoint } from "../../utils/url-constant";
import pationApiData from '../api-details/patientApiData.json';
import OrganizationService from "../admin-organization-list/service/adminOrganizationService";

interface AppStatusClientPageProps {

    showPopup: boolean,
    okButtonText?: string
    closeButtonText?: string
    data: any,
    adminAppStatusData: any,
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;
}

function AppStatusClientPage(props: AppStatusClientPageProps) {

    const [detailsVisible, setDetailsVisible] = useState(false);
    const [isProviderLinkCopied, setIsProviderLinkCopied] = useState(false);
    const [isPatientLinkCopied, setIsPatientLinkCopied] = useState(false);

    const handleDetailsClick = () => {
        setDetailsVisible(!detailsVisible);
    };

    // const handleCopyProviderLinkClick = (e: any) => {
    //     const link = e.target.getAttribute("href");
    //     const textArea = document.createElement("textarea");
    //     textArea.value = link;
    //     document.body.appendChild(textArea);
    //     textArea.select();
    //     document.execCommand("copy");
    //     document.body.removeChild(textArea);
    //     setIsProviderLinkCopied(true);
    //     e.preventDefault();
    //     setTimeout(() => {
    //         setIsProviderLinkCopied(false);
    //     }, 2000);
    // };

    // const handleCopyPatientLinkClick = (e: any) => {
    //     const link = e.target.getAttribute("href");
    //     const textArea = document.createElement("textarea");
    //     textArea.value = link;
    //     document.body.appendChild(textArea);
    //     textArea.select();
    //     document.execCommand("copy");
    //     document.body.removeChild(textArea);
    //     setIsPatientLinkCopied(true);
    //     e.preventDefault();
    //     setTimeout(() => {
    //         setIsPatientLinkCopied(false);
    //     }, 2000);
    // };


    //Copy curl request code
    const handleCopyProviderLinkClick = (e: any) => {
        const link = e.target.getAttribute("href");
        //const clientId = props.data?.client_id || "your-client-id"; 
        //const clientSecret = props.data?.client_secret || "your-client-secret";
        copyCurlRequest(link, null, null, null);
        setIsProviderLinkCopied(true);
        e.preventDefault();
        setTimeout(() => {
            setIsProviderLinkCopied(false);
        }, 2000);
    };

    const handleLinkClick = () => {
        const confirmation = window.confirm("Are you sure you want to navigate to the API Details page?");

        if (confirmation) {
            window.open('/apiDetails', '_blank');
        }
    };

    const access_token = sessionStorage.getItem('access_token');
    const handleCopyPatientLinkClick = (e: any) => {
        const link = e.target.getAttribute("href");
        const clientId = props.data?.client_id || "your-client-id";
        const clientSecret = props.data?.client_secret || "your-client-secret";
        const authorization = `Bearer ${access_token}` || "your-Authorization-token";

        copyCurlRequest(link, clientId, clientSecret, authorization);
        setIsPatientLinkCopied(true);
        e.preventDefault();
        setTimeout(() => {
            setIsPatientLinkCopied(false);
        }, 2000);
    };

    const copyCurlRequest = (apiUrl: any, clientId: any, clientSecret: any, authorization: any) => {
        const curlCommand = `curl -X POST "${apiUrl}" -H "Client-Id: ${clientId}" -H "Client-Secret: ${clientSecret}" -H "Authorization: ${authorization}"`;
        const textArea = document.createElement("textarea");
        textArea.value = curlCommand;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    };

    useEffect(() => {
        if (!props.showPopup) {
            setDetailsVisible(false);
        }
    }, [props.showPopup]);

    const maskValue = (value: any) => {
        if (value) {
            return '*'.repeat(value.length);
        }
    };

    //export json file steps
    const handlePatientExportApiCollection = () => {
        
        //replace the JSON values if we need it
        function replaceValuesInJson(data: any) {
            const modifiedData: any = [];
            const dataKeys: any[] = [
                {
                    key: 'scope',
                    dataKey: 'patientScope',
                },
                {
                    key: 'clientSecret',
                    dataKey: 'patientClientSecret',
                },
                {
                    key: 'clientId',
                    dataKey: 'patientClientId',
                },
                {
                    key: 'accessTokenUrl',
                    dataKey: 'patientAccessTokenUrl',
                },
                {
                    key: 'authUrl',
                    dataKey: 'patientAuthUrl',
                },
                {
                    key: 'tokenName',
                    dataKey: 'tokenName',
                },
                {
                    key: 'grant_type',
                    dataKey: 'grantType',
                },
                {
                    key: 'client_authentication',
                    dataKey: 'clientAuthentication',
                }
            ];
            dataKeys.forEach(key => {
                const dt = {
                    
                        "key": key.key,
                        "value": data[key.dataKey]?.trim(),
                        "type": "string"
                    
                }
                modifiedData.push(dt);
            });

            const hardcodedValues = [
                {
                    "key": "redirect_uri",
                    "value": "http://localhost:3000",
                    "type": "string"
                },
                {
                    "key": "state",
                    "value":"123456",
                    "type": "string"
                },
                {
                    "key": "tokenType",
                    "value": "Bearer",
                    "type": "string"
                },
                {
                    "key": "challengeAlgorithm",
                    "value": "S256",
                    "type": "string"
                },
                {
                    "key": "useBrowser",
                    "value": "false",
                    "type": "boolean"
                },
                {
                    "key": "password",
                    "value": "Chanq321thorn!",
                    "type": "string"
                },
                {
                    "key": "username",
                    "value": "ACEJrsUser",
                    "type": "string"
                },
                {
                    "key": "addTokenTo",
                    "value": "header",
                    "type": "string"
                }
            ];
    
            modifiedData.push(...hardcodedValues);
          
            const newOauth2Array = modifiedData;

            pationApiData.item[0].request.auth.oauth2= newOauth2Array as any;
            pationApiData.item[0].request.url.raw= getPatientEndpoint()

            return JSON.stringify(pationApiData, null, 2);
        }

        OrganizationService.getCredentials()
        .then(response => {
          const modifiedJsonString = replaceValuesInJson(response.data);
          const jsonBlob = new Blob([modifiedJsonString], { type: "application/json" });
          const jsonUrl = URL.createObjectURL(jsonBlob);
  
          const downloadLink = document.createElement("a");
          downloadLink.href = jsonUrl;
          downloadLink.download = "patient_api.json";
          downloadLink.click();
  
          URL.revokeObjectURL(jsonUrl);
        })
        .catch(e => {
          console.log(e);
        });
        

       
    };

    return (
        <>
            {(props && props.showPopup && (
                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>Application Client Credentials</h2>
                                    </div>
                                    <div className="col-3" style={{ marginLeft: 50, whiteSpace: "nowrap" }}>
                                        <Button
                                            onClick={handleDetailsClick}
                                            className="usa-button CloseButton"

                                        >
                                            {detailsVisible ? "Hide Details" : "API Details"}
                                        </Button>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {props.closeButtonText || "Close"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div className={"ModalText"}>
                                    {/* <InputText
                                        context="appStatusModal"
                                        name="applicationId"
                                        label="Application Id"
                                        value={maskValue(props.data?.app_id)}
                                        readOnly={true}
                                        formGroupInputClasses="usa-input-group-readonly"
                                    /> */}
                                    <InputText
                                        context="appStatusClientModal"
                                        name="clientId"
                                        label="Client Id"
                                        value={props.data?.client_id}
                                        readOnly={true}
                                        formGroupInputClasses="usa-input-group-readonly"
                                    />
                                    <InputText
                                        context="appStatusClientModal"
                                        name="clientSecret"
                                        label="Client Secret"
                                        value={props.data?.client_secret}
                                        readOnly={true}
                                        formGroupInputClasses="usa-input-group-readonly"
                                    />
                                </div>
                                <div className={"ModalText"}>
                                    {detailsVisible && (
                                        <>
                                        {props.adminAppStatusData?.producName ==='patient-access-api' &&
                                        <>
                                            <div>
                                                <label className="usa-label text-bold">Patient API Endpoint</label>
                                                <p>
                                                    {getPatientEndpoint()}
                                                </p>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {/* <a
                                                    href={getPatientEndpoint()}
                                                    onClick={handleCopyPatientLinkClick}
                                                    style={{ color: isPatientLinkCopied ? "green" : "blue" }}
                                                >
                                                    {isPatientLinkCopied ? "✔ Copied" : "Copy Patient API Request"}
                                                </a> */}
                                                <div >
                                                    <Button onClick={handlePatientExportApiCollection}>
                                                        Export Patient API as JSON
                                                    </Button>
                                                </div>
                                            </div>
                                            </>
}
{props.adminAppStatusData?.producName === 'provider-api' &&
<>
                                            <div>
                                                <label className="usa-label text-bold">Provider API Endpoint</label>
                                                <p>
                                                    {getProviderEndpoint()}
                                                </p>
                                            </div>
                                            <a
                                                href={getProviderEndpoint()}
                                                onClick={handleCopyProviderLinkClick}
                                                style={{ color: isProviderLinkCopied ? "green" : "blue" }}
                                            >
                                                {isProviderLinkCopied ? "✔ Copied" : "Copy Provider API Request"}
                                            </a>
                                            </>
}
                                            <div style={{ marginLeft: 5 }}>
                                                <h3 style={{ fontWeight: "bold" }}>How to Use API</h3>
                                                <a
                                                    onClick={handleLinkClick}
                                                    style={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                        color: '#2100ff'
                                                    }}
                                                    // href={'/apiDetails'} // URL need to update once page is ready
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Link &nbsp;
                                                    <span style={{ fontSize: "80%", verticalAlign: "middle" }}>
                                                        ↗️
                                                    </span>
                                                </a>
                                            </div>

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )) ||
                null}
        </>
    );
}

export default AppStatusClientPage;