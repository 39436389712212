import { configureStore } from "@reduxjs/toolkit";


// Benefit Application //
import orgRegistrationStep1RepDetails  from "../features/organization-user-security-registration/store/step1OrganizationRegistrationRepresentativeSlice";
import userRegistrationRepDetails from "../features/organization-user-security-registration/store/step2UserRegistrationRepresentativeSlice";
import privacyPolicyRepDetails from "../features/organization-user-security-registration/store/step3PrivacySecurityRepresentativeSlice";


// NOTE: Organize the imports for reducers as they're built

export const store = configureStore({
    reducer: {
        userRegistrationRepDetails: userRegistrationRepDetails,
        orgRegistrationStep1RepDetails: orgRegistrationStep1RepDetails,
        privacyPolicyRepDetails: privacyPolicyRepDetails,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
