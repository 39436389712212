import classnames from "classnames";
import uniqueId from "lodash/uniqueId";
import FormLabel from "./FormLabel";

/**
 * Custom Zip Code [input](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input) field. Also renders
 * supporting UI elements like label, hint text, and error message.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/text-input/)
 */
function ZipCodeInput(props: ZipCodeInputProps) {
    // Generate a unique ID for associating field elements like the
    // input, label, and error message. This is important for a11y.
    const inputId = uniqueId("InputText");
    const hasError = !!props.errorMsg;

    const fieldClasses = classnames(
        "usa-input",
        "inline-block",
        "usa-input--medium",
        props.inputClassName,
        {
            "usa-input--error": hasError,
            "margin-top-3": props.large,
        }
    );
    const fieldClasses2 = classnames(
        "usa-input",
        "inline-block",
        "usa-input--small",
        props.inputClassName,
        {
            "usa-input--error": hasError,
            "margin-top-3": props.large,
        }
    );
    const formGroupClasses = classnames("usa-form-group", props.formGroupClassName, {
        "usa-form-group--error": hasError,
        "margin-top-5": props.large,
    });

    const enforceNumeric = (value: string) => {
        return value.replace(/\D/g, "");
    };
    const zipCodeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = enforceNumeric(e.target.value);
        if (props.onChange) props.onChange(e);
    };

    const zipCodeExtOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = enforceNumeric(e.target.value);
        if (props.onChange) props.onChange(e);
    };

    return (
        <div className={formGroupClasses}>
            <FormLabel
                errorMsg={props.errorMsg}
                inputId={inputId}
                hint={props.hint}
                large={props.large}
                optionalText={props.optionalText}
            >
                {props.label}
                {props.mandatoryLabel && (
                    <span className="usa-mandatory-label">
                           *
                    </span>
                )}
            </FormLabel>

            <input
                className={fieldClasses}
                id={inputId}
                name={`${props.name}5`}
                onChange={zipCodeOnChange}
                value={props.postalCode5Value}
                maxLength={5}
                inputMode="numeric"
                title="enter 5 digits postal code"
                readOnly={props.readOnly}
            />
            <span className="usa-label inline-block">
                &nbsp;<b>-</b>&nbsp;
            </span>
            <input
                className={fieldClasses2}
                id={`${inputId}4`}
                name={`${props.name}4`}
                onChange={zipCodeExtOnChange}
                value={props.postalCode4Value}
                maxLength={4}
                inputMode="numeric"
                title="enter 4 digits postal code"
                readOnly={props.readOnly}
            />
        </div>
    );
}

interface ZipCodeInputProps {
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Additional classes to include on the containing form group element
     */
    formGroupClassName?: string;
    /**
     * Localized hint text
     */
    hint?: React.ReactNode;
    /**
     * Additional classes to include on the HTML input
     */
    inputClassName?: string;
    /**
     * Localized field label
     */
    label: React.ReactNode;
    /**
     * Large variant of label
     */
    large?: boolean;
    /**
     * HTML input `name` attribute
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * HTML input `onChange` attribute
     */
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    /**
     * Localized text indicating this field is optional
     */
    optionalText?: React.ReactNode;
    /**
     * Change the width of the input field
     */
    width?: "small" | "medium";
    /**
     * Sets the input's `value`. Use this in combination with `onChange`
     * for a controlled component.
     */
    postalCode5Value?: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * Sets the input's `value`. Use this in combination with `onChange`
     * for a controlled component.
     */
    postalCode4Value?: React.InputHTMLAttributes<HTMLInputElement>["value"];

    readOnly?:boolean;
    mandatoryLabel?: boolean;
}

export default ZipCodeInput;
