import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddressState ,initializeAddressState } from "../../BenefitsApplication/shared/services/addressService";


export interface  OrganizationRegistrationRepresentativeState {
    orgName: string;
    fedTaxId: string;
    physicalAdress: AddressState;
    mailingAdress: AddressState;
    isAddrSameAsPhysicalAddr: boolean;
}

export const initializeOrgRegistrationStep1RepState = () => {
    return {
        orgName: "",
        fedTaxId: "",
        physicalAdress: initializeAddressState(),
        mailingAdress: initializeAddressState(),
        isAddrSameAsPhysicalAddr: false,
    } as OrganizationRegistrationRepresentativeState;
};


export const getLastItemOrNew = (orgRegistrationStep1RepArray: OrganizationRegistrationRepresentativeState[]) => {
    const orgRegistrationStep1RepDetailsIndex = orgRegistrationStep1RepArray.length - 1;
    const lastItem =
    orgRegistrationStep1RepDetailsIndex >= 0
        ? orgRegistrationStep1RepArray[orgRegistrationStep1RepDetailsIndex]
        : initializeOrgRegistrationStep1RepState();
    return { index: orgRegistrationStep1RepDetailsIndex, item: lastItem };
};

const initialState = [] as OrganizationRegistrationRepresentativeState[];

const OrganizationRegistrationRepresentativeDetailsSlice = createSlice({
    name: "orgRegistrationStep1RepDetails",
    initialState,
    reducers: {
        addOrgRegistrationStep1RepDetails(state, action: PayloadAction<OrganizationRegistrationRepresentativeState>) {
            state.push(action.payload);
        },
        updateOrgRegistrationStep1RepDetailsByIndex(
            state,
            action: PayloadAction<{ index: number; arState: OrganizationRegistrationRepresentativeState }>
        ) {
            
            //add new if conditions are met
            if (state.length === 0 || (state.length < 4 && action.payload.index < 0)) {
                state.push(action.payload.arState);
            } else {
                //grab the item by index or most recent;
                const validIndex =
                    action.payload.index >= state.length ? state.length - 1 : action.payload.index;
                const stateObj = state[validIndex];
                for (const [key, value] of Object.entries(action.payload.arState)) {
                    // stateObj[key as keyof OrgRegistrationStep1RepresentativeState] = value;
                }
            }
        },
        removeOrgRegistrationStep1RepDetails(state, action: PayloadAction<number>) {
            const index = action.payload;
            //do not remove if index is out of index range
            if (index < 0 || index >= state.length) return;
            state.splice(index, 1);
        },
        removeAll(state) {
            state.splice(0, state.length);
        },
    },
});

export const {
    addOrgRegistrationStep1RepDetails,
    updateOrgRegistrationStep1RepDetailsByIndex,
    removeOrgRegistrationStep1RepDetails,
    removeAll,
} = OrganizationRegistrationRepresentativeDetailsSlice.actions;

export default OrganizationRegistrationRepresentativeDetailsSlice.reducer;




