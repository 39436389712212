import React, { useState, useEffect, CSSProperties } from "react";
import QuestionPage from "../../templates/QuestionPage";
import CustomTable, {MinTableItem, MinTableHeader} from "../../components/CustomTable";
import AdminOrganizationReviewPage from "./admin-organization-review-page";
import OrganizationModal from "./admin-organization-modal";
import OrganizationService  from  "./service/adminOrganizationService";
import { Organizations } from "../model/Organizations";
import InputText from "../../components/InputText";
import NavigationConstants from "../BenefitsApplication/shared/enums/navigation";
import { useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";


const AdminOrganizationList = () => {

    const [organizationsTableData, setOrganizationsTableData] = useState<AdminOrganizationTable[]>([]);
    const [adminOrganizationsData, setAdminOrganizationsData] = useState<AdminOrganizationTable[]>([]);
    const [searchField, setSearchField] = useState<any>('');
    const [loading, setLoading] = useState(true);

    const [openAdminOrganizationReviewPopup, setOpenAdminOrganizationReviewPopup] = useState(false);
    const [organizationReviewData, setOrganizationReviewData] = useState<any>();

    const [openAdminOrganizationPagePopup, setOpenAdminOrganizationPagePopup] = useState(false);
    const [adminOrganizationPageData, setAdminOrganizationPageData] = useState<any>();

    const history = useHistory();
    // const adminLogin = sessionStorage.getItem('admin_login');
    // if (!adminLogin) {
    //     const path = NavigationConstants.AdminSignInPage;
    //     history.push(path);
    // }

    const override: CSSProperties = {
        marginTop: "20%",
        marginLeft: "50%"
     };

    const statusDropdownChoices = [
        {
            label: "Approved",
            value: "AP",
        },
        {
            label: "Rejected",
            value: "RJ",
        },
        {
            label: "In Review",
            value: "IR",
        },
        {
            label: "Requires Additional Information",
            value: "RA",
        }
    ]

    useEffect(() => {
        getOrganizationsList();
    }, []);

    const getOrganizationsList = () => {
        setLoading(true);
        OrganizationService.getAll()
          .then(response => {
            console.log(response.data);
            let preparedData = prepareOrganizationTableData(response.data);
            preparedData = preparedData.sort((a, b) => {
                const isAsc = true;
                 return compareData(a.orgName, b.orgName, isAsc)
             });
            setAdminOrganizationsData(preparedData);
            setOrganizationsTableData(preparedData);
            setLoading(false);
          })
          .catch(e => {
            console.log(e);
            setLoading(false);
          });
    };

    const prepareOrganizationTableData = (data: Organizations[]): AdminOrganizationTable[] => {
        if (data) {
            return data.map(getOrganizationTableData);
        } else {
             return [];
        }
    }

    function getOrganizationTableData(data: Organizations) {
        let adminManageConsentTableData :AdminOrganizationTable =  { 
            orgId: data.orgId, 
            orgName: data.orgName,
            orgStatus: data.orgStatus,
            updateDt: '',
            action: 'Review'
        }
        if (data.updateDt) {
            adminManageConsentTableData['updateDt'] = data.updateDt;
        } else if (data.createDt) {
            adminManageConsentTableData['updateDt'] = data.createDt;
        }
        if (adminManageConsentTableData.orgStatus) {
            statusDropdownChoices.forEach(statusDropDown => {
                if (statusDropDown.value == adminManageConsentTableData.orgStatus) {
                    adminManageConsentTableData.orgStatus = statusDropDown.label;
                }
                
            });
        }
        return adminManageConsentTableData;
    }

    const adminOrganizationHeaders:MinTableHeader[] = [
        {
            id: "orgId",
            name: "Organization Id",
            type: "link"
        },
        {
            id: "orgName",
            name: "Organization Name",
            type: "text",
            sort: true,
        },
        {
            id: "orgStatus",
            name: "Status",
            type: "text"
        },
        {
            id: "updateDt",
            name: "Last Updated Date",
            type: "text",
            sort: true,
        },
        {
            id: "action",
            name: "Actions",
            type: "button"
        }
    ]

    
    const handleButtonFunctionality = (res: any) => {
        if (res && res.data) {
            if (res.header) {
                if (res.header.type == "button") {
                    setOpenAdminOrganizationReviewPopup(true);
                    setOrganizationReviewData(res.data.orgId);
                } else if (res.header.type == "link") {
                    setOpenAdminOrganizationPagePopup(true);
                    setAdminOrganizationPageData(res.data.orgId);
                }
            }
            console.log(res.data)
           // getOrganizations(res.data.orgId)
        } 
    };

    const handleSortingFunctionality = (res: any) => {
        console.log("sorting res", res);
        let filteredOrganizationsList = adminOrganizationsData.filter((item) => item.orgName.toLowerCase().includes(searchField.toLowerCase()));
        filteredOrganizationsList = filteredOrganizationsList.sort((a, b) => {
            const isAsc = res.sortOrdering === 'ASC';
            if (res.sortColumnId == 'updateDt') {
                return compareData(a.updateDt, b.updateDt, isAsc);
            } else {
                return compareData(a.orgName, b.orgName, isAsc);
            }
         });
        setOrganizationsTableData(Object.create(filteredOrganizationsList));
        
    }

    // const getOrganizations = (orgId: any): void => {
    //     console.log("org parsing data", orgId)
    //     OrganizationService.get(orgId)
    //       .then(response => {
    //         console.log(response.data);
    //         setConfirmModalData(response.data);
    //       })
    //       .catch(e => {
    //         console.log(e);
           
    //       });
    // };

    const handleOrganizationPageCloseBtn = () => {
        setAdminOrganizationPageData(null);
        setOpenAdminOrganizationPagePopup(false);
    };

    const handleOrganizationPageConfirmBtn = () => {
        setAdminOrganizationPageData(null);
        setOpenAdminOrganizationPagePopup(false);
    }

    const handleReviewCloseBtnConfirmModal = () => {
        setOrganizationReviewData(null);
        setOpenAdminOrganizationPagePopup(false);
    };

    const handleReviewConfirmBtnModal = (data: any) => {
        console.log ("data", data)
        setOrganizationReviewData(null);
        setOpenAdminOrganizationPagePopup(false);
        getOrganizationsList();
        
    };

    const searchOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("event", event.target.value)
        setSearchField(event.target.value);
        let filteredOrganizationsList = adminOrganizationsData.filter((item) => item.orgName.toLowerCase().includes(event.target.value.toLowerCase()));
        filteredOrganizationsList = filteredOrganizationsList.sort((a, b) => {
            const isAsc = true;
             return compareData(a.orgName, b.orgName, isAsc)
         });
        setOrganizationsTableData(Object.create(filteredOrganizationsList));
    };

    const compareData = (a: number | string, b: number | string, isAsc: boolean) => { 
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    return (
        
        <React.Fragment>
            <QuestionPage
                title= "Organizations list"
                hideFeatureName = {true}
                hideSubmitBtn = {true}
                callAdminSigout = {true}
                showLogoutBtn = {true}
                formClass =""
            >
                { !loading && <InputText
                    name="search_medicaid"
                    onChange={searchOrganizationChange}
                    type="text"
                    formGroupClassName=" float_right margin-top-0 margin-bottom-20 "
                    inputPrefix="Search"
                /> }
                { !loading && <CustomTable
                    headers={adminOrganizationHeaders}
                    items={organizationsTableData}
                    tableButton={handleButtonFunctionality}
                    sortingButton={handleSortingFunctionality}
                    defaultSortColId = "orgName"
                /> }

                { !loading && organizationReviewData && <AdminOrganizationReviewPage
                    showPopup={openAdminOrganizationReviewPopup}
                    orgId = {organizationReviewData}
                    agreeBtnOnClick={handleReviewConfirmBtnModal}
                    closeBtnOnClick = {handleReviewCloseBtnConfirmModal}
                /> }
                { !loading && adminOrganizationPageData && <OrganizationModal
                    orgId = {adminOrganizationPageData}
                    showPopup={openAdminOrganizationPagePopup}
                    agreeBtnOnClick={handleOrganizationPageCloseBtn}
                    closeBtnOnClick = {handleOrganizationPageConfirmBtn}
                /> }
                <BeatLoader loading = {loading} cssOverride={override}/>     
            
            </QuestionPage>
        </React.Fragment>
    );

}

 export interface AdminOrganizationTable extends MinTableItem
{
    orgId: string,
    orgName: string,
    orgStatus: string,
    updateDt: string,
    action: string
}

export default AdminOrganizationList;