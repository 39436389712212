import './b2c-signin.css'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import InputText from '../../components/InputText'
import Button from '../../components/Button'
import NavigationConstants from '../BenefitsApplication/shared/enums/navigation'
import { useHistory } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import CustomModal from '../Modal/CustomModal'
import { Typography } from '@mui/material'
import { Credentials } from '../model/Credentials'
import ErrorSummary from '../../components/ErrorSummary'

const B2CSignupPage = (props: any) => {
  const history = useHistory()
  const [localState, setloginRepState] = useState<Credentials>({
    userName: '',
    password: '',
  })
  const [openAgreementModal, setOpenAgreementModal] = useState(false)
  const [isShowPassword, setShowPassword] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [userNameError, setUserNameError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  sessionStorage.removeItem('access_token')
  sessionStorage.removeItem('orgId')
  sessionStorage.removeItem('token_type')
  sessionStorage.removeItem('member_id')
  sessionStorage.removeItem('isIdProofed')

  const routeChange = () => {
    // resetLoginErrorMsgs();

    // if (loginValidationFormWithErrorMsg()) {
    //     let data = {
    //         userName: localState.userName,
    //         password: localState.password
    //     }
    //     B2CSignInService.b2cSignIn(data)
    //     .then(response => {
    //         console.log(response.data);
    //         sessionStorage.setItem('access_token', response.data.access_token);
    //         sessionStorage.setItem('orgId', response.data.orgId);
    //         sessionStorage.setItem('token_type', response.data.token_type);
    //         const path = NavigationConstants.UserRegistrationLandingPage;
    //         history.push(path);

    //       })
    //       .catch(e => {
    //         setLoginError(e.message);
    //         console.log(e.message);
    //       });
    // }

    const path = NavigationConstants.UserAuthorizedLandingPage
    history.push(path)
  }

  const signupRoute = () => {
    const path = NavigationConstants.OrganizationUserSecurityHomePage
    history.push(path)
  }

  const handleCloseBtnAgreementModal = () => {
    setOpenAgreementModal(false)
  }

  const showPassword = () => {
    setShowPassword(!isShowPassword)
  }
  const handleOpenAgreementModal = () => {
    setOpenAgreementModal(true)
  }
  const handleAdditionalChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setloginRepState({
      ...localState,
      [event.target.name]: event.target.value,
    })
  }

  const resetLoginErrorMsgs = () => {
    setLoginError('')
    setUserNameError('')
    setPasswordError('')
  }

  const loginValidationFormWithErrorMsg = () => {
    let isFormValid = true

    if (!localState.userName) {
      setUserNameError('Please enter User Name field')
      isFormValid = false
    }

    if (!localState.password) {
      setPasswordError('Please enter Password field')
      isFormValid = false
    }

    return isFormValid
  }

  const errorMessages = [loginError, userNameError, passwordError]

  // TO-DO Handle error messages
  const cleanedErrorMsgs = (errorMessages || []).filter((msg) => !!msg)
  return (
    <React.Fragment>
      <div>
        <BackButton />
      </div>
      <Box
        sx={{ m: 20, border: 7, borderColor: '#e7e7e7', borderRadius: '26px' }}
      >
        <Box sx={{ m: 6 }}>
          <Typography variant="h4" gutterBottom component="div">
            Sign In
          </Typography>
          <div className="margin-top-5">
            {cleanedErrorMsgs.length > 0 && (
              <div className="margin-bottom-3 usa-form">
                <ErrorSummary errorMessages={cleanedErrorMsgs} />
              </div>
            )}
          </div>
          <InputText
            context="b2cSignIn"
            name="userName"
            label="User Name"
            value={localState?.userName}
            onChange={handleAdditionalChange}
            // errorMsg={orgNameErrorMsg}
          />
          <InputText
            context="b2cSignIn"
            name="password"
            label="Password"
            value={localState?.password}
            type={isShowPassword ? 'text' : 'password'}
            onChange={handleAdditionalChange}
            // errorMsg={orgNameErrorMsg}
          />
          <Box>
            <a href="#" onClick={showPassword}>
              Show Password
            </a>
          </Box>
          <Button
            className="margin-top-3 margin-bottom-2"
            type="submit"
            name="login"
            onClick={routeChange}
          >
            Sign In
          </Button>
          {/* <Box>
                        <a href="#" >Forgot Password?</a>
                    </Box>  */}
          {/* <br/>
                    <Box>
                       Don't have an account? <a href="#" onClick={handleOpenAgreementModal}>Create your account</a>
                    </Box> */}
          {/* <Button
                        className="margin-top-4 margin-bottom-6"
                        type="submit"
                        name="signup"
                        // onClick={signupRoute}
                        onClick = {handleOpenAgreementModal}
                    >Sign Up
                    </Button> */}
          <CustomModal
            showPopup={openAgreementModal}
            okButtonText="I Agree"
            headerText="Agreement"
            closeButtonText="Cancel"
            agreeBtnOnClick={signupRoute}
            closeBtnOnClick={handleCloseBtnAgreementModal}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}
export default B2CSignupPage
