import { any } from "prop-types";
import axios from 'axios';
import {getBaseApiUrl} from "../../utils/url-constant";


const createApp = (data: any) => {

    const access_token = sessionStorage.getItem('access_token');
    const token_type = sessionStorage.getItem('token_type');

    let url = getBaseApiUrl() + "/application/registration";    
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
    axios.defaults.headers.common['Authorization'] = token_type + " " + access_token;

   return axios.post(`${url}`, data);
        
}

const updateApplication = (data: any) => {

    const access_token = sessionStorage.getItem('access_token');
    const token_type = sessionStorage.getItem('token_type');

    let url = getBaseApiUrl() + "/admin/application/approval";
    
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
    axios.defaults.headers.common['Authorization'] = token_type + " " + access_token;;

   return axios.post(`${url}`, data);
        
}

const getApplicationsByOrgId = (orgId: any) => {

    const access_token = sessionStorage.getItem('access_token');
    const token_type = sessionStorage.getItem('token_type');

    let url = getBaseApiUrl() + "/getAllApplications/" + orgId;    
    axios.defaults.baseURL = '';
    const customHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token_type + " " + access_token,
        'X-Api-Key': process.env.REACT_APP_X_API_KEY,
      };
   return axios.get(`${url}`,  { headers: customHeaders });  
        
}

const getAllApplications = () => {
    const access_token = sessionStorage.getItem('access_token');
    const token_type = sessionStorage.getItem('token_type');

    const url = getBaseApiUrl() + "/getAllApplications";    

    const customHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token_type + " " + access_token,
        'X-Api-Key': process.env.REACT_APP_X_API_KEY,
      };
   return axios.get(`${url}`,  { headers: customHeaders });      
}

const getApplication = (id:any) => {

    const access_token = sessionStorage.getItem('access_token');
    const token_type = sessionStorage.getItem('token_type');

    let url = getBaseApiUrl() + `/getApplication/${id}`
       
     axios.defaults.baseURL = '';
     const customHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token_type + " " + access_token,
        'X-Api-Key': process.env.REACT_APP_X_API_KEY,
      };

     return axios.get(`${url}`,  { headers: customHeaders });  
};

const getCredentials = (orgId:any, appId:any) => {

    const access_token = sessionStorage.getItem('access_token');
    const token_type = sessionStorage.getItem('token_type');
    let url = getBaseApiUrl() + "/app/credentials/" + orgId + "/" + appId;
       
     axios.defaults.baseURL = '';
     const customHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token_type + " " + access_token,
        'X-Api-Key': process.env.REACT_APP_X_API_KEY,
      };

    return axios.get(`${url}`,  { headers: customHeaders });  
};

const getApicProducts = () => {
    const access_token = sessionStorage.getItem('access_token');
    const token_type = sessionStorage.getItem('token_type');
    let url = getBaseApiUrl() + "/getApicProducts";
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
    axios.defaults.headers.common['Authorization'] = token_type + " " + access_token;;

   return axios.get(`${url}`);
};


const AppRegistrationService = {
    createApp,
    getApicProducts,
    getApplicationsByOrgId,
    getAllApplications,
    updateApplication,
    getApplication,
    getCredentials
    
};
export default AppRegistrationService;

