import "./CustomModal.css";
import Button from "../../components/Button";
import verify from '../../img/verify.png';
import Avatar from '@mui/material/Avatar';



interface CustomModalProps {

    showPopup: boolean,
    okButtonText?: string
    closeButtonText?: string
    headerText?: string
    messageText?: string
    idmeInd?: string
    agreeBtnOnClick?: React.MouseEventHandler;
    closeBtnOnClick?: React.MouseEventHandler;

}

function CustomModal(props: CustomModalProps) {

    return (
        <>
            {(props && props.showPopup && (
                <>
                    <div className="OverlayContainer">
                        <div
                            className={"OverlayMainContainer"}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="container">
                                <div className={"ModalHeader row"}>
                                    <div className="col-9">
                                        <h2>{props.headerText || "Confirmation"}</h2>
                                    </div>
                                    <div className="col-3">
                                        <Button onClick={props.closeBtnOnClick} className="usa-button CloseButton" variation="outline" >
                                            {props.closeButtonText || "Close"}
                                        </Button>
                                    </div>
                                </div>
                                <hr className="hr-line" />
                                <div className={"ModalText"}>
                                    {props.messageText && (<>
                                        <p>{props.messageText}</p>
                                    </>)
                                    }
                                    {!props.messageText && (<>
                                        <p>
                                            <a href="https://sadhhscmsiprd01.blob.core.windows.net/identity/TC.html" target="_blank" rel="noopener noreferrer">
                                            Please review the privacy policy and understand the terms and conditions for the Interoperability application, before clicking I Agree
                                            </a>
                                        </p>
                                    </>
                                    )}
                                    {props.idmeInd && (
                                        <div style={{ marginLeft: '800px', cursor: 'pointer' }} > <img className="idMeCss" src={verify} onClick={props.agreeBtnOnClick} /> </div>
                                    )
                                    }
                                    {!props.idmeInd && (<>
                                        <Button className="OkButton" onClick={props.agreeBtnOnClick}> {props.okButtonText || "OK"} </Button>
                                    </>)}

                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )) ||
                null}
        </>
    );
}

export default CustomModal;
