import React, { useState, CSSProperties } from "react";
import QuestionPage from "../../templates/QuestionPage";
import InputText from "../../components/InputText";
import { getPhoneNumberErrorMsg, getClientTosErrorMsg, getSftNameErrorMsg, getSftVersionErrorMsg, getSupportEmailErrorMsg, getDescriptionErrorMsg, getAppSupportErrorMsg } from "../../utils/errorMessageUtil";
import PhoneNumber from "../BenefitsApplication/shared/components/phoneNumber";
import AppRegistrationService from "./AppRegistrationService"
import { Box, Typography } from "@mui/material";
import Button from "../../components/Button";
import { useHistory } from "react-router";
import { pageScrollToTop } from "../../../src/utils/pageScrollToTop"
import NavigationConstants from "../BenefitsApplication/shared/enums/navigation";
import "../explore-apis/exploreapi.css";
import BeatLoader from "react-spinners/BeatLoader";
import OrganizationModal from "../admin-organization-list/admin-organization-modal";



const AppRegistrationStep1 = () => {

    const history = useHistory();

    const orgId = sessionStorage.getItem('orgId');
    if (!orgId) {
        const path = NavigationConstants.AppSignInPage;
        history.push(path);
    }

    const [registrationStep, setRegistrationStep] = useState(1);
    const [loading, setLoading] = useState(false);

    const override: CSSProperties = {
        marginTop: "20%",
        marginLeft: "50%"
    };

    const [localState, setAppRegistrationStep1RepState] = useState<AppRegistrationStep1RepresentativeState>(initializeAppRegistrationStep1RepState());
    const [sftVersionErrorMsg, setSftVersionError] = useState("");
    const [phoneNumberErrorMsg, setPhoneNumberError] = useState("");
    const [appSupportUrlErrorMsg, setappSupportUrlErrorMsg] = useState("");
    const [SftNameErrorMsg, setSftNameError] = useState("");
    const [supportEmailErrorMsg, setSupportEmailError] = useState("");
    const [clientTosUriMsg, setClientTosUriError] = useState("");
    const [appDescErrorMsg, setAppDescError] = useState("");
    const [apiErrorMsg, setApiErrorMsg] = useState("");
    const [apicProductsList, setApicProductsList] = useState([]);
    const [adminOrganizationPageData, setAdminOrganizationPageData] = useState<any>(orgId);
    const [openAdminOrganizationPagePopup, setOpenAdminOrganizationPagePopup] = useState(false);

    function paddingDigits(gvn_number: any) {
        return gvn_number.toString().padStart(2, '0');
    }

    function formattingDate(gvn_date: Date) {
        return [
            gvn_date.getFullYear(),
            paddingDigits(gvn_date.getMonth() + 1),
            paddingDigits(gvn_date.getDate())
        ].join('-');
    }
    const saveFormAppRegistration = (apicProduct: any) => {

        var phoneNumber = localState.phoneNumber.replace(/[^\w]/gi, '')
        let todayDate = formattingDate(new Date());

        let appObj = {
            appId: "",
            orgId: orgId,
            producName: apicProduct.name,
            productDesc: apicProduct.summary,
            productId: apicProduct.id,
            subscribeDt: todayDate,
            appNum: "0",
            appName: localState.sftName,
            appStatusCd: "IR",
            appVersion: localState.sftVersion,
            appDesc: localState.applicationDesc,
            appSptEmail: localState.supprotEmail,
            appSptPhone: phoneNumber,
            appSptUrl: localState.appSupportUrl
        }
        setLoading(true);
        AppRegistrationService.createApp(appObj)
            .then(response => {
                console.log(response.data);
                setLoading(false);
                routeChange();
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.data && error.response.data.message) {
                    setApiErrorMsg(error.response.data.message);
                }
            });
    };

    const getApicProducts = () => {
        setLoading(true);
        AppRegistrationService.getApicProducts()
            .then(response => {
                console.log(response.data.results);
                setApicProductsList(response.data.results);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.data && error.response.data.message) {
                    setApiErrorMsg(error.response.data.message);
                }
            });
        setRegistrationStep(2);
    }



    const signupRoute = (apicProduct: any) => {
        saveFormAppRegistration(apicProduct);
    }

    const handleSave = (event: React.FormEvent<HTMLInputElement>) => {
        console.log("event", event, localState);
        event.preventDefault();
        if (registrationStep === 1) {
            resetStep1ErrorMsgs();
            if (step1ValidationFormWithErrorMsg()) {
                // setRegistrationStep(2);
                getApicProducts();
            }
        }
        pageScrollToTop();
    };

    const resetStep1ErrorMsgs = () => {
        setSftVersionError("");
        setPhoneNumberError("");
        setSftNameError("");
        setSupportEmailError("");
        setClientTosUriError("");
        setAppDescError("");
        setApiErrorMsg("");
    };

    const step1ValidationFormWithErrorMsg = () => {
        let isFormValid = true;

        if (!localState.sftName) {
            setSftNameError(getSftNameErrorMsg("your"));
            isFormValid = false;
        }

        if (!localState.sftVersion) {
            setSftVersionError(getSftVersionErrorMsg("your"));
            isFormValid = false;
        }

        if (!localState.applicationDesc) {
            setAppDescError(getDescriptionErrorMsg("your"));
            isFormValid = false;
        }

        if (!localState.phoneNumber) {
            setPhoneNumberError(getPhoneNumberErrorMsg("your"));
            isFormValid = false;
        }

        if (!localState.clientTosUrl) {
            setClientTosUriError(getClientTosErrorMsg("your"));
            isFormValid = false;
        }

        if (!localState.supprotEmail) {
            setSupportEmailError(getSupportEmailErrorMsg("your"));
            isFormValid = false;
        }

        if (!localState.appSupportUrl) {
            setappSupportUrlErrorMsg(getAppSupportErrorMsg("your"));
            isFormValid = false;

        }

        return isFormValid;
    };


    const handleAdditionalChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setAppRegistrationStep1RepState({
            ...localState,
            [event.target.name]: event.target.value,
        });
    };


    const errorMessagesStep1 = [
        sftVersionErrorMsg,
        phoneNumberErrorMsg,
        SftNameErrorMsg,
        supportEmailErrorMsg,
        clientTosUriMsg,
        appDescErrorMsg,
        apiErrorMsg
    ];

    const moveToBackStep = () => {
        if (registrationStep === 2) {
            setRegistrationStep(1);
        } else if (registrationStep === 1) {
            history.goBack();
        }
    };

    const routeChange = () => {
        const path = "/appFeedbackPg";
        history.push(path);
    }

    const handleOrganizationPageCloseBtn = () => {
        setOpenAdminOrganizationPagePopup(false);
    };

    const handleOrganizationPageConfirmBtn = () => {
        setOpenAdminOrganizationPagePopup(false);
    }

    return (
        <React.Fragment>
            {adminOrganizationPageData && <OrganizationModal
                orgId={adminOrganizationPageData}
                showPopup={openAdminOrganizationPagePopup}
                agreeBtnOnClick={handleOrganizationPageCloseBtn}
                closeBtnOnClick={handleOrganizationPageConfirmBtn}
            />}

            <QuestionPage
                title={`App Registration Step ${registrationStep}`}
                onSave={handleSave}
                errorMessages={errorMessagesStep1}
                hideFeatureName={true}
                hideSubmitBtn={registrationStep === 2 ? true : false}
                buttonText="Continue"
                backBtnOnClick={moveToBackStep}
                showLogoutBtn={true}
            >
                <a onClick={() => setOpenAdminOrganizationPagePopup(true)} style={{cursor:'pointer'}}>Organization Details</a>

            {registrationStep === 1 &&
                <>
                    <InputText
                        context="appRegistrationStep1Rep"
                        name="sftName"
                        label="Application Name"
                        value={localState.sftName}
                        onChange={handleAdditionalChange}
                        errorMsg={SftNameErrorMsg}
                        mandatoryLabel={true}
                    />

                    <InputText
                        context="appRegistrationStep1Rep"
                        name="sftVersion"
                        label="Application Version"
                        value={localState.sftVersion}
                        onChange={handleAdditionalChange}
                        errorMsg={sftVersionErrorMsg}
                        mandatoryLabel={true}
                    />

                    <InputText
                        context="appRegistrationStep1Rep"
                        name="applicationDesc"
                        label="Description"
                        value={localState.applicationDesc}
                        onChange={handleAdditionalChange}
                        errorMsg={appDescErrorMsg}
                        mandatoryLabel={true}
                    // type="textarea"
                    />

                    <InputText
                        context="appRegistrationStep1Rep"
                        name="supprotEmail"
                        label="Support Email"
                        value={localState.supprotEmail}
                        onChange={handleAdditionalChange}
                        errorMsg={supportEmailErrorMsg}
                        mandatoryLabel={true}
                    />

                    <PhoneNumber
                        context="appRegistrationStep1Rep"
                        name="phoneNumber"
                        label="Support Phone Number"
                        value={localState.phoneNumber}
                        onChange={handleAdditionalChange}
                        errorMsg={phoneNumberErrorMsg}
                        mandatoryLabel={true}
                    />

                    <InputText
                        context="appRegistrationStep1Rep"
                        name="appSupportUrl"
                        label="App Support URL"
                        value={localState.appSupportUrl}
                        onChange={handleAdditionalChange}
                        errorMsg={appSupportUrlErrorMsg}
                        mandatoryLabel={true}
                    />

                    <InputText
                        context="appRegistrationStep1Rep"
                        name="clientTosUrl"
                        label="Client Terms of Service URL"
                        value={localState.clientTosUrl}
                        onChange={handleAdditionalChange}
                        errorMsg={clientTosUriMsg}
                        mandatoryLabel={true}
                    />
                </>

            }
            {registrationStep === 2 &&
                <>
                    <p>
                        Please select which API will be used by this Application. Note, that one app can only subscribe to one API
                    </p>
                    {!loading &&
                        apicProductsList.map((apicProduct: any) => (apicProduct && <>
                            <br />
                            <Box sx={{ width: '100%', maxWidth: 800 }}>
                                <Typography sx={{ color: 'primary.main' }} variant="h5" gutterBottom component="div">
                                    {apicProduct.name}
                                </Typography>
                                <div id={apicProduct.id}>
                                    <div id="column1">
                                        <Typography variant="h6" gutterBottom component="div">
                                            {apicProduct.summary}
                                        </Typography>
                                    </div>
                                    <div id="column2">
                                        <Button
                                            type="submit"
                                            name="signup"
                                            onClick={() => signupRoute(apicProduct)}
                                        >Subscribe
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                        </>
                        ))
                    }
                    <BeatLoader loading={loading} cssOverride={override} />
                </>
            }
        </QuestionPage>
        </React.Fragment >
    );
};
export default AppRegistrationStep1;

export interface AppRegistrationStep1RepresentativeState {
    sftName: string;
    sftVersion: string;
    redirUrl: string;
    clientPolicyUri: string;
    supprotEmail: string;
    softwareId: string;
    appType: string;
    clientTosUrl: string;
    secCertUrl: string;
    applicationDesc: string;
    phoneNumber: string;
    appSupportUrl: string;
}

export const initializeAppRegistrationStep1RepState = () => {
    return {
        sftName: "",
        sftVersion: "",
        redirUrl: "",
        clientPolicyUri: "",
        softwareId: "",
        supprotEmail: "",
        appType: "",
        clientTosUrl: "",
        secCertUrl: "",
        applicationDesc: "",
        phoneNumber: "",
        appSupportUrl: ""
    } as AppRegistrationStep1RepresentativeState;
};

