import { any } from "prop-types";
import axios from 'axios';
import {getMemberLookupUrl, getMemberLookupClientId, getMemberLookupClientSecret} from "../../../utils/url-constant";

const getLookUpMember = (medicaidID:any, dateOfBirth:any, ssn:any) => {
    let url = getMemberLookupUrl();
    url = url + "?medicaidID=" + medicaidID + "&dateOfBirth=" + JSON.stringify([dateOfBirth].toString()).replaceAll(",","-").replaceAll(/["']/g, "")+ "&ssn=" + ssn;
      
    axios.defaults.baseURL = '';
    axios.defaults.headers.common['Content-type'] = "application/json";
    axios.defaults.headers.common['Accept'] = "*/*";
    axios.defaults.headers.common['X-IBM-Client-Id'] = getMemberLookupClientId();
    axios.defaults.headers.common['X-IBM-Client-Secret'] = getMemberLookupClientSecret();
   return axios.get(`${url}`)
        
}


const LookupMemberMedicaidIDService = {
  getLookUpMember
};
export default LookupMemberMedicaidIDService;

