enum NavigationConstants {
  EmptySamplePage = '/sample-empty-page',
  TableOfContents = '/table-of-contents',
  PageNotDevelopedYet = '/page-not-developed-yet',
  BenefitsDiscovery = '/benefit-discovery',
  LandingPage = '/',
  StartPage = '/start',
  ProgramSelection = '/program-select',
  ProgramSelectPrivacy = '/program-select-privacy',
  ApplicantName = '/applicant-name',
  LivingSituation = '/living-situation',
  HouseholdAddress = '/household-address',
  GeneralDelivery = '/general-delivery',
  MailingAddress = '/mailing',
  AuthorizedRep = '/appoint-authorize-rep',
  AuthorizedRepDetails = '/authorized-rep-details',
  AuthorizedRepScope = '/authorized-rep-scope',
  AuthorizedRepSuccess = '/authorized-rep-success',
  AuthorizedRepAddress = '/authorized-rep-address',
  ReviewApplication = '/review-application',
  TemporaryAddress = '/temporaryAddress',
  ApplicantHasHelp = '/applicant-has-help',
  ApplicantHasHelpDetails = '/applicant-has-help-details',
  ApplicantPhone = '/applicant-phone',
  MinimumSubmit = '/minimum-submit',
  WhereToReachYou = '/where-to-reach-you',
  EmergencyContacts = '/emergency-contacts',
  LanguagePreferences = '/language-preferences',
  BirthdaySex = '/date-of-birth-sex',
  AdditionalComments = '/additional-comments',
  VoterRegistration = '/voter-registration',
  EthnicityAndRace = '/ethnicity-race',
  LegalQuestons = '/legal-questions',
  ApplicantAgreements = '/agreements',
  WhoCompletedApp = '/who-completed-app',
  LocationApplying = '/location',
  OrganizationDetails = '/organization-details',
  SignatureCapture = '/sign-application',
  ApplicationComplete = '/application-complete',
  PreviousAddress = '/previous-address',
  SelfcareDisability = '/selfcare-disability',
  HealthCondition = '/health-condition',
  HouseholdMembers_MemberName = '/household-member-name',
  HouseholdResidencyStatus = '/household-citizenship-status',
  HouseholdResidencyStatusMembers = '/household-citizenship-members',
  HouseholdResidencyStatusDetail = '/household-citizenship-members-detail',
  Attend_School = '/attend-school',
  HouseholdMembers_TellUsAboutYour = '/household-member-tell-us-about',
  HouseholdMembers_WhoDoYouLiveWith = '/household-member-who-do-you-live-with',
  HouseholdMembers_BirthdaySexRelationship = '/household-member-birthday-sex-relationship',
  HouseholdMembers_Address = '/household-member-address',
  HouseholdMember_Other_Address = '/household-member-other-address',
  HouseholdMember_TemporaryAddress = '/household-member-temporary-address',
  HouseholdFoster_Care = '/household-foster-care',
  HouseholdFosterCare_Members = '/household-foster-care-members',
  HouseholdFosterCare_Members_With_Age = '/household-foster-care-members-with-age',
  BoarderQuestion = '/boarder-question',
  BoarderMembers = '/boarder-members',
  GovAssistenceQuestion = '/gov-assistence-question',
  Household_Where_Live_In_Nebraska = '/household-where-live-in-nebraska',
  Household_Address_In_Nebraska = '/household-address-in-nebraska',
  CaretakerStart = '/caretaker-lives-with',
  LiveWithTakeCareChildren = '/caretaker-takecare-children',
  AddAnotherChild = '/caretaker-add-another-child',
  CaretakerEnd = '/caretaker-additional-children',
  CaretakerRelationships = '/caretaker-relationships',
  CaretakerChild = '/caretaker-child',
  CaretakerChildren = '/caretaker-children',
  CaretakerParent = '/caretaker-parent',
  CaretakerHousehold = '/caretaker-household',
  CaretakerParentLiving = '/caretaker-parent-living',
  HelpPayingMedicalBills = '/help-paying-medical-bills',
  OnGoingMedicalCosts = '/on-going-medical-costs',
  FinalMedicalExpenses = '/final-medical-expenses',
  TotalGrossIncome = '/total-gross-income',
  CurrentJob = '/current-job',
  AboutJob = '/about-job',
  FoodAssistanceStart = '/food-assistance-cooperate-child-support',
  FoodAssistanceUseEbt = '/food-assistance-use-ebt-card',
  FoodAssistanceEnd = '/food-assistance-who-is-applying',
  FoodAssistanceEBT = '/food-assistance-ebt-card',
  MaritalStatus_TellUsAboutYour = '/marital-status-tell-us-about',
  MaritalStatus_Selection = '/marital-status-selection',
  MaritalStatus_Marriages = '/marital-status-marriages',
  MaritalStatus_Annulled = '/marital-status-annulled',
  MaritalStatus_Divorced = '/marital-status-divorced',
  MaritalStatus_Widowed = '/marital-status-widowed',
  B2CUse = '/b2c',
  MilitaryServiceQuestion = '/military-service-question',
  Address_In_Nebraska = '/applicant-address-in-nebraska',
  GovAssistanceReceivedDetails = '/gov-assistance-received-details',
  TaxInfo = '/tax-info',
  TaxDependentOther = '/tax-dependent-other',
  TaxFilingJointlySpouse = '/tax-filing-jointly',
  TaxClaimDependents = '/tax-claim-dependents',
  TaxClaimPage6 = '/tax-claim-page-6',
  TaxClaimPage7 = '/tax-claim-page-7',
  TaxClaimPage8 = '/tax-claim-page-8',
  TaxClaimPage9 = '/tax-claim-page-9',
  IsAnyonePregnant = '/is-anyone-pregnant',
  disabilityMembers = '/disability-members',
  disabilityMemberDetails = '/disability-member-details',
  whoDoYouRegularyPrepareAndPurchaseFood = '/regularly-prepare-and-purchase-food',
  disabilityByAccident = '/disability-by-accident',
  disabilityServices = '/disability-services',
  PregnancyDetails = '/pregnancy-details',
  TellUsAboutCitizenship = '/citizenship-tell-us-about',
  ChildcareQualification = '/childcare-qualification',
  TellUsAboutChildcareReason = '/childcare-tell-us-about-reason',
  ChildrenDevelopmentalScreenings = '/children-developmental-screenings-request',
  TellUsAboutChildcareProvider = '/childcare-provider-tell-us-about',
  ChildcareProviderAddition = '/add-childcare-provider',
  ChildcareProviderAdditionSuccess = '/add-childcare-provider-success',
  WhoWillFileTaxReturn = '/tax-who-will-file',
  TaxSpouseDetails = '/tax-spouse-details',
  HouseholdFleeingProsecution = '/household-fleeing-to-avoid-prosecution',
  WhenDidPersonFlee = '/when-did-flee',
  HouseholdViolationOfParole = '/hosehold-violation-of-probation-parole',
  WhenDidPersonViolateParole = '/when-did-violate-probation-parole',
  HouseholdConvictedOfPossession = '/household-convicted-of-possession',
  WhenWasPossessionConvicted = '/when-was-convicted-of-possession',
  HouseholdConvictedOfSnapFirearmExchange = '/household-convicted-of-snap-firearms-exchange',
  WhenWasSnapExchangeConvicted = '/when-was-convicted-of-snap-firearms-exchange',
  HouseholdConvictedOfFraudDuplicateSnap = '/household-convicted-of-fraudulently-duplicate-snap-benefits',
  WhenWasSnapFraudBenefitsConvicted = '/when-was-convicted-of-fraudulently-duplicate-snap-benefits',
  HouseholdGuiltyOfBuyingSnapBenefits = '/household-guilty-of-buying-selling-snap-benefits',
  WhenWasSnapBuyingSellingBenefitsGuilty = '/when-was-convicted-of-buying-selling-snap-benefits',
  ChildcareDependent = '/childcare-dependent',
  ChildcareExpenses = '/childcare-dependent-expenses',
  ChildcareAdultDependent = '/childcare-dependent-adult',
  ChildcareAdultCareExpenses = '/childcare-adult-care-expenses',
  ChildcareChildSupport = '/childcare-child-support',
  ChildcareChildSupportExpenses = '/childcare-child-support-expenses',
  HouseholdSocialSecurityNumbers = '/household-social-security-numbers',
  ExpensesPropertyHousing = '/expenses-property-housing',
  ExpensesHousingCurrentProperty = '/expenses-housing-current-property',
  ResourcesAccountsInvestment = '/resources-accounts-investment',
  TellUsAboutAccountsInvestment = '/tell-us-about-accounts-investment',
  ResourcesCash = '/resources-cash',
  StudentLoan = '/student-loan',
  StudentLoanInterest = '/student-loan-interest',
  StudentAlimony = '/student-alimony',
  StudentAlimonyPayment = '/student-alimony-payment',
  StudentTaxDeductible = '/student-tax-deductible',
  StudentOtherTaxDeductible = '/student-other-tax-deductible',
  TellUsAboutExpensesUtilites = '/expenses-utilites-tell-us-about',
  ExpensesUtilitesFunds = '/expenses-utilites-funds',
  ExpensesUtilitesHeatHome = '/expenses-utilites-heat-home',
  ExpensesUtilitesPowerHome = '/expenses-utilites-power-home',
  ExpensesUtilitesPayHome = '/expenses-utilites-pay-home',
  OwnVehicles = '/own-vehicles',
  HouseHoldVehicles = '/house-hold-vehicles',
  SoldAndTransfer = '/sold-and-transfer',
  AssetsHasSoldOrTransferred = '/assets-has-sold-transfer',
  HouseholdMisrepresentedIdForBenefits = '/household-misrepresented-id-for-benefits',
  WhenWasNameMisrepresentedForBenefits = '/when-was-name-misrepresented-for-Benefits',
  HouseholdConvictedForTradingDrugs = '/household-convicted-for-trading-drugs',
  WhenWasNameConvictedForTradingDrugs = '/when-was-name-convicted-for-trading-drugs',
  HouseholdConvictedForAnyFelony = '/household-convicted-for-any-felony',
  WhenWasNamesConvictedForAnyFelony = '/when-was-names-convicted-for-any-felony',
  HouseholdDisqualifiedForPrograms = '/household-disqualified-for-programs',
  WhichProgramsNameDisqualified = '/which-programs-name-disqualified',
  HouseholdFamilyMembers = '/household-family-members',
  TribeName = '/tribe-name',
  MoneyReceived = '/money-received',
  CommoditiesReceived = '/commodities-received',
  TaxDependentsClaimedOutsideHousehold = '/tax-dependents-claimed-outside-household',
  TaxDependentsClaimedOutsideMore = '/tax-dependents-claimed-outside-more',
  OrganizationUserSecurityHomePage = '/organization-user-security-registraion-steps',
  OrgFeedbackPage = '/orgFeedbackPg',
  CmsInteropHomePage = '/cms-interop-home',
  AppownerLandingPage = '/appOwnerLandingHome',
  UserRegistrationLandingPage = '/userLandingHome',
  UserAuthorizedLandingPage = '/userAuthorizedLandingHome',
  AppFeedbackPage = '/appFeedbackPg',
  ApiLandingPage = '/apiLandingPage',
  AppSignInPage = '/appSignIn',
  B2CSignInPage = '/b2cSignIn',
  ExploreApis = '/exploreApis',
  ApplicationStatus = '/appStatus',
  APIDetails = '/apiDetails',
  AppRegstep1 = '/appRegSteps',
  AdminLandingPage = '/adminLandingPage',
  AdminSignInLandingPage = '/adminSignInLandingPage',
  // AdminSignInPage = "/adminSignInPage",
  AdminAppStatusList = '/adminAppStatusList',
  AdminManageConsentList = '/adminManageConsentList',
  AdminOrganizationList = '/adminOrganizationList',
  UserDigitalRegistration = '/registration',
  UserManageConsentList = '/userManageConsentList',
  // missing links
  AnotherIncome = '/anotherIncome',
  IncomeSumPayment = '/income',
}

export default NavigationConstants
