import React, { useState, useEffect } from "react";
import QuestionPage from "../../templates/QuestionPage";
import CustomTable, {MinTableItem, MinTableHeader} from "../../components/CustomTable";
import AdminManageConsentRevokePage from "./admin-manage-consent-revoke-page";
import InputText from "../../components/InputText";
import ManageConsentService  from  "./service/ManageConsentService";
import { ManageConsent } from "./model/ManageConsent";
import NavigationConstants from "../BenefitsApplication/shared/enums/navigation";
import { useHistory } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import BackButton from "../../components/BackButton";
import { AuthorizationComponent } from "../admin-landing-page/authorization-component";


const AdminManageConsentListPage = () => {

    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState<any>(null);
    const [confirmModalData, setConfirmModalData] = useState<any>(null);
     const [adminManageConsentData, setAdminManageConsentData] = useState<AdminManageConsentTable[]>([]);
    const [adminManageConsentTableData, setAdminManageConsentTableData] = useState<AdminManageConsentTable[]>([]);

    const history = useHistory();       
    const goToAdminLoginPage = () => {
        const path = NavigationConstants.AdminSignInLandingPage;
         history.push(path);
     }
    useEffect(() => {
        getAdminManageConsentList();
    }, []);

    const getAdminManageConsentList = () => {
        ManageConsentService.getAllConsentList()
          .then(response => {
            console.log("api",response.data);
            const filteredManageConsent = response.data.filter((item: any) => item.status == 'A');
            let preparedData = prepareAdminManageConsentTableData(filteredManageConsent)
            setAdminManageConsentData(preparedData);
            console.log(preparedData)
            setAdminManageConsentTableData(preparedData);
          })
          .catch(e => {
            console.log("error", e);
          });
    };

    const prepareAdminManageConsentTableData = (data: ManageConsent[]): AdminManageConsentTable[] => {
        if (data) {
            return data.map(getAdminManageConsentTableData);
        } else {
             return [];
        }
    }

    function getAdminManageConsentTableData(data: ManageConsent) {
        let adminManageConsentTableData :AdminManageConsentTable =  { 
            id: data.id, 
            appName: data.appName.split("-appId:")[0], 
            orgName: data.orgName,
            status: data.status,
            memberId: data.memberId,
            medicaidId: data.medicaidId,
            updateDt: '',
            action: 'Revoke',
            appId: data.appName.split("-appId:")[1],
            updateBy: data.updatedBy
        }
        if (data.updateDt) {
            adminManageConsentTableData['updateDt'] = data.updateDt;
        } else if (data.createDt) {
            adminManageConsentTableData['updateDt'] = data.createDt;
        }
        return adminManageConsentTableData;
    }

    const adminManageConsentHeaders:MinTableHeader[] = [        
        {
            id: "id",
            name: "ID",
            type: "text",
            isHidden: true
        },
        {
            id: "memberId",
            name: "Member Id",
            type: "text",
            isHidden: true
        },
        {
            id: "medicaidId",
            name: "Medicaid ID",
            type: "text"
        },
        {
            id: "appId",
            name: "Application ID",
            type: "text"
        },    
        {
            id: "appName",
            name: "Application Name",
            type: "text"
        },
        {
            id: "orgName",
            name: "Organization",
            type: "text"
        },
        {
            id: "updateDt",
            name: "Last Updated Date",
            type: "text"
        },
        {
            id: "action",
            name: "Actions",
            type: "button"
        }
    ]

    const searchMedicaidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log("event", event.target.value)
      const filteredManageConsent = adminManageConsentData.filter((item) => item.medicaidId.includes(event.target.value));
      setAdminManageConsentTableData(Object.create(filteredManageConsent));
    };

    const handleButtonFunctionality = (item: any) => {
        let message = "The Application ";
        message = message + item.data.appName.split("-appId:")[0] + ",with Application Id: " + item.data.appId+ ", "
        message = message + "will no longer be allowd access to your medicaid Data";
        setConfirmModalMessage(message);
        setConfirmModalData(item.data);
        setOpenConfirmModal(true);
    };

    const handleCloseBtnConfirmModal = () => {
        setConfirmModalMessage(null);
        setConfirmModalData(null);
        setOpenConfirmModal(false);
    };

    const handleAgreeBtnConfirmModal = () => {
        setConfirmModalMessage(null);
        setOpenConfirmModal(false);
        if (confirmModalData && confirmModalData.id) {
            getAdminManageConsentList();
            setConfirmModalData(null);
        } 
    };

    const AdminMangeConsentListPageComponent = () =>
        <React.Fragment>
          <AuthenticatedTemplate>
            <QuestionPage
                title= "Admin Manage Consent"
                hideFeatureName = {true}
                hideSubmitBtn = {true}
                callAdminSigout = {true}
                showLogoutBtn = {true}
                formClass =""
            >
                <InputText
                    name="search_medicaid"
                    onChange={searchMedicaidChange}
                    type="text"
                    formGroupClassName=" float_right margin-top-0 margin-bottom-20 "
                    // inputPrefix="Search"
                    placeholder="Search with Medicaid Id"
                />

                <CustomTable
                    headers={adminManageConsentHeaders}
                    items={adminManageConsentTableData}
                    tableButton={handleButtonFunctionality}
                    
                />
                <AdminManageConsentRevokePage
                    showPopup={openConfirmModal}
                    agreeBtnOnClick={handleAgreeBtnConfirmModal}
                    closeBtnOnClick = {handleCloseBtnConfirmModal}
                    messageText = {confirmModalMessage}
                    data = {confirmModalData}
                />
            </QuestionPage>
            </AuthenticatedTemplate>

             <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to access the Admin Content</h5> 
                <BackButton buttonClassName="subheading-backbutton" onClick={goToAdminLoginPage}/>
             </UnauthenticatedTemplate>
        </React.Fragment>

    const CmsiAuthorizedComponent = AuthorizationComponent(AdminMangeConsentListPageComponent);

    return (
        <CmsiAuthorizedComponent>
        </CmsiAuthorizedComponent>
    );
};

interface AdminManageConsentTable extends MinTableItem
{
    checkbox?: string,
    status: string,
    memberId: string,
    medicaidId: string,
    id: number, // consent Id
    appName: string,
    orgName: string,
    updateDt: string,
    action: string
    appId: string,
    updateBy: string
}

export default AdminManageConsentListPage;