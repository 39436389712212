import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        //for some reason, window.scrollTo doesn't work with history.goBack()
        //and doesn't work consistently with browser's back button
        //document.documentElement.scrollTo seems to resolve this.
        //window.scrollTo(0,0);
        document.documentElement.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;
