import axios from 'axios';
import {getBaseApiUrl} from "../../../utils/url-constant";


const get = (id:any) => {
  let url = getBaseApiUrl() + "/registration/" + id;
  axios.defaults.baseURL = '';
  axios.defaults.headers.common['Content-Type'] = "application/json";
  axios.defaults.headers.common['Accept'] = "*/*";
 return axios.get(`${url}`);
};

const updateB2cFHIRMemberId =(medicaidId: any, memberId: any) => {
  let url = getBaseApiUrl() + "/update/b2c/graphql/" + medicaidId  + "/y/" + memberId + "/false/true" ;    
  axios.defaults.baseURL = '';
  axios.defaults.headers.common['Content-Type'] = "application/json";
  axios.defaults.headers.common['Accept'] = "*/*";
  return axios.post(`${url}`);
}


const DigitalRegistrationService = {
  get,
  updateB2cFHIRMemberId
};
export default DigitalRegistrationService;