import Dropdown from "./Dropdown";

/**
 * Dropdown of Nebraska counties
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/templates/form-templates/address-form/)
 */
function CountyDropdown(props: CountyDropdownProps) {
    return <Dropdown choices={countyDropdownChoices} {...props} />;
}

interface CountyDropdownProps {
    /**
     * Localized label for the initially selected option when no value is set
     */
    emptyChoiceLabel: string;
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Localized hint text
     */
    hint?: React.ReactNode;
    /**
     * Localized label
     */
    label: React.ReactNode;
    /**
     * HTML input `name` attribute
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * HTML input `onChange` attribute
     */
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    /**
     * Localized text indicating this field is optional
     */
    optionalText?: React.ReactNode;
    /** The `value` of the selected choice */
    value: React.InputHTMLAttributes<HTMLInputElement>["value"];

    formGroupInputClasses?: string;

    disabled?:boolean;
    mandatoryLabel?: boolean;
}

// All counties of Nebraska state.
const countyDropdownChoices = [
    // {
    //     label: "Out of State",
    //     value: "Out of State",
    // },
    {
        label: "Adams",
        value: "Adams",
    },
    {
        label: "Antelope",
        value: "Antelope",
    },
    {
        label: "Arthur",
        value: "Arthur",
    },
    {
        label: "Banner",
        value: "Banner",
    },
    {
        label: "Blaine",
        value: "Blaine",
    },
    {
        label: "Boone",
        value: "Boone",
    },
    {
        label: "Box Butte",
        value: "Box Butte",
    },
    {
        label: "Boyd",
        value: "Boyd",
    },
    {
        label: "Brown",
        value: "Brown",
    },
    {
        label: "Buffalo",
        value: "Buffalo",
    },
    {
        label: "Burt",
        value: "Burt",
    },
    {
        label: "Butler",
        value: "Butler",
    },
    {
        label: "Cass",
        value: "Cass",
    },
    {
        label: "Cedar",
        value: "Cedar",
    },
    {
        label: "Chase",
        value: "Chase",
    },
    {
        label: "Cherry",
        value: "Cherry",
    },
    {
        label: "Cheyenne",
        value: "Cheyenne",
    },
    {
        label: "Clay",
        value: "Clay",
    },
    {
        label: "Colfax",
        value: "Colfax",
    },
    {
        label: "Cuming",
        value: "Cuming",
    },
    {
        label: "Custer",
        value: "Custer",
    },
    {
        label: "Dakota",
        value: "Dakota",
    },
    {
        label: "Dawes",
        value: "Dawes",
    },
    {
        label: "Dawson",
        value: "Dawson",
    },
    {
        label: "Deuel",
        value: "Deuel",
    },
    {
        label: "Dixon",
        value: "Dixon",
    },
    {
        label: "Dodge",
        value: "Dodge",
    },
    {
        label: "Douglas",
        value: "Douglas",
    },
    {
        label: "Dundy",
        value: "Dundy",
    },
    {
        label: "Fillmore",
        value: "Fillmore",
    },
    {
        label: "Franklin",
        value: "Franklin",
    },
    {
        label: "Frontier",
        value: "Frontier",
    },
    {
        label: "Furnas",
        value: "Furnas",
    },
    {
        label: "Gage",
        value: "Gage",
    },
    {
        label: "Garden",
        value: "Garden",
    },
    {
        label: "Garfield",
        value: "Garfield",
    },
    {
        label: "Gosper",
        value: "Gosper",
    },
    {
        label: "Grant",
        value: "Grant",
    },
    {
        label: "Greeley",
        value: "Greeley",
    },
    {
        label: "Hall",
        value: "Hall",
    },
    {
        label: "Hamilton",
        value: "Hamilton",
    },
    {
        label: "Harlan",
        value: "Harlan",
    },
    {
        label: "Hayes",
        value: "Hayes",
    },
    {
        label: "Hitchcock",
        value: "Hitchcock",
    },

    {
        label: "Holt",
        value: "Holt",
    },
    {
        label: "Hooker",
        value: "Hooker",
    },
    {
        label: "Howard",
        value: "Howard",
    },
    {
        label: "Jefferson",
        value: "Jefferson",
    },
    {
        label: "Johnson",
        value: "Johnson",
    },
    {
        label: "Kearney",
        value: "Kearney",
    },
    {
        label: "Keith",
        value: "Keith",
    },
    {
        label: "Keya Paha",
        value: "Keya Paha",
    },
    {
        label: "Kimball",
        value: "Kimball",
    },
    {
        label: "Knox",
        value: "Knox",
    },
    {
        label: "Lancaster",
        value: "Lancaster",
    },
    {
        label: "Lincoln",
        value: "Lincoln",
    },
    {
        label: "Logan",
        value: "Logan",
    },
    {
        label: "Loup",
        value: "Loup",
    },
    {
        label: "Madison",
        value: "Madison",
    },
    {
        label: "McPherson",
        value: "McPherson",
    },
    {
        label: "Merrick",
        value: "Merrick",
    },
    {
        label: "Morrill",
        value: "Morrill",
    },
    {
        label: "Nance",
        value: "Nance",
    },
    {
        label: "Nemaha",
        value: "Nemaha",
    },
    {
        label: "Nuckolls",
        value: "Nuckolls",
    },
    {
        label: "Pawnee",
        value: "Pawnee",
    },
    {
        label: "Perkins",
        value: "Perkins",
    },
    {
        label: "Phelps",
        value: "Phelps",
    },
    {
        label: "Pierce",
        value: "Pierce",
    },
    {
        label: "Platte",
        value: "Platte",
    },
    {
        label: "Polk",
        value: "Polk",
    },
    {
        label: "Red Willow",
        value: "Red Willow",
    },
    {
        label: "Richardson",
        value: "Richardson",
    },
    {
        label: "Rock",
        value: "Rock",
    },
    {
        label: "Saline",
        value: "Saline",
    },
    {
        label: "Sarpy",
        value: "Sarpy",
    },
    {
        label: "Saunders",
        value: "Saunders",
    },
    {
        label: "Scotts Bluff",
        value: "Scotts Bluff",
    },
    {
        label: "Seward",
        value: "Seward",
    },
    {
        label: "Sheridan",
        value: "Sheridan",
    },
    {
        label: "Sherman",
        value: "Sherman",
    },
    {
        label: "Sioux",
        value: "Sioux",
    },
    {
        label: "Stanton",
        value: "Stanton",
    },
    {
        label: "Thayer",
        value: "Thayer",
    },
    {
        label: "Thomas",
        value: "Thomas",
    },
    {
        label: "Thurston",
        value: "Thurston",
    },
    {
        label: "Valley",
        value: "Valley",
    },
    {
        label: "Washington",
        value: "Washington",
    },
    {
        label: "Wayne",
        value: "Wayne",
    },
    {
        label: "Webster",
        value: "Webster",
    },
    {
        label: "Wheeler",
        value: "Wheeler",
    },
    {
        label: "York",
        value: "York",
    },
];

export default CountyDropdown;
