import classnames from "classnames";

/**
 * [Fieldset](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset) field used
 * used to group controls and labels within a form.
 *
 * [USWDS Reference ↗](https://designsystem.digital.gov/templates/form-templates/)
 */
const Fieldset = (props: FieldsetProps) => {
    const hasError = !!props.errorMsg;
    const classNames = classnames("usa-fieldset", props.className);
    const isBold = props.legendBold === undefined ? true : props.legendBold;
    const legendClassNames = classnames("usa-legend", {
        "usa-legend--large": props.legendStyle === "large",
        "font-body-lg": props.legendStyle === "medium",
        "usa-label--error": hasError,
        "text-bold": isBold,
    });

    return (
        <fieldset className={classNames}>
            {props.legend && <legend className={legendClassNames}>{props.legend}</legend>}
            {props.hint && <span className="usa-hint">{props.hint}</span>}
            {hasError && (
                <span className="usa-error-message" role="alert">
                    {props.errorMsg}
                </span>
            )}
            <span>{props.children}</span>
        </fieldset>
    );
};

interface FieldsetProps {
    children: React.ReactNode;
    /** Additional CSS class names */
    className?: string;
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Localized hint text for the entire fieldset
     */
    hint?: React.ReactNode;
    /**
     * Legend text
     */
    legend?: React.ReactNode;
    legendStyle?: "default" | "large" | "medium";
    /**
     * Should the legend be bold faced
     */
    legendBold?: boolean;
}

export default Fieldset;
