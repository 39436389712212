const pageScrollToTop = (): void => {
    window.scrollTo({
        top: 0,
        behavior: "auto",
    });

    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
    }
};

export { pageScrollToTop };
