/**
 * The Back button for the question page template
 *
 */
import React from "react";
import { useHistory } from "react-router";
import Button from "./Button";
import classnames from "classnames";


function BackButton({ ...props }: BackButtonProps) {
    const history = useHistory();
    const handleBackBtn = props.onClick || (() => history.goBack());
    const backButtonClasses = classnames("usa-link", props.buttonClassName);

    return (
        <Button className={backButtonClasses} onClick={handleBackBtn} variation="unstyled">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -2 24 24"
                className="usa-icon margin-right-05"
            >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
            Back
        </Button>
    );
}

interface BackButtonProps {
    /** Where to route the user to */
    href?: React.AnchorHTMLAttributes<HTMLAnchorElement>["href"];
    onClick?: React.MouseEventHandler;
    buttonClassName?: string;
}

export default BackButton;
